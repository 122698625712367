import React, { Component } from 'react';
import ModalDocumentType from './ModalDocumentType';
import {
	ControlLabel, Grid, Col, IconButton, Icon, Uploader, Alert
} from 'rsuite';
import { Card } from 'react-bootstrap';

class Documents extends Component{
    constructor(props){
        super(props);

        this.openModalDocumentType = this.openModalDocumentType.bind(this);
        this.handleChangeFile = this.handleChangeFile.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getDocumentTypes = this.getDocumentTypes.bind(this);
        this.deleteDocument = this.deleteDocument.bind(this);

        this.state = {
            type:"",
            image:[],
            comments:"",
            documentTypes:[],
            employeeDocuments:[]
        }
    }

    async componentDidMount(){
        console.log(this.props);
        this.props.onRef(this);

        this.getDocumentTypes();
        this.getEmployeeDocuments();
    }

    async getEmployeeDocuments(){
        var employeeDocuments = await this.props.fetchRequest({
            api:'accounts',
            method:'GET', 
            url:'employee-documents/by-employee/'+this.props.match.params.id , 
            requireToken:true
        });
        this.setState({employeeDocuments:employeeDocuments});
    }

    async getDocumentTypes(){
        var documentTypes = await this.props.fetchRequest({api:'accounts', method:'GET', url:'document-types/by-account', requireToken:true});
        this.setState({documentTypes:documentTypes});
    }

    async handleChangeFile(value){
        console.log("Handle Change...")
        await this.setState({image: value.length > 0 ? [value[value.length - 1]] : []});
        console.log(value);
        if(value.length > 0){
            await this.base64( [value[value.length - 1]], async (data)=>{
                await this.setState({photoBase64:data.base64});
                this.saveImage();
            })
        }else{
            this.setState({image:[]});
        }        
    }

    handleChange(e){
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    }

    async saveImage(){
        if(this.state.photoBase64 !== ""){
            console.log("Saving file...");
            console.log(this.state);
            if(this.state.type === ""){
                Alert.error("Debe seleccionar el tipo de documento");
                await this.setState({image:[]});
                return;
            }

            var obj = {
                documentType: this.state.type,
                comments: this.state.comments,
                imageBase64: this.state.photoBase64,
                employeeId: this.props.location.state.id     
            };

            var req = {
                api:'accounts',
                method:'POST',
                url:'employee-documents',
                obj:obj,
                modal:this.props.modal_loader,
                message_modal:this.props.t('modal.spin.saving'),
                requireToken:true
            }
    
            var response = await this.props.fetchRequest(req);
            if(response !== undefined){
                await this.getEmployeeDocuments();
                Alert.success("Documento guardado");
                await this.setState({image:[], type:"", comments:""});
            }
        }
    }

    async base64(file, callback){
        var coolFile = {};
        function readerOnload(e){
            var base64      = btoa(e.target.result);
            coolFile.base64 = base64;
            callback(coolFile)
        };
      
        var reader = new FileReader();
        reader.onload = readerOnload;
        
        var image            = file[0].blobFile;
        coolFile.filetype   = image.type;
        coolFile.size       = image.size;
        coolFile.filename   = image.name;
        
        reader.readAsBinaryString(image);
    }

    openModalDocumentType(e){
        e.preventDefault();
        this.modal_document_type.handleShow();
    }

    async deleteDocument(e, documentId){
        e.preventDefault();
        var req = {
            api:'accounts',
            method:'DELETE',
            url:'employee-documents/'+documentId,
            modal:this.props.modal_loader,
            message_modal:this.props.t('modal.spin.saving'),
            requireToken:true
        }

        var response = await this.props.fetchRequest(req);
        if(response !== undefined){
            Alert.error('Problema con el servicio')
        }
        await this.getEmployeeDocuments();
        Alert.success("Documento Eliminado");
    }

    render(){
        const {t} = this.props;
        
        return(
            <form>
                <Grid fluid>
                    <Col xs={24} style={{padding:0}}>
                        <Col xs={24} sm={10} md={6}>
                            <ControlLabel>{t('screen.settings.employee.documents.type')}</ControlLabel>
                            <select className="form-control form-control-sm" id="type" name="type"
                                value={this.state.type} onChange={e=>this.handleChange(e)} 
                                disabled={sessionStorage.getItem("suscription") === "free"}>
                                <option value="">{t('screen.settings.employee.documents.select')}</option>
                                {this.state.documentTypes.map((doc, i)=>(
                                    <option key={i} value={doc.key}>
                                        {doc.name}
                                    </option>
                                ))}
                            </select>
                        </Col>
                        <Col xs={24} sm={2} md={2} style={{marginTop:'29px'}}>
                            <IconButton 
                                icon={<Icon icon="plus" />}
                                onClick={e=>this.openModalDocumentType(e)}
                                color="blue"
                                size="sm"
                                disabled={sessionStorage.getItem("suscription") === "free"}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={16}>
                            <ControlLabel>{t('screen.settings.employee.documents.comments')}</ControlLabel>
                            <textarea className="form-control form-control-sm" style={{"resize":"none"}}
                                name="comments" id="comments" rows="1" value={this.state.comments} 
                                onChange={e=>this.handleChange(e)}
                                disabled={sessionStorage.getItem("suscription") === "free"}>
                            </textarea>
                        </Col>
                    </Col>
                    {this.state.employeeDocuments.length < 3?
                        <Col xs={24}>
                            <Uploader 
                                style={{paddingTop:"20px"}}
                                draggable
                                listType="picture-text"
                                onChange={this.handleChangeFile}
                                autoUpload={true}
                                multiple={true}
                                fileList={this.state.image}
                                onRemove={this.handleChangeFile}
                                disabled={sessionStorage.getItem("suscription") === "free"}
                            >
                            <div style={{lineHeight: '200px'}}>{t('screen.settings.employee.new.form.image_instruction')}</div>
                            </Uploader>
                        </Col>
                    :null}
                    {this.state.employeeDocuments.length > 0?
                        <Col xs={24} style={{paddingTop:"10px"}}>
                            {this.state.employeeDocuments.map((doc, i)=>(
                                <Col xs={24} sm={12} md={6}>
                                    <Col xs={24}>
                                        <Col xs={20}>
                                            <Card style={{width:'150px', height:"150px", display:'inline-block'}}>
                                                <Card.Img variant="top" id={doc.documentType}
                                                    src={"data:image/png;base64,"+doc.imageBase64}/>
                                            </Card>
                                        </Col>
                                        <Col xs={4}>
                                            <IconButton 
                                                icon={<Icon icon="trash" />}
                                                onClick={e=>this.deleteDocument(e,doc.id)}
                                                color="blue"
                                                size="sm"
                                            />
                                        </Col>
                                    </Col>
                                    <Col xs={24} sm={24}>
                                        <ControlLabel>{doc.documentType}</ControlLabel>
                                    </Col>
                                </Col>
                            ))}
                        </Col>
                    :null}
                </Grid>
                <ModalDocumentType  
                    {...this.props} 
                    onRef={ref => this.modal_document_type = ref}
                    getDocumentTypes = {this.getDocumentTypes}
                />
            </form>
        )
    }
    
}
export default Documents