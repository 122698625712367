import React, {Component} from 'react';
import moment from 'moment';
import { Modal, Grid, Col, ControlLabel, AutoComplete, Button, Alert } from 'rsuite';
import { isValidForm, showCtrlError } from '../../lib/functions';

class ModalEvents extends Component{
    constructor(props){
        super(props);

        this.handleChange   = this.handleChange.bind(this);
        this.handleShow     = this.handleShow.bind(this);
        this.handleClose    = this.handleClose.bind(this);
        this.onSave         = this.onSave.bind(this);

        this.state = {
            employee:"",
            start_date:moment().format('YYYY-MM-DD'),
            end_date:moment().format('YYYY-MM-DD'),
            event:"",
            show:false
        }
    }

    async componentDidMount(){
        this.props.onRef(this);
    }

    handleShow(){
        this.setState({show:true});
    }

    handleClose(){
        this.setState({
            show:false,
            employee:"",
            incident:""
        });
    }

    handleChange(e){
        this.setState({[e.target.name]: e.target.value});

        showCtrlError(e.target.id);
    }

    async onSave(){
        var error = '';
        var start_date = moment(this.state.start_date).format('YYYY-MM-DDTHH:mm:ss.sss');
        var end_date = moment(this.state.end_date).format('YYYY-MM-DDTHH:mm:ss.sss');
        
        if(start_date === 'Invalid date' || start_date === 'Fecha invalida'){
            error = 'fecha';
        }
        if(end_date === 'Invalid date' || end_date === 'Fecha invalida'){
            error = 'fecha';
        }

        if(error === ''){
            if(isValidForm('form.events-form')){
                var index = this.props.original_employees.findIndex(item =>
                    item.lastName + ' ' + item.maternalSurname+' '+item.firstName === this.state.employee || 
                    item.firstName+' '+item.lastName + ' ' + item.maternalSurname === this.state.employee
                );
                
                var obj = {
                    employee: this.props.original_employees[index],
                    eventType: this.state.event,
                    startDate: start_date,
                    endDate: end_date
                };
        
                var req = {
                    api:'accounts',
                    url:'employee_event',
                    method:'POST',
                    obj:obj,
                    requireToken:true,
                    modal:this.props.modal_loader,
                    message_modal:'Guardando....'
                };
                
                var event = await this.props.fetchRequest(req);
        
                if(event !== undefined){
                    this.handleClose();
                    this.props.updateList();
                }
            }else{
                Alert.error('Campos incompletos');
            }
            
        }else{
            Alert.error('Las fechas no tienen un formato correcto');
        }


        
    }
    
    render(){
        const {t} = this.props;
        return(
            <Modal show={this.state.show} onHide={this.handleClose} size="xs" backdrop="static">
                <Modal.Body>
                    <form className="form-group events-form">
                        <Grid fluid>
                            <Col xs={24} className="form-group">
                                <Col xs={24} sm={10}>
                                    <ControlLabel>{t('screen.settings.events.form.event')}</ControlLabel>
                                    <select name="event" id="event" value={this.state.event}
                                        onChange={(e)=>this.handleChange(e)} className="form-control form-control-sm" required>
                                        <option value="">{t('general.select')}</option>
                                        {this.props.events.map((e, i) => (
                                            <option key={i} value={e.key}>
                                                {e.value}
                                            </option>
                                        ))}
                                    </select>
                                </Col>
                            </Col>
                            <Col xs={24} className="form-group">
                                <Col xs={24}>
                                    <ControlLabel>{t('screen.settings.events.form.employee')}</ControlLabel>
                                    <AutoComplete 
                                        data={this.props.employees}
                                        value={this.state.employee}
                                        onChange={(e)=>this.handleChange({target:{name:'employee', id:'employee', value:e}})}
                                        id="employee"
                                        autocomplete="off"
                                        required
                                    />
                                </Col>
                            </Col>
                            <Col xs={24}>
                                <Col xs={24} sm={12}>
                                    <ControlLabel>{t('screen.settings.events.form.start_date')}</ControlLabel>
                                    <input type="date" name="start_date" id="start_date" value={this.state.start_date}
                                        onChange={(e)=>this.handleChange(e)} className="form-control form-control-sm" required/>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <ControlLabel>{t('screen.settings.events.form.end_date')}</ControlLabel>
                                    <input type="date" name="end_date" id="end_date" value={this.state.end_date}
                                        onChange={(e)=>this.handleChange(e)} className="form-control form-control-sm" required/>
                                </Col>
                            </Col>
                        </Grid>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{textAlign:'right'}}>
                        <Button color="blue" onClick={this.onSave}>{t('button.save')}</Button>
                        <Button onClick={this.handleClose}>{t('button.cancel')}</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default ModalEvents;