import React, {Component} from 'react';

import {Button, Modal, Col, FlexboxGrid, FormGroup, ControlLabel, Alert } from 'rsuite';
import { withTranslation } from 'react-i18next';
import PasswordStrengthBar from 'react-password-strength-bar';

class ModalChangePassword extends Component{
	constructor(props){
		super(props);

		this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);

		this.state = {
            show: false,
            currentPassword:"",
            newPassword:"",
            confirmPassword:""
		}
    }

	componentDidMount(){
		this.props.onRef(this);
	}

	componentDidUmount(){
		this.props.onRef(null);
	}

	async handleShow(){
        await this.setState({show:true});
	}

	handleClose(){
		this.setState({
            show:false,
            currentPassword:"",
            newPassword:"",
            confirmPassword:""
        });
    }

    handleChange(e){
		let	value = e.target.value;
        let name = e.target !== undefined ? e.target.name : e;
        
        this.setState({[name]: value});	
    }

    async save(){
        if(this.state.currentPassword === "" || this.state.newPassword === "" || this.state.confirmPassword === ""){	
            Alert.error(this.props.t('error_alert.incomplete_fields'));
            return;
        }

        if(this.state.newPassword !== this.state.confirmPassword){
            Alert.error(this.props.t('error_alert.password_no_match'));
            return;
        }
        var obj = {
            email:this.props.email,
            newPassword:this.state.newPassword,
            oldPassword:this.state.currentPassword
        }
        console.log(obj);
        console.log("Saving...");
        var req = {
            api:'auth',
            method:'POST',
            url:'auth/force-change-password',
            obj:obj,
            modal:this.props.modal_loader,
            message_modal:this.props.t('modal.spin.saving'),
            requireToken:true
        }
        
        var response = await this.props.fetchRequest(req);
        if(response !== null){
            await this.props.initSession(response);
        }
        this.handleClose();
    }

	render(){
		const {t} = this.props;
		return (
			<Modal backdrop={false} show={this.state.show} size="md">
				<Modal.Header>	
					<Modal.Title>{this.props.t('screen.login.change_password')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
                    <FlexboxGrid >
                        <Col xs={18} >
                            <FormGroup>
                                <ControlLabel>{this.props.t('screen.login.current_password')}</ControlLabel> <span style={{color:'red'}}>*</span>
                                <input type="password" className="form-control form-control-sm"
									value={this.state.currentPassword} required
									name="currentPassword"
									onChange={(e)=>this.handleChange(e)} 
								/>
                            </FormGroup>	
						</Col>
                        <Col xs={18} style={{paddingTop:'10px'}}>
                            <FormGroup>
                                <ControlLabel>{this.props.t('screen.login.new_password')}</ControlLabel> <span style={{color:'red'}}>*</span>
                                <input type="password" className="form-control form-control-sm"
									value={this.state.newPassword} required
									name="newPassword"
									onChange={(e)=>this.handleChange(e)} 
								/>
                                <PasswordStrengthBar
                                password={this.state.newPassword}
                                minLenght={8}
                                scoreWords={[
                                    <ControlLabel>{this.props.t('screen.passwords.very_weak')}</ControlLabel>,
                                    <ControlLabel>{this.props.t('screen.passwords.weak')}</ControlLabel>,
                                    <ControlLabel>{this.props.t('screen.passwords.good')}</ControlLabel>,
                                    <ControlLabel>{this.props.t('screen.passwords.very_good')}</ControlLabel>,
                                    <ControlLabel>{this.props.t('screen.passwords.strong')}</ControlLabel>
                                ]}
                                shortScoreWord={<ControlLabel>{this.props.t('screen.passwords.small')}</ControlLabel>}
                                />
                            </FormGroup>	
						</Col>
                        <Col xs={18} style={{paddingTop:'10px'}}>
                            <FormGroup>
                                <ControlLabel>{this.props.t('screen.login.confirm_password')}</ControlLabel> <span style={{color:'red'}}>*</span>
                                <input type="password" className="form-control form-control-sm"
									value={this.state.confirmPassword} required
									name="confirmPassword"
									onChange={(e)=>this.handleChange(e)} 
								/>
                            </FormGroup>	
						</Col>
                    </FlexboxGrid>
				</Modal.Body>
				<Modal.Footer>
                    <Button appearance="primary" onClick={this.save}>{t('button.add')}</Button>
                    <Button appearance="default" onClick={this.handleClose}>{t('button.cancel')}</Button>
				</Modal.Footer>
			</Modal>
		);
	}

}

export default withTranslation('translations') (ModalChangePassword);