import Swal from "sweetalert2";
import $ from 'jquery';


export const isValidForm = (element)=>{
	var ctrls = [];
	const select = document.querySelector(element);

	if(select !== null){
		ctrls = select.querySelectorAll('input, select');
   	
	    let isFormValid = true;
    	 ctrls.forEach(ctrl => {
	    	if(ctrl.required){
		      	const isInputValid = showCtrlError(ctrl.id);
		      	if (!isInputValid) {
		        	isFormValid = false;
		    	}
		  	}
	    });
	   
	    return isFormValid;
	}

	return true;

};

export const showCtrlError = (id)=>{
	var res = null;
	var control = document.getElementById(id);

	if(control !== null){
		if (control.value.trim() === "") {
	        if(control !== null){
	            control.classList.add('error');
	        }
			res = false;
		} else{
			if(control !== null){
				if(control.required && control.className.includes('error')){
		        	control.classList.remove('error');
		    	}
	    	}
			res = true;
		}
	}

	return res;
};

export const isAuthenticated = ()=>{
	let authenticated = false;
    let token = sessionStorage.getItem('token');

	if(token !== null){
		authenticated = true;
	}
	
	return authenticated;
}

export const hasAccount = ()=>{
	let hasAccount = false;
    let account = sessionStorage.getItem('hasAccount');

	if(account !== null){
		hasAccount = JSON.parse(account);
	}
	
	return hasAccount;
}

export const hasCapital = (value)=>{
   return new RegExp(/[A-Z]/).test(value);
}

export const hasSpecial = (value)=>{
   return new RegExp(/[!#@$%^&*)(+=._-]/).test(value);
}

export const isRfc = (value)=>{
	return new RegExp(/^[a-zA-Z]{3,4}(\d{6})((\D|\d){2,3})?$/).test(value);	
}

export const isPhone = (value)=>{
	return new RegExp(/^[0-9 ]+$/).test(value);
}

export const swalAction = (obj)=>{
	Swal.fire({
		title 				: obj.title,
		text 				: obj.text,
		icon 				: obj.icon,
		showConfirmButton	: obj.showConfirm,
		showCancelButton	: obj.showCancel,
		confirmButtonText	: obj.textConfirm,
		confirmButtonColor	: obj.colorConfirm,
		cancelButtonColor	: obj.colorCancel,
		cancelButtonText	: obj.textcancel
	}).then(result => {
		if(result.isConfirmed){
			obj.fn(obj.values);
		}else{
			if(obj.fnCancel !== undefined){
				obj.fnCancel(obj.values);
			}
		}
	});
}

export const isValidDate = (e, fn)=>{
	var id = e.target.id;

	if(e.target.validationMessage !== ""){
		Swal.fire('Error', e.target.validationMessage, 'error');
		
		setTimeout(()=>{			
			$('#'+id).val('')
				.attr('type', 'text')
				.attr('type', 'date');
				
			fn('', id);
		}, 3000);

		$('#'+e.target.id).addClass('error');

	}else{
		$('#'+e.target.id).removeClass('error');
	}
}