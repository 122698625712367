import React from 'react';
import {Switch, Route, Redirect} from "react-router-dom";
import AppliedRoute from './AppliedRoute';

import Home from './screens/Home';
import Wizard from './screens/Wizard';
import Register from './screens/Register';
import Login from './screens/Login';

import Account from './screens/settings/Account';
import Employee from './screens/settings/employee/Employee';
import EmployeeNew from './screens/settings/employee/EmployeeNew';
import EmployeeDetails from './screens/settings/employee/EmployeeDetails';

import Locations from './screens/settings/locations/Locations';
import NewLocation from './screens/settings/locations/NewLocation';

import Reports from './screens/reports/Reports';
import Entries from './screens/reports/Entries';
import CheckInFace from './screens/reports/CheckInFace';
import Absences from './screens/reports/Absences';
import CalendarReport from './screens/reports/Calendar';
import Attendance from './screens/reports/Attendance';

import Events from './screens/settings/employee/Events';
import Visit from './screens/reports/visits/Visit';
import VisitDetail from './screens/reports/visits/VisitDetail';
import Overtime from './screens/reports/Overtime';
import Recover from './screens/Recover';
import Plans from './components/subscription/Plans';

const PrivateRoute = ({component: Component, props: cProps, ...rest})=>{
	const isAuthenticated = cProps.isAuthenticated();
	
	return (
		<Route 
			{...rest}
			render={props =>{
				return isAuthenticated ? (
					<Component {...props} {...cProps} />
				) : (
					<Redirect to="/login" {...props} {...cProps} />
				)
			}
				
			}
		/>
	)
};

const Routes = ({childProps}) =>	
	<Switch>
		<AppliedRoute path="/login" exact component={Login} props={childProps} />
		<AppliedRoute path="/recover" exact component={Recover} props={childProps} />
		<AppliedRoute path="/subscription-plans" exact component={Plans} props={childProps} />
		
		<AppliedRoute path="/registration/:urlCode" exact component={Register} props={childProps} />

		<PrivateRoute path="/" exact component={Home} props={childProps} />
		<PrivateRoute path="/wizard" exact component={Wizard} props={childProps} />
		<PrivateRoute path="/settings/account" exact component={Account} props={childProps} />

		<PrivateRoute path="/settings/employee" exact component={Employee} props={childProps} />
		<PrivateRoute path="/settings/employee/new" exact component={EmployeeNew} props={childProps} />
		<PrivateRoute path="/settings/employee/details/:id/:can_active" exact component={EmployeeDetails} props={childProps} />

		<PrivateRoute path="/settings/employee/events" exact component={Events} props={childProps} />
	
		<PrivateRoute path="/settings/locations" exact component={Locations} props={childProps} />
		<PrivateRoute path="/settings/locations/:optionForm" exact component={NewLocation} props={childProps} />
		<PrivateRoute path="/settings/locations/:optionForm/:locationId" exact component={NewLocation} props={childProps} />

		<PrivateRoute path="/reports" exact component={Reports} props={childProps} />
		<PrivateRoute path="/reports/entries" exact component={Entries} props={childProps} />
		<PrivateRoute path="/reports/overtime" exact component={Overtime} props={childProps} />
		<PrivateRoute path="/reports/check_in_face" exact component={CheckInFace} props={childProps} />
		<PrivateRoute path="/reports/absences" exact component={Absences} props={childProps} />
		<PrivateRoute path="/reports/calendar" exact component={CalendarReport} props={childProps} />
		<PrivateRoute path="/reports/attendance" exact component={Attendance} props={childProps} />
		<PrivateRoute path="/reports/visit" exact component={Visit} props={childProps} />
		<PrivateRoute path="/reports/visit/details/:id" exact component={VisitDetail} props={childProps} />
	</Switch>
export default Routes;