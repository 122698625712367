import React from 'react';
import {Icon} from 'rsuite';

const SavedMark = (props)=>{
    return (
        <div style={{float:'right', marginRight:'1%'}}>
            <Icon icon="check" size="lg" style={{color:'green'}} />
            <br/>
        </div>
    )
}

export default SavedMark;