import React, { useState } from 'react';
import { Panel, Grid, Col, Button } from 'rsuite';
import Swal from 'sweetalert2';

const Ususcribe = (props)=>{
    const {t} = props;
    const [accept_remove, checkRemove] = useState(false)

    const message = ()=>{
        Swal.fire('Error', t('error_alert.accept_terms'), 'error');
    }
    return(
        <Panel header="" shaded>
            <Grid fluid>
                <Col xs={24} className="form-group">
                    <input type="checkbox" name="accept_remove" id="accept_remove" value={accept_remove}
                        checked={accept_remove} onChange={()=>checkRemove(!accept_remove)}
                        className="rs-col-xs-1" style={{marginTop:5}}
                    /> <p style={{textAlign:'justify'}} className="rs-col-xs-23"> 
                        {t('screen.settings.account.unsuscribe.message')}
                    </p>
                </Col>
                <Col xs={24} style={{textAlign:"center"}}>
                    <Button onClick={(e)=>accept_remove ? props.confirmCancelSubscription() : message()} color="red" 
                        className="s-btn-primary" style={{marginTop:"23px"}} disabled={!accept_remove}
                    >
                        {t('button.cancel_sub')}
                    </Button>
                </Col>
            </Grid>
        </Panel>
    )
}

export default Ususcribe;