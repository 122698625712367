import React, { Component } from 'react';
import { Grid, Col, ControlLabel, Panel, IconButton, Icon } from 'rsuite';
import SavedMark from './SavedMark';
import SaveBtn from './SaveBtn';

class Special extends Component{
    constructor(props){
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            checkAheadOffsetHours:"",
            checkBackOffsetHours:"",
            breakOutOffsetMinutes:"",
            checkMaxRaduis:"",
            is_saved:false,
            active_save:false,
            disabled:true
        }
    }

    componentDidMount(){
        this.props.onRef(this);
    }

    handleChange(e){
        this.setState({
            [e.target.name]: e.target.value, 
            active_save: true,
            is_saved: false});
    }

    render(){
        const {t} = this.props;
        return (
            <Panel shaded>
                <i class="rs-panel-heading" style={{padding:"0px"}}> 
                    {t('screen.settings.account.special_config.title')}
                </i>
                <i class="rs-icon rs-icon-help-o rs-panel-heading" style={{paddingLeft:"10px"}} title={this.props.t('screen.settings.account.tooltips.special_config')}> 
                </i>
                {this.state.is_saved &&
                    <SavedMark />
                }
                <Grid fluid>
                    <Col xs={24} sm={2} md={1}>
                        {this.state.disabled ?
                            <IconButton 
                                icon={<Icon icon='pencil' />}
                                color="blue"
                                size="sm"
                                onClick={()=>this.setState({disabled: !this.state.disabled})}
                                disabled={this.props.suscription==="free"?true:false}
                            />
                        :
                            <SaveBtn t={this.props.t} active={this.state.active_save} action={this.props.dataUpdate} />
                        }
                    </Col>
                    <Col xs={24} sm={22} md={23}>
                        <Grid fluid className="form-group">
                            <Col xs={24} sm={18}>
                                <ControlLabel>{t('screen.settings.account.special_config.hours_ahead')}</ControlLabel>
                            </Col>
                            <Col xs={24} sm={6}>
                                <input type="number" name="checkAheadOffsetHours" id="checkAheadOffsetHours"
                                    value={this.state.checkAheadOffsetHours}
                                    style={{textAlign:'right'}}
                                    disabled={this.state.disabled}
                                    onChange={(e)=>this.handleChange(e)} 
                                    className="form-control form-control-sm is-number"
                                />
                            </Col>
                        </Grid>
                        <Grid fluid className="form-group">
                            <Col xs={24} sm={18}>
                                <ControlLabel>{t('screen.settings.account.special_config.hours_back')}</ControlLabel>
                            </Col>
                            <Col xs={24} sm={6}>
                                <input type="number" name="checkBackOffsetHours" id="checkBackOffsetHours"
                                    value={this.state.checkBackOffsetHours}
                                    style={{textAlign:'right'}}
                                    disabled={this.state.disabled}
                                    onChange={(e)=>this.handleChange(e)} 
                                    className="form-control form-control-sm is-number"
                                />
                            </Col>
                        </Grid>
                        <Grid fluid className="form-group">
                            <Col xs={24} sm={18}>
                                <ControlLabel>{t('screen.settings.account.special_config.minute_range')}</ControlLabel>
                            </Col>
                            <Col xs={24} sm={6}>
                                <input type="number" name="breakOutOffsetMinutes" id="breakOutOffsetMinutes"
                                    value={this.state.breakOutOffsetMinutes}
                                    style={{textAlign:'right'}}
                                    disabled={this.state.disabled}
                                    onChange={(e)=>this.handleChange(e)} 
                                    className="form-control form-control-sm is-number"
                                />
                            </Col>
                        </Grid>
                        <Grid fluid className="form-group">
                            <Col xs={24} sm={18}>
                                <ControlLabel>{t('screen.settings.account.special_config.max_radius')}</ControlLabel>
                            </Col>
                            <Col xs={24} sm={6}>
                                <input type="number" name="checkMaxRaduis" id="checkMaxRaduis"
                                    value={this.state.checkMaxRaduis}
                                    style={{textAlign:'right'}}
                                    disabled={this.state.disabled}
                                    onChange={(e)=>this.handleChange(e)} 
                                    className="form-control form-control-sm is-number"
                                />
                            </Col>
                        </Grid>

                    </Col>
                </Grid>
                    
            </Panel>

        )
    }
    
}

export default Special;