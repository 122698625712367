import React,  {Component} from 'react';
import { Grid, Col, Panel, FlexboxGrid, Button } from 'rsuite';
import {withTranslation} from 'react-i18next';
import { Image } from 'react-bootstrap';
import {isEmail} from 'validator';
import PasswordStrengthBar from 'react-password-strength-bar';
import Swal from 'sweetalert2';
import {hasCapital, hasSpecial} from '../lib/functions';

class Recover extends Component{
    constructor(props){
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.sendRecover = this.sendRecover.bind(this);
        this.verify = this.verify.bind(this);

        this.state = {
            email:"",
            show_error:false,
            sending:false,
            password:"",
            confirm_password:"",
            code:"",
            error_password:""
        }
    }

    handleChange(e){
        if(e.target.name === 'password' || e.target.name === 'confirm_password'){
			var error = '';
			var caracteres = '', letra = '', simbolo = '';

			this.setState({[e.target.name]: e.target.value});

			if(e.target.name === 'password'){
				if(this.state.confirm_password !== "" && this.state.confirm_password !== e.target.value){
					error = this.props.t('error_alert.password_no_match');
				}
			}

			if(e.target.name === 'confirm_password'){
				if(this.state.password !== "" && this.state.password !== e.target.value){
					error = this.props.t('error_alert.password_no_match');
				}
			}

			if(error === ''){
				error = this.props.t('error_alert.password_contain');
				if(e.target.value.length < 8){
					caracteres = this.props.t('error_alert.min_character');
				}

				if(!hasCapital(e.target.value)){
					letra = this.props.t('error_alert.one_capital');
				}

				if(!hasSpecial(e.target.value)){
					simbolo = this.props.t('error_alert.one_symbol');
				};

				if(caracteres === "" && letra === "" && simbolo === ""){
					error = "";
				}else{
					error = error+caracteres+letra+simbolo;	
				}
			}
			
			this.setState({error_password: error});
		}else{
            this.setState({[e.target.name]: e.target.value});
        }
        
    }

    async sendRecover(){
        if(isEmail(this.state.email)){
            this.setState({show_error:false});

            var req = await this.props.fetchRequest({
                api:'auth',
                method:'POST',
                url:'auth/forgot-password',
                obj:{
                    email: this.state.email
                },
                modal:this.props.modal_loader,
                message_modal:this.props.t('modal.spin.sending')
            });

            if(req !== undefined){
                this.setState({sending:true});

            }

        }else{
            this.setState({show_error:true});
        }
    }

    async verify(){
        var error = "";
        if(this.state.password !== "" && this.state.confirm_password !== "" && this.state.code !== ""){
            if(this.state.password !== this.state.confirm_password){
                error = this.props.t('error_alert.password_no_match');
            }
        }else{
            error = this.props.t('error_alert.incomplete_fields')
        }

        if(error === ""){
            var obj = {
                email: this.state.email,
                newPassword: this.state.password,
                verificationCode: this.state.code
            };

            var req = await this.props.fetchRequest({
                api:'auth',
                method:'POST',
                url:'auth/reset-password',
                obj:obj,
                modal:this.props.modal_loader,
                message_modal:this.props.t('modal.spin.confirming')
            });

            if(req !== undefined){
                Swal.fire(this.props.t('success_alert.confirm'), this.props.t('success_alert.password_reset'), 'success');
                this.props.history.push('/login');
            }
            
        }else{
            Swal.fire('Error', error, 'error');
        }
    }

    render(){
        const {t} = this.props;
        return(
            <Grid fluid>
                <Col xs={24}>
                    <Col xs={6} sm={4} md={3} lg={2}>
                        <Image src="images/image.png" alt="logo" fluid className="s-logo-img" />
                    </Col>
                </Col>
                <Col xs={24} style={{marginTop:'5%'}}>
                    <FlexboxGrid justify="center">
                        <Col xs={24} sm={12} md={10} lg={8}>
                            {this.state.sending ?
                                <Panel header={t('screen.recover_password.title')} bordered shaded>
                                    <Col xs={24}>
                                        <p style={{textAlign:'justify'}}>
                                            {t('screen.recover_password.confirm')}
                                        </p>
                                    </Col>
                                    <Col xs={24} className="form-group">                                        
                                        <input className="form-control form-control-sm" placeholder={t('screen.recover_password.email')} 
                                            name="email" id="email" value={this.state.email} 
                                            onChange={(e)=>{
                                                if(isEmail(e.target.value)){
                                                    this.setState({show_error:false});
                                                }else{
                                                    this.setState({show_error:true});
                                                }
                                                
                                                return this.handleChange(e);
                                            }}
                                            disabled
                                        />
                                    </Col>

                                    <Col xs={24} className="form-group">
                                        <PasswordStrengthBar 
                                            password={this.state.password} 
                                            minLenght={8}
                                            scoreWords={[
                                                t('general.password_score_word.very_weak'),
                                                t('general.password_score_word.weak'),
                                                t('general.password_score_word.good'),
                                                t('general.password_score_word.very_good'),
                                                t('general.password_score_word.strong')
                                            ]}
                                            shortScoreWord={t('general.password_score_word.small')}
                                        />
                                        {this.state.error_password !== "" &&
                                            <span style={{fontSize:10, color:'red'}}>{this.state.error_password}</span>
                                        }
                                        <input type="password" className="form-control form-control-sm"  
                                            placeholder={t('screen.recover_password.password')}
                                            name="password" id="password" value={this.state.password} 
                                            onChange={(e)=>this.handleChange(e)}                                            
                                        />
                                    </Col>

                                    <Col xs={24} className="form-group">
                                        <input type="password" className="form-control form-control-sm"  
                                            placeholder={t('screen.recover_password.confirm_password')}
                                            name="confirm_password" id="confirm_password" value={this.state.confirm_password} 
                                            onChange={(e)=>this.handleChange(e)}                                            
                                        />
                                    </Col>

                                    <Col xs={24} className="form-group">
                                        <input className="form-control form-control-sm"  
                                            placeholder={t('screen.recover_password.code')}
                                            name="code" id="code" value={this.state.code} 
                                            onChange={(e)=>this.handleChange(e)}    
                                            autoComplete="off"                                        
                                        />
                                    </Col>

                                    <Col xs={24} style={{textAlign:'right'}} className="form-group">
                                        <Button 
                                            onClick={(e)=>{
                                                e.preventDefault(); 
                                                return this.setState({sending:false, password:"", confirm_password:"", code:""});
                                            }} 
                                            style={{marginRight:8}}>{t('button.return')}</Button>
                                        <Button color="blue" onClick={()=>this.verify()}>{t('button.confirm')}</Button>
                                    </Col>
                                </Panel>
                            :
                            <Panel bordered header={t('screen.recover_password.title')} shaded>                                
                                <Col xs={24}>
                                    <p style={{textAlign:"justify"}}>
                                        {t('screen.recover_password.recover')}
                                    </p>
                                </Col>
                                <Col xs={24} className="form-group">
                                    {this.state.show_error &&
                                        <span style={{fontSize:10, color:'red'}}>{t('error_alert.invalid_email')}</span>
                                    }
                                    <input className="form-control form-control-sm" placeholder={t('screen.recover_password.email')} 
                                        name="email" id="email" value={this.state.email} 
                                        onChange={(e)=>{
                                            if(isEmail(e.target.value)){
                                                this.setState({show_error:false});
                                            }else{
                                                this.setState({show_error:true});
                                            }
                                            this.setState({email:e.target.value})
                                        }}
                                    />
                                </Col>
                                <Col xs={24} className="form-group" style={{textAlign:'right'}}>
                                    <Button onClick={(e)=>{e.preventDefault(); return this.props.history.push('/login')}} 
                                        style={{marginRight:8}}>{t('button.cancel')}</Button>
                                    <Button onClick={()=>this.sendRecover()} color="blue">{t('button.send')}</Button>
                                </Col>
                            </Panel>
                            }
                        </Col>
                    </FlexboxGrid>
                </Col>
                
            </Grid>
        )
    }
}

export default withTranslation('translations')(Recover);