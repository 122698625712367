import React, {Component} from 'react';

import './App.css';
import {withRouter} from "react-router-dom";

import Routes from './Routes';
import {isAuthenticated, isValidForm} from './lib/functions';
import {fetchRequest} from './lib/fetchRequest';

import ModalLoader from './components/ModalLoader';
import ModalLogin from './components/ModalLogin';
import IdleTimer from 'react-idle-timer';

class App extends Component{
    constructor(props){
        super(props);

        this.closeSesion = this.closeSesion.bind(this);
        this.onIdle = this.onIdle.bind(this);

        this.state = {
            authenticated: false,
            modal:{}
        }
    }

    componentDidMount(){
        this.setState({modal:this.modal_loader})
    }

    closeSesion(){
        localStorage.clear();
        sessionStorage.clear();
        this.props.history.push('/login');
    }

    onIdle(){
        if(this.props.location.pathname !== '/login'){
            this.modal_login.handleShow();
        }
	}

    render(){
        let childrenProps = {
            fetchRequest: fetchRequest,
            isValidForm: isValidForm,
            isAuthenticated: isAuthenticated,
            closeSesion: this.closeSesion,
            modal_loader: this.state.modal
        }

        var min = 1000 * 60 * 15;//15 minutos
        
        return(
            <div>
                <IdleTimer
					ref={ref => this.idleTimer = ref}
					element={document}
					onIdle ={this.onIdle}
					debounce={500}
					timeout={min} />

                <Routes childProps={childrenProps} />

                <ModalLogin {...this.props} onRef={ref => this.modal_login = ref} props={childrenProps}/>

                <ModalLoader {...this.props} onRef={ref => this.modal_loader = ref} />
            </div>
            
        )
    }

}

export default withRouter(App);
