import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {FlexboxGrid, Col, Panel, Button, Alert} from 'rsuite';
import CheckoutForm from './CheckoutForm';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { Image } from 'react-bootstrap';
import ModalLoader from "../ModalLoader";

class PaymentPlanCard extends Component{
	constructor(props){
		super(props);

		this.handleChange = this.handleChange.bind(this);
        this.confirmSubscription = this.confirmSubscription.bind(this);

		this.state = {
		}
	}

	componentDidMount(){
        window.addEventListener('resize', () => {
        	if (window.innerWidth < 450 && this.state.elementFontSize !== '14px') {
          		this.setState({elementFontSize: '14px'});
        	} else if (
          		window.innerWidth >= 450 &&
          		this.state.elementFontSize !== '18px'
        	) {
          		this.setState({elementFontSize: '18px'});
        	}
		});
	}

	handleChange(e, value){
        if (value) {
            this.setState({
                [value]:{
                    ...this.state[value], 
                    [e.target.name]: e.target.value
                }
            });
        } else {
            this.setState({[e.target.name]: e.target.value});
        }
    }

    async confirmSubscription(){
		this.modal_loader.handleShow(this.props.t('modal.spin.validating'));
		var cardData = await this.checkout_form.getCardData();

		this.modal_loader.handleClose();
		if(cardData === undefined){
			return;
		}

		cardData.subscriptionType = this.props.state.basic ? 'basic' : this.props.state.premium ? 'premium' : ""; 

		var url = 'subscription';
		if(this.state.action === 'new_method'){
			url = 'payment-methods';
		}

		let req = {
			api:'payments',
			method:'POST',
			url: url,
			requireToken:true,
			obj:cardData,
			modal:this.props.modal_loader,
			message_modal:this.props.t('modal.spin.generating')
		};
		let response =  await this.props.fetchRequest(req);
		if(response !== null&& response !== undefined){
			sessionStorage.setItem("suscription", response.subscriptionType);
			window.location.reload();
		}
    }

	render(){
		const {t} = this.props;
		//Testing 
		//const stripePromise = loadStripe("pk_test_51Hhf2tGibt8wqoLZIg8mEj04dQ6vlP7Slgre2czPw9csDDuYuurjFT4dPDl4UmVMdmptyAM2grCxYVBLwbAA2Bdg00EVzcCFOG");
		
		//Production
		const stripePromise = loadStripe("pk_live_51Hhf2tGibt8wqoLZxiCjGzYFLskDegJDsU52tVpHjnnXj8qh7voIgj6Hg5AUEo37DazVyJIQk9kXO9wBd986ePua00VhPp8Eet");
		return (
            <Panel className="" shaded style={{height: 640}}>
				
                <FlexboxGrid justify="center">
                    <Col xs={24} >
                        <Elements stripe={stripePromise}>
                            <CheckoutForm {...this.props} translate={t} onRef={ref => this.checkout_form = ref} plans={false}/>
                        </Elements>			
                    </Col>
                    <Col xs={24}>
                        <Image src="../../../images/powered-by-stripe-badge.png"  fluid />
                    </Col>
                    <Col xs={24} style={{textAlign:'center', width:"100%", paddingTop:"20px"}}>
						<Button appearance="primary" size="md" 
							onClick={(e)=>this.props.state.free ? Alert.error(this.props.t('error_alert.select_plan'), 3400) : this.confirmSubscription()}
						>
                            {t('modal.subscription.message')}
                        </Button>
                    </Col>
                </FlexboxGrid>
                <ModalLoader onRef={ref => this.modal_loader = ref}/>
            </Panel>
		)
	}
		  
}

export default withTranslation('translations')(PaymentPlanCard)