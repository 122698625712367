import React from 'react';
import { Row, Col, Grid } from 'rsuite';

import Navigation from './Navigation';
import SideNav from './SideNav';

const Layout = (props)=>{
	return (
		
		 <Grid fluid>
            <Row>
                <Col sm={1} smHidden xsHidden className="s-background-sidebar">
                    <SideNav {...props} />
                </Col>
                <Col sm={24} md={23}>
                    <Grid fluid>
                        <Row>
                            <Col xs={24}>
                                <Navigation {...props} />
                            </Col>
                        </Row>
                       
                        <Row>
                            <Col xs={24}>
								{props.children}
                            </Col>
                        </Row>
                    </Grid>
                </Col>
            </Row>
        </Grid>
		
	)
}

export default Layout;