import React, {Component} from 'react';
import {
	Panel, Container, FlexboxGrid, Form, FormGroup, ControlLabel, Col,  IconButton, Icon
} from 'rsuite';
import $ from 'jquery';

import {default as GeneralData} from '../../wizard/General';
import SaveBtn from './SaveBtn';
import SavedMark from './SavedMark';

class General extends Component{
    constructor(props){
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            company_name:"",
            industries:[],
            industry:"",
            industryOther:"",
            country:"",
            countries:[],
            time_zone:"",
            zones:[],
            companySize:"",
            language:"",
            size_companies:[
                {value:"1-10", name:"1 a 10 empleados"},
				{value:"11-50", name:"11 a 50 empleados"},
				{value:"51-100", name:"51 a 100 empleados"}
            ],
            active_save:false,
            is_saved:false,
            disabled:true,
            update:false,
            lng:"",
        }
    }

    async componentDidMount(){
        this.props.onRef(this);


        var countries = await fetch('https://countriesnow.space/api/v0.1/countries/iso')
        .then(res => {
            return res.json().then((r)=>{
                return r;
            });
        }).then(response => {
            var countries = [];
            response.data.map((res)=>
                countries.push({
                    value: res.Iso3,
                    label: this.props.t('general.countries.'+res.Iso3)
                })
            );
            return countries;
        });
        
        var lang = sessionStorage.getItem(btoa("lang"));

        var industries = await this.props.fetchRequest({api:'accounts', method:'GET', url:'lookups/economic_sector/'+atob(lang), requireToken:true});
        if(industries === undefined){
            this.props.closeSesion();
            return;
        }    
		var zones_aux = await this.props.fetchRequest({api:'accounts', method:'GET', url:'accounts/get-available-zones', requireToken:true});
		var zones = [];
		zones_aux.map((z)=>
			zones.push({value:z.zone+' '+z.offset, label:z.zone+' '+z.offset})
		);

        var aux_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if(this.state.time_zone !== ""){
            aux_zone = this.state.time_zone;
        }
        var index = zones_aux.findIndex(obj => obj.zone === aux_zone);
        
        this.setState({
            zones: zones,
			countries: countries !== undefined ? countries : [],
            industries: industries !== undefined ? industries : [],
            time_zone: index !== -1 ? zones_aux[index].zone+' '+zones_aux[index].offset : "",
            lng: lang
		});
    }

    async componentDidUpdate(){
        if(!this.state.update){
            var index = this.state.zones.findIndex(obj => obj.value.indexOf(this.state.time_zone) !== -1);
            
            if(index !== -1){
                this.setState({
                    time_zone: this.state.zones[index].value,
                    update:true
                });
            }            
        }else{
            var lang = sessionStorage.getItem(btoa("lang"));
            if(this.state.lng !== lang){
                var industries = await this.props.fetchRequest({api:'accounts', method:'GET', url:'lookups/economic_sector/'+atob(lang), requireToken:true});
                if(industries === undefined){
                    this.props.closeSesion();
                    return;
                }  

                this.setState({
                    industries: industries !== undefined ? industries : [],
                    lng:lang
                });
            }            
        }
    }

    componentWillUnmount(){
        this.props.onRef(null);
    }

    handleChange(e){
        if(e.target.name === 'industry' && e.target.value !== 'other_services'){
            this.setState({industryOther:''});
        }

        if(e.target.name === 'language'){
            const {i18n} =  this.props;
		
            i18n.changeLanguage(e.target.value);
            
            
        }
        this.setState({[e.target.name]: e.target.value, active_save: true, is_saved:false});
    }

    render(){
        return (
            <Panel className="panel-general" shaded>
                <i class="rs-panel-heading" style={{padding:"0px"}}> 
                    {this.props.t('screen.settings.account.general.title')}
                </i>
                <i class="rs-icon rs-icon-help-o rs-panel-heading" style={{paddingLeft:"10px"}} title={this.props.t('screen.settings.account.tooltips.general')}> 
                </i>
                
                {this.state.is_saved &&
                    <SavedMark />
                }
              
                <div className="general-account">
                    
                    <Col xs={24} sm={2} md={1} style={{marginTop:'3%'}}>
                        {this.state.disabled ?
                            <IconButton 
                                icon={<Icon icon='pencil' />}
                                color="blue"
                                size="sm"
                                onClick={()=>this.setState({disabled: !this.state.disabled})}
                            />
                        :
                            <SaveBtn t={this.props.t} active={this.state.active_save} action={this.props.dataUpdate} />
                        }
                    </Col>
                    <Col xs={24} sm={22} md={23}>
                        <GeneralData 
                            t={this.props.t}
                            company_name={this.state.company_name} 
                            industries={this.state.industries}
                            industry={this.state.industry}
                            country={this.state.country}
                            countries={this.state.countries}
                            time_zone={this.state.time_zone}
                            zones={this.state.zones}
                            industryOther={this.state.industryOther}
                            disabled={this.state.disabled}
                            language={this.state.language}
                            handleChange={this.handleChange}
                        />
                        <Container>
                            <FlexboxGrid>
                                <Col xs={24} sm={14} md={12} style={{padding:'5px', marginBottom:10}}>
                                    <Form fluid>
                                        <FormGroup style={{marginTop:15}}>
                                            <ControlLabel>
                                                {this.props.t('screen.settings.account.general.company_size')} <span style={{color:'red'}}>*</span>
                                            </ControlLabel>
                                            <select name="companySize" id="companySize" value={this.state.companySize} 
                                                className="form-control form-control-sm" 
                                                disabled={this.state.disabled}
                                                onChange={(e)=>this.handleChange(e)} required >
                                                <option value="">{this.props.t('general.select')}</option>
                                                {this.state.size_companies.map((c, i)=>(
                                                    <option key={i} value={c.value}>{c.name}</option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                        <FormGroup>
                                            <ControlLabel>
                                                {this.props.t('screen.wizard.general.language')} <span style={{color:'red'}}>*</span>
                                            </ControlLabel>
                                            <select className="form-control form-control-sm" name="language" id="language"
                                                value={this.state.language} 
                                                onChange={(e)=>this.handleChange(e)}
                                                required
                                                disabled={this.state.disabled}
                                            >
                                                <option value="es">{this.props.t('general.language.es')}</option>
                                                <option value="en">{this.props.t('general.language.en')}</option>
                                            </select>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </FlexboxGrid>
                        </Container>
                    </Col>                        
                </div>
                
                <br/>
            </Panel>
        )
    }
 
}

export default General;