import React, {Component} from 'react';
import { Modal, Col } from 'rsuite';
import Geocode from "react-geocode";

import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';


Geocode.setApiKey("AIzaSyAig1H6xWKyyS5J6NeADJhi8YR2A2N6akI");
Geocode.setLanguage("es");
Geocode.enableDebug();

 
class ModalMap extends Component{
    constructor(props){
        super(props);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            lat:"",
            long:"",
            name:"",
            show:false
        }
    }

    componentDidMount(){
        this.props.onRef(this);
    }

    componentWillUnmount(){
        this.props.onRef(null);
    }

    handleShow(lat, lng, name){
        this.setState({lat:lat, long:lng, name:name, show:true});
    }

    handleClose(){
        this.setState({show: false, lat:"", long:"", name:""});
    }

    render(){
        return(
            <Modal size="lg" show={this.state.show} onHide={this.handleClose} >
                <Modal.Header></Modal.Header>
				<Modal.Body>
					<div className="form-group">
						<Col xs={24} style={{height:"500px"}}>
                        <Map google={this.props.google} initialCenter={{
                                lat: this.state.lat,
                                lng: this.state.long
                            }}
                            className="s-w-map"
                            zoom={15}
                            >
                                <Marker
                                    title={this.state.name}
                                    name={this.state.name}
                                    zoom={15}
                                    position={{lat: this.state.lat, lng: this.state.long}} />
                                <InfoWindow >
                                    <div>
                                        <h1>{"test"}</h1>
                                    </div>
                                </InfoWindow>
                        </Map>
                        
                        
                        </Col>
					</div>
				</Modal.Body>
			</Modal>
        )
    }
}

export default (GoogleApiWrapper({
    apiKey: ("AIzaSyAig1H6xWKyyS5J6NeADJhi8YR2A2N6akI")
})(ModalMap));