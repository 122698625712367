import React, { useEffect } from 'react';
import Flag from "react-world-flags";
import {
	ControlLabel, Grid, Col, SelectPicker, Toggle, IconButton, Icon
} from 'rsuite';
import { isValidDate, swalAction } from '../../../lib/functions';


const General = (props)=>{
    const {t} = props;
    const codes = props.codes;
    const suscription = sessionStorage.getItem('suscription');
    
    useEffect(()=>{
        props.onRef({codes:codes});
    });

    const sendInvitation = (type)=>{
        var obj = {
            title           : t('modal.alert.titles.alert'),
            text            : t('modal.alert.messages.send_invitation'),
            icon            : 'warning',
            showConfirm     : true,
            showCancel      : true,
            textConfirm     : t('modal.alert.confirms.yes'),
            colorConfirm    : '#3085d6',
            colorCancel     : '#d33',
            textcancel      : t('modal.alert.confirms.no'),
            values          : type,
            fn              : props.invite
        }
        swalAction(obj);
    }

    return(
        <form>
            <Grid fluid>
                <Grid fluid>
                    <Col xs={24} style={{padding:0}}>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <ControlLabel>{t('screen.settings.employee.details.form.role')}</ControlLabel><span style={{color:'red'}}>*</span>
                            <select className="form-control form-control-sm" id="roleId" name="roleId" 
                            value={props.roleId} required
                                onChange={(e)=>props.handleChange(e)} 
                                disabled={suscription === 'free' ? true : !props.edit_general}>
                                    <option value="">{t('general.select')}.</option>
                                    {props.roles.map((r, i)=>
                                        <option value={r.id} key={r.id}>{r.name}</option>                                            
                                    )}
                            </select>
                        </Col>
                    </Col>
                    <Col xs={24} sm={props.show_details ? 12 : 12} md={props.show_details ? 12 : 6} lg={8}>
                        <ControlLabel>{t('screen.settings.employee.new.form.name')}</ControlLabel> <span style={{color:'red'}}>*</span>
                        <input className="form-control form-control-sm" size="sm" required
                            name="names" id="names" value={props.names}
                            disabled={!props.edit_general}
                            onChange={(e)=> props.handleChange(e)} />
                    </Col>
                    <Col xs={24} sm={props.show_details ? 12 : 12} md={props.show_details ? 12 : 6} lg={6}>
                        <ControlLabel>{t('screen.settings.employee.new.form.lastname')}</ControlLabel> <span style={{color:'red'}}>*</span>
                        <input className="form-control form-control-sm" size="sm" required
                            name="lastname1" id="lastname1" value={props.lastname1}
                            disabled={!props.edit_general}
                            onChange={(e)=> props.handleChange(e)} />
                    </Col>
                    <Col xs={24} sm={props.show_details ? 12 : 12} md={props.show_details ? 12 : 6} lg={6}>
                        <ControlLabel>{t('screen.settings.employee.new.form.maternal_surname')}</ControlLabel>
                        <input className="form-control form-control-sm" size="sm" 
                            name="lastname2" id="lastname2" value={props.lastname2}
                            disabled={!props.edit_general}
                            onChange={(e)=> props.handleChange(e)} />
                    </Col>
                    {props.show_details &&
                        <Col xs={24} sm={12} md={3}>
                            <ControlLabel>{t('screen.settings.employee.new.form.active')}</ControlLabel>
                            <div>
                                <Toggle
                                    checked={props.active}
                                    onChange={(e)=>props.activeEmployee(e)}
                                    disabled={props.can_active ? 
                                                !props.edit_general 
                                            : !props.can_active}
                                />
                            </div>                            
                        </Col>
                    }
                    
                </Grid>
                <Grid fluid>
                    {props.show_details &&
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <ControlLabel>{t('screen.settings.employee.new.form.sex')}</ControlLabel>
                            <select className="form-control form-control-sm" size="sm" 
                                name="sex" id="sex" value={props.sex}
                                disabled={!props.edit_general}
                                onChange={(e)=> props.handleChange(e)}>
                                <option value="">{t('general.select')}.</option>
                                <option value="M">{t('screen.settings.employee.new.form.male')}</option>
                                <option value="F">{t('screen.settings.employee.new.form.female')}</option>
                            </select>
                        </Col>
                    }
                    {props.show_details &&
                        <Col xs={24} sm={12} md={12} lg={6}>
                            <ControlLabel>{t('screen.settings.employee.new.form.birthday')}</ControlLabel>
                            <input type="date" className="form-control form-control-sm" size="sm" 
                                disabled={!props.edit_general}
                                name="birthday" id="birthday" value={props.birthday} onBlur={(e)=>isValidDate(e, props.handleChange)}
                                onChange={(e)=> props.handleChange(e)} />
                        </Col>
                    }
                    
                </Grid>
                <Grid fluid>
                    <Col xs={24} sm={24} md={12} lg={8}>
                        <Col xs={24} style={{padding:0}}>
                            <ControlLabel>{t('screen.settings.employee.new.form.phone')}</ControlLabel> <span style={{color:'red'}}>*</span>
                            <span style={{color:'red', fontSize:12, marginLeft:10, display:'block'}}>{props.error_phone}</span>
                        </Col>
                        <Col xs={9} sm={props.show_details ? 7 : 8} md={props.show_details ? 8 : 10} style={{paddingLeft:0}}>                        
                            <SelectPicker 
                                size="sm"
                                value={props.code}
                                onChange={(e)=>props.handleChange(e, {target:{name:'code', value:e}})}
                                data={codes}
                                style={{width:'100%', paddingLeft:2}}
                                renderMenuItem={(label, item) => {
                                    return (
                                        <div><Flag code={label} height={15} /> {label}</div>
                                    );
                                }}
                                placeholder="Seleccione..."
                                className="s-time-zone"
                                searchable={true}
                                required
                                disabled={!props.edit_general}
                            />
                        </Col>
                        <Col xs={13} sm={props.show_details ? 15 : 16} md={props.show_details ? 12 : 14}>
                            <input className="form-control form-control-sm" size="sm" required
                                name="phone" id="phone" value={props.phone}
                                style={{padding:5}}
                                disabled={!props.edit_general}
                                onChange={(e)=> props.handleChange(e)} />
                        </Col>
                        {props.show_details &&
                            <Col xs={2} sm={2}>
                                <IconButton 
                                    icon={<Icon icon="commenting-o" />}
                                    circle
                                    color="blue"
                                    size="sm"
                                    //disabled={!props.edit_general}
                                    onClick={()=>sendInvitation('sms')}
                                />
                            </Col>
                        }
                    </Col>
                    
                    <Col xs={24} sm={props.show_details ? 22 : 24} md={props.show_details ? 10 : 12}>
                        <ControlLabel>{t('screen.settings.employee.new.form.email')}</ControlLabel>
                        <span style={{color:'red', fontSize:12, marginLeft:10}}>{props.error_email}</span>
                        <input className="form-control form-control-sm" size="sm" 
                            name="email" id="email" value={props.email}
                            disabled={!props.edit_general}
                            onChange={(e)=> props.handleChange(e)}/>
                        
                    </Col>
                    {props.show_details &&
                        <Col xs={24} sm={2} md={2} style={{marginTop:28}}>
                            <IconButton 
                                icon={<Icon icon="location-arrow" />}
                                circle
                                color="blue"
                                size="sm"
                                onClick={()=>sendInvitation('email')}
                                //disabled={!props.edit_general}
                            />
                        </Col>
                    }
                </Grid>
                {!props.show_details &&
                    <Grid style={{marginTop:10}} fluid>
                        <Col xs={24}>
                            <input type="checkbox" name="face" id="face" value={props.face}
                                checked={props.face} onChange={(e)=>props.handleCheck(e)} /> <span>{t('screen.settings.employee.new.form.face')}</span>
                        </Col>
                        <Col xs={24}>
                            <input type="checkbox" name="geolocation" id="geolocation" value={props.geolocation}
                                checked={props.geolocation} onChange={(e)=>props.handleCheck(e)} /> <span>{t('screen.settings.employee.new.form.location')}</span>
                        </Col>
                    </Grid>
                }
                
                {props.show_details &&
                    props.campos.length > 0 &&
                        <Grid fluid>
                            {props.campos.map((c, i)=>
                                <Col xs={24} sm={i % 3 === 0 ? 8 : 6} key={i}>
                                    <ControlLabel>{c.title}</ControlLabel>
                                    {c.enum !== undefined ?
                                        <select className="form-control form-control-sm" name={c.id} id={c.id}
                                            required={c.required} disabled={!props.edit_general} defaultValue={c.default}>
                                            <option value="">{t('general.select')}</option>
                                            {c.enum.map((e, j)=>
                                                <option value={e} key={j}>{e}</option>                                            
                                            )}
                                        </select>
                                    :
                                        <input type={c.format !== undefined ? c.format : 'text'} name={c.id} id={c.id}
                                            disabled={!props.edit_general} required={c.required} defaultValue={c.default}
                                            className={"form-control form-control-sm "+(c.format !== undefined ? 'is-number' : '')} />
                                    }
                                </Col>
                            )}
                        </Grid>
                }
            </Grid>
        </form>
    )
   
 
}

export default General;