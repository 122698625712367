import React from 'react';
import {
	Container, FlexboxGrid, Form, FormGroup, ControlLabel, Grid
} from 'rsuite';

const Operation = (props)=>{
	return(
		<Container>
			<Grid>
				<FlexboxGrid.Item style={{padding:'5px'}}>
					<Form fluid>
						<FormGroup>
							<ControlLabel style={{fontWeight:'bold', fontSize:'20px'}}>
								{props.t('screen.wizard.operation.characteristics_checker')} <span style={{color:'red'}}>*</span>
							</ControlLabel>
							<div style={{paddingLeft:'40px'}}>
								<input type="checkbox" className="form-check-input"
									name="enforceFacialRecognition" id="enforceFacialRecognition" 
									value={props.facial}
									onChange={props.handleCheck} 
									checked={props.facial}
									/> 
									{props.t('screen.wizard.operation.facial')}
							</div>
							<div style={{paddingLeft:'40px'}}>
								<input type="checkbox" className="form-check-input" 
									name="enforceGeolocation" id="enforceGeolocation" 
									value={props.geolocalization}
									onChange={props.handleCheck}
									checked={props.geolocalization}/> 
									{props.t('screen.wizard.operation.geolocation')}
							</div>
							
						</FormGroup>
						<FormGroup>
							<ControlLabel style={{fontWeight:'bold', fontSize:'20px'}}>
								{props.t('screen.wizard.operation.payroll_periodicity')} <span style={{color:'red'}}>*</span>
							</ControlLabel>

							<div style={{paddingLeft:'40px'}}>
								<input type="checkbox" className="form-check-input"
									name="payrollWeekly" id="payrollWeekly" 
									value={props.weekly} 
									checked={props.weekly}
									onChange={props.handleCheck} 
									/> 
									{props.t('screen.wizard.operation.weekly')}
							</div>
							<div style={{paddingLeft:'40px'}}>
								<input type="checkbox" className="form-check-input" 
									name="payrollBiweekly" id="payrollBiweekly" 
									value={props.biweekly} 
									checked={props.biweekly}
									onChange={props.handleCheck}/> 
									{props.t('screen.wizard.operation.biweekly')}
							</div>
							<div style={{paddingLeft:'40px'}}>
								<input type="checkbox" className="form-check-input" 
									name="payrollMonthly" id="payrollMonthly" 
									value={props.monthly} 
									checked={props.monthly}
									onChange={props.handleCheck}/> 
									{props.t('screen.wizard.operation.monthly')}
							</div>
							<div style={{paddingLeft:'40px'}}>
								<input type="checkbox" className="form-check-input" 
									name="payrollSpecial" id="payrollSpecial" 
									value={props.special} 
									checked={props.special}
									onChange={props.handleCheck}/> 
									{props.t('screen.wizard.operation.special')}
							</div>
						</FormGroup>
						<FormGroup>
							<ControlLabel style={{fontWeight:'bold', fontSize:'20px'}}>
								{props.t('screen.wizard.operation.incident_control')} <span style={{color:'red'}}>*</span>
							</ControlLabel>
							<div style={{paddingLeft:'40px'}}>
								<input type="checkbox" className="form-check-input" 
									name="supportDelays" id="supportDelays" 
									value={props.delays} 
									checked={props.delays}
									onChange={props.handleCheck}/> 
									{props.t('screen.wizard.operation.delays')}
							</div>
							<div style={{paddingLeft:'40px'}}>
								<input type="checkbox" className="form-check-input" 
									name="supportAbsences" id="supportAbsences" 
									value={props.absences} 
									checked={props.absences}
									onChange={props.handleCheck}/> 
									{props.t('screen.wizard.operation.absence')}
							</div>
							<div style={{paddingLeft:'40px'}}>
								<input type="checkbox" className="form-check-input" 
									name="supportEconomicDays" id="supportEconomicDays" 
									value={props.economicDays} 
									checked={props.economicDays}
									onChange={props.handleCheck}/> 
									{props.t('screen.wizard.operation.economic_day')}
							</div>
							<div style={{paddingLeft:'40px'}}>
								<input type="checkbox" className="form-check-input" 
									name="supportVacations" id="supportVacations" 
									value={props.vacations} 
									checked={props.vacations}
									onChange={props.handleCheck}/> 
									{props.t('screen.wizard.operation.holidays')}
							</div>
						</FormGroup>
					</Form>
				</FlexboxGrid.Item>
			</Grid>
		</Container>
	)
}

export default Operation;