import React from 'react';
import { Grid, Col, ControlLabel } from 'rsuite';
import TimeField from 'react-simple-timefield';

const ExtendedForm = (props)=>{
    const {t} = props;
    
    return(
        <Grid fluid>
            <Grid fluid className="form-group" style={{marginTop:10}}>
                <Col xs={12} sm={6} md={4} lg={2}>
                    <ControlLabel>{t('screen.settings.account.checker_rules.startTime')}</ControlLabel> <span style={{color:'red'}}>*</span>
                </Col>
                <Col xs={12} sm={9} md={5} lg={3}>
                    <ControlLabel>{t('screen.settings.account.checker_rules.minimum_hours')}</ControlLabel> <span style={{color:'red'}}>*</span>
                </Col>
            </Grid>
            {props.schedule.days.map((d, i)=>(
                <div key={i}>
                    {i === 0 &&
                        <Grid className="form-group" fluid>
                            <Col xs={12} sm={6} md={4} lg={2}>
                                <TimeField
                                    className={"form-control form-control-sm "+d.error_start} 
                                    value={d.startTime}
                                    onChange={(e)=>props.changeRules(e, i)} 
                                    style={{width:'100%'}}
                                    name="startTime"
                                    disabled={props.edit_schedule ? props.schedule.days.length > 0 ? !d.check : false : true} 
                                />
                            </Col>
                            <Col xs={12} sm={6} md={4} lg={2}>
                                <input type="number" className="form-control form-control-sm is-number" 
                                    name="minimumHours" id="minimumHours"
                                    value={props.schedule.minimumHours}
                                    min="9"
                                    max="36"
                                    onBlur={(e)=>props.validHours(e)}
                                    onChange={(e)=>props.handleChange(e)}
                                    disabled={props.edit_schedule ? props.schedule.days.length > 0 ? !d.check : false : true} 
                                />
                            </Col>
                        </Grid>
                    }
                </div>
                
            ))}
        </Grid>
    )
}

export default ExtendedForm;