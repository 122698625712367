import React from 'react';
import { Grid, Col, ControlLabel, IconButton, Icon} from 'rsuite';
import TimeField from 'react-simple-timefield';

const TraditionalForm = (props)=>{
    const {t} = props;
    let show_form = true;
    if(props.show_name){
        if(props.select_name){
            if(props.schedule_name !== ""){
                show_form = true;
            }else{
                show_form = false;
            }
        }
    }
    return (
        <div>       
            {show_form && 
                <div style={{marginTop:8}}>
                    <Grid fluid className="form-group" style={{marginTop:10}}>
                        <Col xs={12} sm={7} md={5} lg={4}>
                            <Col xs={24} sm={7} md={4}></Col>
                        </Col>
                        <Col xs={12} sm={17} md={20}>
                            <Col xs={24} sm={6} md={5} lg={5}>
                                <ControlLabel>{t('screen.settings.account.checker_rules.startTime')}</ControlLabel> <span style={{color:'red'}}>*</span>
                            </Col>
                            
                            {props.schedule.useBreakTime &&
                                <Col xs={24} sm={6} md={5} lg={5}>
                                    <ControlLabel>{t('screen.settings.account.checker_rules.breakTime')}</ControlLabel> <span style={{color:'red'}}>*</span>
                                </Col>
                            }
                            {props.schedule.useBreakTime &&
                                <Col xs={24} sm={6} md={5} lg={5}>
                                    <ControlLabel>{t('screen.settings.account.checker_rules.breakReturnTime')}</ControlLabel> <span style={{color:'red'}}>*</span>
                                </Col>
                            }
                            <Col xs={24} sm={6} md={6}>
                                <ControlLabel>{t('screen.settings.account.checker_rules.closingTime')}</ControlLabel> <span style={{color:'red'}}>*</span>
                            </Col>
                        </Col>
                        
                    </Grid>
                    
                    {props.schedule.days.map((d, i)=>(
                        <div key={i}>
                            {(props.schedule.activeWeekend && i >= 5) ?
                                <Grid className="form-group" fluid >
                                    <Col xs={12} sm={7} md={5} lg={4}>
                                        <Col xs={24}>
                                            <input type="checkbox" name="check" 
                                                value={d.check} checked={d.check}
                                                onChange={(e)=>props.changeRules(e, i)}
                                                disabled={!props.edit_schedule} /> <span>{t('general.days_of_week.'+d.name.toLowerCase())}</span>
                                        </Col>
                                    </Col>
                                    <Col xs={12} sm={17} md={20}>
                                        <Col xs={24} sm={6} md={5} lg={5} className="form-group">
                                            <TimeField 
                                                className={"form-control form-control-sm "+d.error_start} 
                                                value={d.startTime}
                                                onChange={(e)=>props.changeRules(e, i)} 
                                                style={{width:'100%'}}name="startTime"
                                                disabled={props.edit_schedule ? props.schedule.days.length > 0 ? !d.check : false : true } 
                                            />
                                        </Col>
                                        {props.schedule.useBreakTime &&
                                            <Col xs={24} sm={6} md={5} lg={5} className="form-group">
                                                <TimeField 
                                                    className={"form-control form-control-sm "+d.error_break} 
                                                    value={d.breakTime} 
                                                    onChange={(e)=>props.changeRules(e, i)} 
                                                    style={{width:'100%'}}
                                                    name="breakTime"
                                                    disabled={props.edit_schedule ? props.schedule.days.length > 0 ? !d.check : false : true} 
                                                />
                                            </Col>
                                        }
                                        {props.schedule.useBreakTime &&
                                            <Col xs={24} sm={6} md={5} lg={5} className="form-group">
                                                <TimeField 
                                                    className={"form-control form-control-sm "+d.error_return} 
                                                    value={d.breakReturnTime} 
                                                    onChange={(e)=>props.changeRules(e, i)} 
                                                    style={{width:'100%'}}
                                                    name="breakReturnTime"
                                                    disabled={props.edit_schedule ? props.schedule.days.length > 0 ? !d.check : false : true} 
                                                />
                                            </Col>
                                        }		
                                        <Col xs={24} sm={6} md={5} lg={5} className="form-group">
                                            <TimeField 
                                                className={"form-control form-control-sm "+d.error_close} 
                                                value={d.closingTime} 
                                                onChange={(e)=>props.changeRules(e, i)} 
                                                style={{width:'100%'}}
                                                name="closingTime"
                                                disabled={props.edit_schedule ? props.schedule.days.length > 0 ? !d.check : false : true} 
                                            />
                                        </Col>	
                                    </Col>
                                    		
                                </Grid>
                            : i < 5 &&
                                <Grid className="form-group" fluid>
                                    <Col xs={12} sm={7} md={5} lg={4}>
                                        <Col xs={24}>
                                            <input type="checkbox" name="check" 
                                                value={d.check} checked={d.check}
                                                onChange={(e)=>props.changeRules(e, i)}
                                                disabled={!props.edit_schedule}/> <span>{t('general.days_of_week.'+d.name.toLowerCase())}</span>
                                        </Col>
                                    </Col>

                                    <Col xs={12} sm={17} md={20}>
                                        <Col xs={24} sm={6} md={5} lg={5} className="form-group">
                                            <TimeField
                                                className={"form-control form-control-sm "+d.error_start} 
                                                value={d.startTime}
                                                onChange={(e)=>props.changeRules(e, i)} 
                                                style={{width:'100%'}}
                                                name="startTime"
                                                disabled={props.edit_schedule ? props.schedule.days.length > 0 ? !d.check : false : true} 
                                            />
                                        </Col>
                                        {props.schedule.useBreakTime &&
                                            <Col xs={24} sm={6} md={5} lg={5} className="form-group">
                                                <TimeField 
                                                    className={"form-control form-control-sm "+d.error_break} 
                                                    value={d.breakTime} 
                                                    onChange={(e)=>props.changeRules(e, i)} 
                                                    style={{width:'100%'}}
                                                    name="breakTime"
                                                    disabled={props.edit_schedule ? props.schedule.days.length > 0 ? !d.check : false : true} 
                                                />
                                            </Col>
                                        }
                                        {props.schedule.useBreakTime &&
                                            <Col xs={24} sm={6} md={5} lg={5} className="form-group">
                                                <TimeField 
                                                    className={"form-control form-control-sm "+d.error_return} 
                                                    value={d.breakReturnTime} 
                                                    onChange={(e)=>props.changeRules(e, i)} 
                                                    style={{width:'100%'}}
                                                    name="breakReturnTime"
                                                    disabled={props.edit_schedule ? props.schedule.days.length > 0 ? !d.check : false : true} 
                                                />
                                            </Col>
                                        }		
                                        <Col xs={24} sm={6} md={5} lg={5} className="form-group">
                                            <TimeField 
                                                className={"form-control form-control-sm "+d.error_close} 
                                                value={d.closingTime} 
                                                onChange={(e)=>props.changeRules(e, i)} 
                                                style={{width:'100%'}}
                                                name="closingTime"
                                                disabled={props.edit_schedule ? props.schedule.days.length > 0 ? !d.check : false : true} 
                                            />
                                        </Col>	
                                    </Col>
                                    
                                    			
                                </Grid>
                            }

                            {(i === 4 && (props.select_name ? props.edit_schedule : true )) &&
                                <Grid className="form-group" fluid>
                                    <input type="checkbox" name="activeWeekend" value={props.schedule.activeWeekend}
                                        checked={props.schedule.activeWeekend} onChange={(e)=>props.handleChange(e)} /> 
                                        <span style={{marginLeft:8}}>
                                            {t('screen.settings.account.checker_rules.enable_weekend')}
                                        </span>
                                </Grid>
                            }
                        </div>
                        
                    ))}
                </div>
            }

            {props.wizard ?
                props.show_name ?
                    props.select_name ?
                        show_form &&
                            <Grid className="form-group" fluid>
                                <IconButton 
                                    icon={<Icon icon="edit" />}
                                    title={t('button.edit')}
                                    onClick={(e)=>props.onCheck({
                                        target:{
                                            name:'edit_schedule',
                                            checked: !props.edit_schedule
                                        }
                                    })}
                                    color="blue"
                                />
                            </Grid>
                    : null
                : null
            :null}
            
            
        </div>
    )
}

export default TraditionalForm;