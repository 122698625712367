import React, { Component } from 'react';
import {Grid, Col, ControlLabel} from 'rsuite';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import Geocode from "react-geocode";

class AddressForm extends Component{
    constructor(props){
        super(props);

        this.getLocationTypes = this.getLocationTypes.bind(this);
        this.search = this.search.bind(this);
        this.handleChangeAddressLine1 = this.handleChangeAddressLine1.bind(this);
        //this.getLocation = this.getLocation.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleChangeCheck = this.handleChangeCheck.bind(this);

        this.state = {
            locationsTypes:[],
            name:"",
            type:"",
            latitude:"",
            longitude:"",
            address: {
                addressLine1: "Mexico, Mexico",
                addressLine2: "",
                city: "",
                state: "",
                zipCode: "",
                country: ""
            },
            countries:[],
            zoomMap:5,
            flagInitMap:true,
            flagEdit:false,
            lang:""
        }
    }

    async componentDidMount(){
        this.props.onRef(this);
        if(this.props.form === 'location'){
            if(this.props.match.params.locationId === undefined){
                this.search(this.state.address.addressLine1, true);    
            }else{
                this.getLocation(this.props.match.params.locationId, false);
            }
        }else{
            
            if(this.state.address.city === ""){
                this.search(this.state.address.addressLine1, true);
            }
        }        
        await this.setState({lang:sessionStorage.getItem(btoa("lang"))})
        this.getLocationTypes();
    }

    async getLocationTypes(){
        this.setState({
            locationsTypes: await this.props.fetchRequest({
                api:'accounts', 
                url:'lookups/location_type/'+atob(this.state.lang)+"/ordered",
                requireToken:true
            })
       });	
    }

    async componentDidUpdate(){
        var auxLang = sessionStorage.getItem(btoa("lang"));
        if(this.state.lang !== auxLang){
            await this.setState({lang:auxLang});
            this.getLocationTypes();
        }
    }

    async getLocation(locationId, updateLatLong){
        var location = await this.props.fetchRequest({api:'accounts', method:'GET', url:'locations/'+locationId, requireToken:true});
               
        this.setState({
            address:location.address,
            name:location.name,
            type: location.type,
            latitude: location.latitude.toFixed(4),
            longitude:location.longitude.toFixed(4),
            flagInitMap:false
        });
        this.search(this.state.address.addressLine1, updateLatLong);
    }

    async search(searchAddress, updateLatLong){
        // Get latitude & longitude from address.
      
        await Geocode.fromAddress(searchAddress).then(
          async response => {
            await this.setState({
                latitude: updateLatLong? response.results[0].geometry.location.lat.toFixed(4):this.state.latitude, 
                longitude: updateLatLong? response.results[0].geometry.location.lng.toFixed(4):this.state.longitude, 
                address:{
                    addressLine1: searchAddress,
                    addressLine2: this.state.address.addressLine2,
                    country: 
                        updateLatLong? this.getMapsProperty(response.results[0].address_components,'country')
                        :this.state.address.country,
                    city:  
                        updateLatLong?this.getMapsProperty(response.results[0].address_components,'locality')
                        :this.state.address.city,
                    state: 
                        updateLatLong?this.getMapsProperty(response.results[0].address_components,"administrative_area_level_1")
                        :this.state.address.state,
                    zipCode: 
                        updateLatLong?this.getMapsProperty(response.results[0].address_components,'postal_code')
                        :this.state.address.zipCode,
                },
                zoomMap: this.state.flagInitMap ? this.state.zoomMap:18,
                flagInitMap:false
            });
          },
          error => {
            console.error(error);
          }
        );
    }

    handleChange(e, value){
        if (value) {
            if(value === "longitude" || value === "latitude"){
                this.setState({[e.target.name]: parseFloat(e.target.value)})
                console.log(e.target.value);
            }else{
                this.setState({
                    [value]:{
                        ...this.state[value], 
                        [e.target.name]: e.target.value
                    }
                });
            }
        } else {
            this.setState({[e.target.name]: e.target.value});
        }
    }

    handleChangeCheck(e){
        this.setState({[e.target.name]:e.target.checked});
    }

    handleChangeAddressLine1(value){
        this.setState({address:{addressLine1:value}});
    }

    getMapsProperty(properties,key){
        var res = "";
        for(var i = 0; i<properties.length;i++){
            if(properties[i].types.includes(key)){
                res = properties[i].long_name;
                break;
            }
        }
        return res;
    }

    handleSelect = address => {
        geocodeByAddress(address)
          .then(async results => {
              await getLatLng(results[0]);
              this.setState({address:results[0].formatted_address});
              this.search(results[0].formatted_address, true);
            })
          .catch(error => console.error('Error', error));
    };

    render(){

        const {t} = this.props;
        return (
            <Grid className="form-group form-location" fluid >
                <Col xs={24} sm={12}>
                    {this.props.form === 'location' &&
                        <Grid className="form-group">
                            <Col xs={24} sm={17} md={6} lg={6}>
                                <ControlLabel>{t('screen.settings.location.form.type')}</ControlLabel> <span style={{color:'red'}}>*</span>
                                <select className="form-control form-control-sm" name="type" id="type" readOnly={this.props.optionForm === "details"}
                                    value={this.state.type} onChange={(e)=>this.handleChange(e)} required disabled={this.props.optionForm === "details"}>
                                    <option value="">{t('general.select')}</option>
                                    {this.state.locationsTypes.map((inc, i)=>(
                                        <option key={i} value={inc.key}>
                                            {inc.value}
                                        </option>
                                    ))}
                                </select>
                            </Col>

                            <Col xs={24} sm={17} md={6} lg={6}>					
                                <ControlLabel>{t('screen.settings.location.form.name')}</ControlLabel> <span style={{color:'red'}}>*</span>
                                <input type="text" className="form-control form-control-sm"
                                    value={this.state.name} required
                                    name="name" id="name" readOnly={this.props.optionForm === "details"}
                                    onChange={(e)=>this.handleChange(e)} 
                                />
                            </Col>
                        </Grid>
                    }
                    <Grid className="form-group">
                        <Col xs={24} sm={17} md={12} lg={12}>
                            <ControlLabel>{t('screen.settings.location.form.address')}</ControlLabel> 
                                <PlacesAutocomplete
                                className="form-control form-control-sm"
                                value={this.state.address.addressLine1}
                                id="addressLine1"
                                name="addressLine1"
                                onChange={this.handleChangeAddressLine1}
                                onSelect={this.handleSelect}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <input className="form-control form-control-sm" readOnly={this.props.optionForm === "details"}
                                    {...getInputProps({
                                        placeholder: t('screen.settings.location.form.search_place'),
                                        className: 'form-control',
                                    })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                    {loading && <div>{t('modal.spin.loading')}</div>}
                                    {suggestions.map((suggestion, i) => {
                                        const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                        <div
                                            key={i}
                                            {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                        );
                                    })}
                                    </div>
                                </div>
                                )}
                            </PlacesAutocomplete>
                        </Col>
                    </Grid>

                    <Grid className="form-group">
                        <Col xs={20} sm={15} md={10}>					
                            <ControlLabel>{t('screen.settings.location.form.reference')}</ControlLabel> 
                            <input className="form-control form-control-sm" name="addressLine2" id="addressLine2" readOnly={this.props.optionForm === "details"}
                                value={this.state.address.addressLine2 || ""} onChange={(e)=>this.handleChange(e, 'address')} />
                        </Col>

                        {this.props.optionForm !== "details"?
                        <Col xs={2} sm={2} md={1} style={{textAlign:"center"}}>
                            <ControlLabel style={{display:'block'}}>{t('button.edit')}</ControlLabel> 
                            <input value={this.state.flagEdit} type="checkbox" name="flagEdit" id="flagEdit"
                                onChange={(e)=>this.handleChangeCheck(e)}/> 
                        </Col>
                        :null}
                    </Grid>

                    <Grid className="form-group">
                        <Col xs={24} sm={17} md={6} >
                            
                            <ControlLabel>{t('screen.settings.location.form.city')} </ControlLabel> 
                            <input className="form-control form-control-sm" name="city" id="city" readOnly={!this.state.flagEdit}
                                    value={this.state.address.city || ""} onChange={(e)=>this.handleChange(e, 'address')} />
                        </Col>

                        <Grid className="form-group">
                            <Col xs={24} sm={17} md={6}>					
                                <ControlLabel>{t('screen.settings.location.form.state')} </ControlLabel> 
                                <input className="form-control form-control-sm" name="state" id="state" readOnly={!this.state.flagEdit}
                                    value={this.state.address.state || ""} onChange={(e)=>this.handleChange(e, 'address')} />
                            </Col>
                        </Grid>
                    </Grid>

                    <Grid className="form-group">
                        <Col xs={24} sm={17} md={6}>
                            <ControlLabel>{t('screen.settings.location.form.postal_code')} </ControlLabel> 
                            <input className="form-control form-control-sm" name="zipCode" id="zipCode" readOnly={!this.state.flagEdit}
                                    value={this.state.address.zipCode || ""} onChange={(e)=>this.handleChange(e, 'address')} />
                        </Col>

                        <Grid className="form-group">
                            <Col xs={24} sm={17} md={6}>					
                                <ControlLabel>{t('screen.settings.location.form.country')} </ControlLabel> 
                                <input className="form-control form-control-sm" name="country" id="country" readOnly={!this.state.flagEdit}
                                value={this.state.address.country || ""} onChange={(e)=>this.handleChange(e,'address')} />
                            </Col>
                        </Grid>
                    </Grid>

                    <Grid className="form-group">
                        <Col xs={24} sm={17} md={6}>
                            <ControlLabel>{t('screen.settings.location.form.latitude')} </ControlLabel> 
                            <input className="form-control form-control-sm" name="latitude" id="latitude"
                                    value={this.state.latitude} type="number" step=".0001" 
                                    readOnly={!this.state.flagEdit} onChange={(e)=>this.handleChange(e,'latitude')}/>
                        </Col>

                        <Grid className="form-group">
                            <Col xs={24} sm={17} md={6}>					
                                <ControlLabel>{t('screen.settings.location.form.longitude')} </ControlLabel> 
                                <input className="form-control form-control-sm" name="longitude" id="longitude"
                                    value={this.state.longitude} type="number" step=".0001"
                                    readOnly={!this.state.flagEdit} onChange={(e)=>this.handleChange(e,'longitude')}/>
                            </Col>
                        </Grid>
                    </Grid>
                </Col>
                <Col xs={24} sm={12} style={{height:"500px"}}>
                    <Map google={this.props.google} zoom={this.state.zoomMap} center={{lat: this.state.latitude,lng: this.state.longitude}}>
                        <Marker
                            title={this.state.address.addressLine1 || ""}
                            name={this.state.address.addressLine1 || ""}
                            position={{lat: this.state.latitude, lng: this.state.longitude}} />

                        <InfoWindow >
                            <div>
                                <h1>{this.state.address.addressLine1 || ""}</h1>
                            </div>
                        </InfoWindow>
                    </Map>
                </Col>
            </Grid>
        )
    }
}

export default (GoogleApiWrapper({
    apiKey: ("AIzaSyAig1H6xWKyyS5J6NeADJhi8YR2A2N6akI")
})(AddressForm));