import React, {Component} from 'react';

import {Button, Modal, Col, FlexboxGrid, FormGroup, ControlLabel, Alert } from 'rsuite';
import { withTranslation } from 'react-i18next';
import { Table } from 'react-bootstrap';

class ModalRoles extends Component{
	constructor(props){
		super(props);

		this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getPermissions = this.getPermissions.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.save = this.save.bind(this);
        this.getRoleById = this.getRoleById.bind(this);

		this.state = {
            show: false,
            name:"",
            permissions:[],
            roleId:undefined
		}
    }

	componentDidMount(){
		this.props.onRef(this);
	}

	componentDidUmount(){
		this.props.onRef(null);
	}

	async handleShow(roleId){
        await this.setState({show:true, roleId: roleId});
        await this.getPermissions();
        if(roleId !== null && roleId !== undefined){
            await this.getRoleById();
        }
	}

    async getRoleById(){
        var req = {
            api:'accounts',
            method:'GET',
            url:'roles/'+this.state.roleId,
            requireToken:true
        }
        let response =  await this.props.fetchRequest(req);

        if(response !== null&& response !== undefined){
            this.setState(prevState => ({ permissions: response.permissions, name: response.name  }));
        }
    }

	handleClose(){
		this.setState({
            show:false, 
            name:"",
            permissions:[],
            roleId:undefined
        });
    }

    async save(){
        if(this.state.name !== ""){	
            var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
            accountId = accountId.substring(1, accountId.length - 1);

            var obj = {
                name: this.state.name,
                permissions: this.state.permissions
            };

            var req = null;
            if(this.state.roleId !== null && this.state.roleId !== undefined){
                req = {
                    api:'accounts',
                    method:'PUT',
                    url:'roles/'+this.state.roleId+'/'+accountId,
                    obj:obj,
                    modal:this.props.modal_loader,
                    message_modal:this.props.t('modal.spin.saving'),
                    requireToken:true
                }
        
            }else{
                req = {
                    api:'accounts',
                    method:'POST',
                    url:'roles/'+accountId,
                    obj:obj,
                    modal:this.props.modal_loader,
                    message_modal:this.props.t('modal.spin.saving'),
                    requireToken:true
                }
            }
            
            var request = await this.props.fetchRequest(req);
            if(request !== null){
                this.props.getRoles();
            }
            this.handleClose();
        }else{
            Alert.error(this.props.t('error_alert.incomplete_fields'));
        }
		
    }

    handleChange(e){
		let	value = e.target.value;
        let name = e.target !== undefined ? e.target.name : e;

        this.setState({[name]: value});	
    }

    async handleCheck(e,permission){
        var index = this.state.permissions.findIndex(p=>p.key === permission.key);
        if(index !== -1){
            let permissions = [...this.state.permissions];
            permissions[index].action = e.target.checked;
            this.setState(permissions);
        }
    }

    async getPermissions(){
        var req = {
            api:'accounts',
            method:'GET',
            url:'lookups?type=permission_type',
            requireToken:true
        }
        let response =  await this.props.fetchRequest(req);

        if(response !== null&& response !== undefined){
            var permissions = [];
            await response.map((p, i)=>(
                permissions.push({
                    key: p.key,
                    action: i===0? true:false,
                    label: p.value
                })
            ));
            this.setState(prevState => ({
                permissions: permissions
            }));
        }
    }

	render(){
		const {t} = this.props;
		return (
			<Modal backdrop={false} show={this.state.show} size="md">
				<Modal.Header>	
					<Modal.Title>Rol</Modal.Title>
				</Modal.Header>
				<Modal.Body>
                    <FlexboxGrid >
                        <Col xs={12} >
                            <FormGroup>
                                <ControlLabel>Nombre</ControlLabel> <span style={{color:'red'}}>*</span>
                                <input type="text" className="form-control form-control-sm"
									value={this.state.name} required
									name="name"
									onChange={(e)=>this.handleChange(e)} 
								/>
                            </FormGroup>	
						</Col>
                        <Col xs={24} sm={24}>
                            <Table responsive hover style={{marginTop:'15px'}}>
                                <thead>
                                    <tr>
                                        <th>Permiso</th>
                                        <th align='center'>Habilitar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.permissions.map((p, i)=>(
                                        <tr key={i}>
                                            <td>{p.label}</td>
                                            <td align='center'>
                                                <input type="checkbox" name="enable" value={p.action}
                                                disabled={i===0}
                                                onChange={(e)=>this.handleCheck(e, p)} checked={p.action} /> 
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </FlexboxGrid>
				</Modal.Body>
				<Modal.Footer>
                    <Button appearance="primary" onClick={this.save}>{t('button.add')}</Button>
                    <Button appearance="default" onClick={this.handleClose}>{t('button.cancel')}</Button>
				</Modal.Footer>
			</Modal>
		);
	}

}

export default withTranslation('translations') (ModalRoles);