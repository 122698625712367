import React, { Component } from 'react';
import {Panel, Grid, Col, ControlLabel, Icon, IconButton} from 'rsuite';
import SaveBtn from './SaveBtn';
import SavedMark from './SavedMark';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import {GoogleApiWrapper} from 'google-maps-react';
import Geocode from "react-geocode";


class TaxData extends Component{
    constructor(props){
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleChangeAddressLine1 = this.handleChangeAddressLine1.bind(this);
        this.state = {
            enable:false,
            business_name:"",
            rfc:"",
            address: {
                addressLine1: "",
                addressLine2: "",
                city: "",
                state: "",
                zipCode: "",
                country: ""
            },
            is_saved:false,
            active_save:false,
            disabled:true
        }
    }

    async componentDidMount(){
        await this.props.onRef(this);
        await this.search(this.state.address.addressLine1);
    }

    componentWillUnmount(){
        this.props.onRef(null);
    }

    handleChange(e){
        this.setState({
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
            active_save: true,
            is_saved: false
        });
    }

    handleSelect = address => {
        geocodeByAddress(address)
          .then(async results => {
              await getLatLng(results[0]);
              this.setState({address:results[0].formatted_address});
              this.search(results[0].formatted_address);
            })
          .catch(error => console.error('Error', error));
    };

    handleChangeAddressLine1(value){
        this.setState({address:{addressLine1:value}, active_save: true, is_saved: false});
    }

    getMapsProperty(properties,key){
        var res = "";
        for(var i = 0; i<properties.length;i++){
            if(properties[i].types.includes(key)){
                res = properties[i].long_name;
                break;
            }
        }
        return res;
    }

    async search(searchAddress){
        // Get latitude & longitude from address.
      
        await Geocode.fromAddress(searchAddress).then(
          async response => {
            await this.setState({
                latitude: response.results[0].geometry.location.lat, 
                longitude: response.results[0].geometry.location.lng, 
                address:{
                    addressLine1: searchAddress,
                    addressLine2: this.state.address.addressLine2,
                    country: this.getMapsProperty(response.results[0].address_components,'country'),
                    city:  this.getMapsProperty(response.results[0].address_components,'locality'),
                    state: this.getMapsProperty(response.results[0].address_components,"administrative_area_level_1"),
                    zipCode: this.getMapsProperty(response.results[0].address_components,'postal_code'),
                },
                zoomMap: this.state.flagInitMap ? this.state.zoomMap:18,
                flagInitMap:false
            });
          },
          error => {
            console.error(error);
          }
        );
    }

    render(){
        const {t} = this.props;
        return (
            <Panel shaded>
                <i class="rs-panel-heading" style={{padding:"0px"}}> 
                    {t('screen.settings.account.tax_data.title')}
                </i>
                {this.state.is_saved &&
                    <SavedMark />
                }
                <Grid fluid>
                    <Col xs={24} sm={2} md={1}>
                        {this.state.disabled ?
                            <IconButton 
                                icon={<Icon icon='pencil' />}
                                color="blue"
                                size="sm"
                                onClick={()=>this.setState({disabled: !this.state.disabled})}
                            />
                        :
                            <SaveBtn t={this.props.t} active={this.state.active_save} action={this.props.dataUpdate} />
                        }
                    </Col>
                    <Col sm={8} className="form-group">
                        <input type="checkbox" name="enable" value={this.state.enable}
                            disabled={this.state.disabled}
                                onChange={(e)=>this.handleChange(e)} checked={this.state.enable} /> <span>{t('screen.settings.account.tax_data.enable')}</span>
                    </Col>
                    <Col className="form-group">
                        <Col xs={24} sm={16}>
                            <ControlLabel>{t('screen.settings.account.tax_data.business_name')}</ControlLabel>
                            <input className="form-control form-control-sm" name="business_name"
                                disabled={this.state.disabled}
                                value={this.state.business_name} onChange={(e)=>this.handleChange(e)} />
                        </Col>
                        <Col xs={24} sm={8}>
                            <ControlLabel>{t('screen.settings.account.tax_data.rfc')}</ControlLabel>
                            <input className="form-control form-control-sm" name="rfc"
                                disabled={this.state.disabled}
                                value={this.state.rfc} onChange={(e)=>this.handleChange(e)} />
                        </Col>
                    </Col>
                    
                    <Col className="form-group">
                        <Col xs={24}>
                            <ControlLabel>{t('screen.settings.location.form.address')}</ControlLabel> 
                            <PlacesAutocomplete
                                className="form-control form-control-sm"
                                value={this.state.address.addressLine1}                                
                                id="addressLine1"
                                name="addressLine1"
                                onChange={this.handleChangeAddressLine1}
                                onSelect={this.handleSelect}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <input className="form-control form-control-sm"
                                    {...getInputProps({
                                        placeholder: t('screen.settings.location.form.search_place'),
                                        className: 'form-control',
                                        disabled: this.state.disabled
                                    })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>{t('modal.spin.loading')}</div>}
                                        {suggestions.map((suggestion, i) => {
                                            const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                            <div key={i}
                                                {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                )}
                            </PlacesAutocomplete>
                        </Col>
                    </Col>

                    <Col xs={24} sm={12}>
                        
                        <ControlLabel>{t('screen.settings.location.form.city')} </ControlLabel> 
                        <input className="form-control form-control-sm" name="city" id="city" readOnly={!this.state.flagEdit}
                                value={this.state.address.city || ""} onChange={(e)=>this.handleChange(e, 'address')} />
                    </Col>

                    <Col xs={24} sm={12}>					
                        <ControlLabel>{t('screen.settings.location.form.state')} </ControlLabel> 
                        <input className="form-control form-control-sm" name="state" id="state" readOnly={!this.state.flagEdit}
                            value={this.state.address.state || ""} onChange={(e)=>this.handleChange(e, 'address')} />
                    </Col>

                    <Col xs={24} sm={12}>
                        <ControlLabel>{t('screen.settings.location.form.postal_code')} </ControlLabel> 
                        <input className="form-control form-control-sm" name="zipCode" id="zipCode" readOnly={!this.state.flagEdit}
                                value={this.state.address.zipCode || ""} onChange={(e)=>this.handleChange(e, 'address')} />
                    </Col>

                    <Col xs={24} sm={12}>					
                        <ControlLabel>{t('screen.settings.location.form.country')} </ControlLabel> 
                        <input className="form-control form-control-sm" name="country" id="country" readOnly={!this.state.flagEdit}
                        value={this.state.address.country || ""} onChange={(e)=>this.handleChange(e,'address')} />
                    </Col>

                </Grid>
            </Panel>
        )
    }
 
}
export default (GoogleApiWrapper({
    apiKey: ("AIzaSyAig1H6xWKyyS5J6NeADJhi8YR2A2N6akI")
})(TaxData));
