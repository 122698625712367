import React, {Component} from 'react';
import { Grid, Col, ControlLabel, IconButton, Icon, Alert } from 'rsuite';
import $ from 'jquery';
import TraditionalForm from './TraditionalForm';
import FlexibleForm from './FlexibleForm';
import ExtendedForm from './ExtendedForm';


class ScheduleForm extends Component{
    constructor(props){
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.selectRule = this.selectRule.bind(this);
        this.validExtendedHours = this.validExtendedHours.bind(this);

        this.state = {
            schedule: {
	            name:"",
	            label:"",
	            useBreakTime:false,
                activeWeekend:false,                
                nightShift:false,
                type:"",
	            days:[
	                {name:'monday', startTime:"", error_start:"", closingTime:"", error_close:"", breakTime:"", error_break:"",  breakReturnTime:"", error_return:"", check:true},
	                {name:'tuesday', startTime:"", error_start:"", closingTime:"", error_close:"", breakTime:"", error_break:"",  breakReturnTime:"", error_return:"", check:false},
	                {name:'wednesday', startTime:"", error_start:"", closingTime:"", error_close:"", breakTime:"", error_break:"",  breakReturnTime:"", error_return:"", check:false},
	                {name:'thursday', startTime:"", error_start:"", closingTime:"", error_close:"", breakTime:"", error_break:"",  breakReturnTime:"", error_return:"", check:false},
	                {name:'friday', startTime:"", error_start:"", closingTime:"", error_close:"", breakTime:"", error_break:"",  breakReturnTime:"", error_return:"", check:false},
	                {name:'saturday', startTime:"", error_start:"", closingTime:"", error_close:"", breakTime:"", error_break:"",  breakReturnTime:"", error_return:"", check:false},
	                {name:'sunday', startTime:"", error_start:"", closingTime:"", error_close:"", breakTime:"", error_break:"",  breakReturnTime:"", error_return:"", check:false},
                ]
            },
            scheme_types:[],
            edit_schedule:false,
            suscription:false,
            inicio:false
        }
    }

    async componentDidMount(){
        this.props.onRef(this);
        if(this.state.scheme_types.length === 0){
            var lang = atob(sessionStorage.getItem(btoa('lang')));
            
            var scheme_types = await this.props.fetchRequest({
                api:'accounts', method:'GET', 
                url:'lookups/schema_type/'+lang, 
                requireToken: true
            });

            this.setState({
                scheme_types: scheme_types,
                suscription: sessionStorage.getItem('suscription') === 'free' ? true : false
            });
        }
            
        if(!this.props.details){
            if(!this.props.wizard){
                if(this.props.schedule !== undefined){
                    this.setState({
                        schedule: this.props.schedule,
                        edit_schedule:true
                    });
                }
                
            }
        }
    }

    componentDidUpdate(){
       if(!this.props.details && !this.props.wizard){
            if(this.props.schedule !== undefined){
                if(this.state.schedule.label !== this.props.schedule.label ){   
                    
                    this.setState({
                        schedule: this.props.schedule,
                        inicio: true
                    });
                                        
                }
            }
        }
        
    }

    handleChange(e){
        this.setState({
            schedule:{
                ...this.state.schedule,
                [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
            }
        });

        if(e.target.name === 'minimumHours'){
            setTimeout(()=>{
                $('#minimumHours').blur();
            }, 500);
        }
        if(e.target.name === 'activeWeekend' && !e.target.checked){
            this.state.schedule.days.map((s, i)=>{
                if(i > 4 && !e.target.checked){
                    s.check = false;                    
                }

                return s;
            });

            this.forceUpdate();
        }

        if(!this.props.wizard){
            if(!this.props.details){
                setTimeout(()=>{
                    this.props.updateRules(this.state.schedule);
                }, 800);
            }
            
        }else{
            this.props.changeColorNext();
        }
        
        
    }

    selectRule(e){
        var schedules = this.props.schedules;
        var index = schedules.findIndex(obj => 
            e.target.name === 'type' ?
                obj.type === e.target.value
            : obj.name === e.target.value
        );

        var schedule = {
            name:"",
            label:"",
            useBreakTime:false,
            activeWeekend:false,                
            nightShift:false,
            minimumHours:"",
            type:e.target.name === 'type' ? e.target.value : 'traditional',
            days:[
                {name:'monday', startTime:"", error_start:"", closingTime:"", error_close:"", breakTime:"", error_break:"",  breakReturnTime:"", error_return:"", check:true},
                {name:'tuesday', startTime:"", error_start:"", closingTime:"", error_close:"", breakTime:"", error_break:"",  breakReturnTime:"", error_return:"", check:false},
                {name:'wednesday', startTime:"", error_start:"", closingTime:"", error_close:"", breakTime:"", error_break:"",  breakReturnTime:"", error_return:"", check:false},
                {name:'thursday', startTime:"", error_start:"", closingTime:"", error_close:"", breakTime:"", error_break:"",  breakReturnTime:"", error_return:"", check:false},
                {name:'friday', startTime:"", error_start:"", closingTime:"", error_close:"", breakTime:"", error_break:"",  breakReturnTime:"", error_return:"", check:false},
                {name:'saturday', startTime:"", error_start:"", closingTime:"", error_close:"", breakTime:"", error_break:"",  breakReturnTime:"", error_return:"", check:false},
                {name:'sunday', startTime:"", error_start:"", closingTime:"", error_close:"", breakTime:"", error_break:"",  breakReturnTime:"", error_return:"", check:false},
            ]
        }

        if(index !== -1){
            schedule = schedules[index];
        }

        this.setState({schedule: schedule});

        if(e.target.name === 'type' && (this.props.details || this.props.wizard)){
            this.props.showHideRule(e.target.value);
        }

        setTimeout(()=>{
            if(this.props.wizard){
                this.props.changeColorNext();
            }
        }, 500);
        
    }

    editSchedule(e){
        this.setState({[e.target.name]: !this.state.edit_schedule})
    }

    validExtendedHours(e){
        if(parseInt(e.target.value) < 9 || parseInt(e.target.value) > 36){
            if(!this.props.wizard && !this.props.details){
                this.props.handleChange({target:{ name:'active_save', value:false} });
                this.props.handleChange({target:{ name:'is_saved', value:false} });
            }           
            
            $('#hoursFulfilled').addClass('error');
            Alert.error(this.props.t('error_alert.invalid_hours'));
        }else{
            $('#hoursFulfilled').removeClass('error');
        }
    }

    updateRules(e){
        this.props.onUpdate(false);

        this.setState({edit_schedule: !this.state.edit_schedule});
    }

    render(){
        const {t} = this.props;
       
        return (
            <form>
                <Grid fluid className='checker-rules'>
                    <Col xs={24}>
                        {(this.state.schedule.type === "traditional" || this.state.schedule.type === "flexible") &&
                            <div  style={{marginTop:8}}>
                                <Col xs={24} sm={14} md={8} lg={8}>
                                    <input type="checkbox"
                                        name="nightShift" id="nightShift" 
                                        value={this.state.schedule.nightShift}
                                        onChange={(e)=>this.handleChange(e)} 
                                        checked={this.state.schedule.nightShift}
                                        disabled={this.props.details || this.props.wizard?
                                            this.props.select_name ? !this.state.edit_schedule : false
                                        : this.props.edit_schedule}
                                    /> 
                                    <span style={{marginLeft:8}}>
                                        {t('screen.settings.account.checker_rules.enable_night')}
                                    </span>
                                </Col>
                                <Col xs={24} sm={14} md={8} lg={8}>
                                    <input type="checkbox" 
                                        name="useBreakTime" id="useBreakTime" 
                                        value={this.state.schedule.useBreakTime}
                                        onChange={(e)=>this.handleChange(e)} 
                                        checked={this.state.schedule.useBreakTime}
                                        disabled={this.props.details || this.props.wizard? 
                                            this.props.select_name ? !this.state.edit_schedule : false 
                                        : this.props.edit_schedule}
                                        /> 
                                    <span style={{marginLeft:8}}>
                                        {t('screen.settings.account.checker_rules.enable_break')}
                                    </span>
                                </Col>
                            </div>
                        }
                    </Col>


                    {this.props.details &&
                        <Col xs={4} sm={2} style={{marginTop:'29px'}}>
                            <IconButton 
                                icon={<Icon icon={this.state.edit_schedule ? "save" : "pencil"} />}
                                onClick={(e)=>this.state.edit_schedule ? this.updateRules(e) : this.setState({edit_schedule: !this.state.edit_schedule})}
                                color="blue"
                                size="sm"
                            />
                        </Col>
                    }
                    <Col xs={24} sm={10} md={8} lg={5}>
                        <ControlLabel>{t('screen.settings.account.checker_rules.type_schema')}</ControlLabel>
                        <select className="form-control form-control-sm" id="checker_rules_type" required
                            name="type" value={this.state.schedule.type}
                            disabled={this.props.details || this.props.wizard ? 
                                this.state.edit_schedule ? this.state.suscription : true
                            : this.props.edit_schedule}
                            onChange={(e)=>this.props.details || this.props.wizard ? 
                                this.selectRule(e)
                            : this.handleChange(e) }>
                            <option value="">{t('general.select')}</option>
                            {this.state.scheme_types.map((s, i)=>
                                <option key={i} value={s.key}>{s.value}</option>
                            )}
                        </select>
                    </Col>
                    
                    {(this.state.schedule.type !== 'presence' && this.state.schedule.type !== '24x24') &&
                        <Col xs={24} sm={10} md={8} lg={5}>
                            <ControlLabel>{t('screen.settings.account.checker_rules.rule_name')}</ControlLabel> <span style={{color:'red'}}>*</span>
                            {this.props.select_name ?
                                <select className="form-control form-control-sm"
                                    value={this.state.schedule.name}
                                    name="name"
                                    id="checker_rules_name"
                                    required
                                    disabled={this.props.details || this.props.wizard ? 
                                        !this.state.edit_schedule
                                    : this.props.edit_schedule}
                                    onChange={(e)=>this.selectRule(e)}
                                >
                                    <option value="" disabled>{t('general.select')}</option>
                                    {this.props.schedules.map((s, i)=>
                                        s.show &&
                                            <option key={i} value={s.name}>{s.name}</option>
                                    )}
                                </select>
                            :
                                <input type="text" className="form-control form-control-sm"
                                    value={this.state.schedule.name}
                                    name="name"
                                    id="checker_rules_name"
                                    required
                                    disabled={this.props.details || this.props.wizard ? 
                                        !this.state.edit_schedule
                                    : this.props.edit_schedule}
                                    onChange={(e)=>this.handleChange(e)} 
                                />
                            }
                        </Col>
                    }
                    {this.props.wizard &&
                        <Col xs={24} sm={2} style={{marginTop:22}}>
                            <IconButton 
                                color={sessionStorage.getItem('suscription') === 'free' && this.props.schedules.length >= 1 ? 
										"default" 
								: "blue"}
                                icon={<Icon icon="plus" />}
                                circle
                                onClick={(e)=>this.props.newRule(e)}
                            />
                        </Col>
                    }

                    
                    {(this.state.schedule.name !== "" && this.props.wizard) &&
                        <Col xs={24}>
                            <Col xs={24} sm={3}>
                                <input type="checkbox" value={this.state.edit_schedule} checked={this.state.edit_schedule} 
                                    name="edit_schedule" id="edit_scheduñe"
                                    onChange={()=>this.setState({edit_schedule: !this.state.edit_schedule})}
                                /> <span>{t('button.edit')}</span>
                            </Col>
                        </Col>
                    }
                    {this.state.schedule.type === 'traditional' ?
                        <Col xs={24}>
                            <TraditionalForm 
                                {...this.props}
                                show_name={this.props.show_name}
                                select_name={this.props.select_name}
                                edit_schedule={this.props.details || this.props.wizard ? 
                                    this.state.edit_schedule
                                : !this.props.edit_schedule}
                                schedule={this.state.schedule}
                                handleChange={this.handleChange}
                                changeRules={this.props.changeRules}
                            />
                        </Col>
                    : this.state.schedule.type === 'flexible' ?
                        <Col xs={24}>
                            <FlexibleForm 
                                {...this.props}
                                schedule={this.state.schedule}
                                edit_schedule={this.props.details || this.props.wizard ? 
                                    this.state.edit_schedule
                                : !this.props.edit_schedule}
                                changeRules={this.props.changeRules}
                            />
                        </Col>
                    : this.state.schedule.type === '24x24' ?
                        <Col xs={24}>
                            <ExtendedForm 
                                {...this.props}
                                schedule={this.state.schedule}
                                edit_schedule={this.props.details || this.props.wizard ? 
                                    this.state.edit_schedule
                                : !this.props.edit_schedule}
                                changeRules={this.props.changeRules}
                                hoursFulfilled={this.state.hoursFulfilled}
                                handleChange={this.handleChange}
                                validHours={this.validExtendedHours}
                            />
                        </Col>
                    :null}
                </Grid>

                {this.props.match.url !== "/settings/employee/new"?
                    this.props.select_name ?
                        (this.state.schedule.name !== "" && this.props.wizard)  &&
                            <Grid fluid>
                                <Col xs={24} sm={2} style={{marginTop:22, float:'right'}}>
                                    <IconButton 
                                        color={this.state.edit_schedule ?
                                                "blue" 
                                        : ""}
                                        icon={<Icon icon={this.state.edit_schedule ? "save" : "pencil"} />}
                                        onClick={(e)=>this.props.updateSchedules(e)}
                                    />
                                </Col>
                            </Grid>
                    :null
                :null}
            </form>
        )
    }
}

export default ScheduleForm;