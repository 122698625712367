import React, { Component } from 'react';
//import { Checkout } from './Checkout';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import CardForm from './CardForm';

export default class CheckoutForm extends Component{
    constructor(props){
        super(props);

        this.getCardData = this.getCardData.bind(this);
    
    }

    componentDidMount(){
        this.props.onRef(this);
    }

    async getCardData(){
        var res = await this.card_form.getCardData();
        return res;
    }

    render(){
        return(
            <ElementsConsumer>
                {({stripe, elements}) => (
                    <CardForm {...this.props} stripe={stripe} elements={elements} {...this.props} onRef={ref => this.card_form = ref}/>
                )}
            </ElementsConsumer>
        )
    }
};