import React, { Component } from 'react';
import {Panel, Grid, Icon, Col, IconButton} from 'rsuite';
import {Table} from 'react-bootstrap';
import ModalRoles from './ModalRoles';
import { swalAction } from '../../../lib/functions';


class Roles extends Component{
    constructor(props){
        super(props);

        this.getRoles = this.getRoles.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.deleteRole = this.deleteRole.bind(this);
        
        this.state = {
            roles: []
        }
    }

    async componentDidMount(){
        this.props.onRef(this);
        await this.getRoles();
    }

    componentWillUnmount(){
        this.props.onRef(null);
    }

    openModal(){
        this.modal_roles.handleShow();
    }    

    openModalEdit(roleId){
        this.modal_roles.handleShow(roleId);
    }  

    async getRoles(){
        var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
        accountId = accountId.substring(1, accountId.length - 1);

        var req = {
            api:'accounts',
            method:'GET',
            url:'roles/by-account/'+accountId,
            requireToken:true
        }
        let response =  await this.props.fetchRequest(req);

        if(response !== null&& response !== undefined){
            this.setState(prevState => ({
                roles: response
            }));
        }
    }

    onDelete(roleId){
        console.log(roleId);
        var obj = {
            title           : "Esta acción eliminará el rol seleccionado",
            text            : "¿Deseas continuar?",                                
            icon            : 'warning',
            showConfirm     : true,
            showCancel      : true,
            textConfirm     : this.props.t('modal.alert.confirms.yes'),
            colorConfirm    : '#3085d6',
            colorCancel     : '#d33',
            textcancel      : this.props.t('modal.alert.confirms.no'),
            values          : roleId,
            fn              : this.deleteRole
        };
        swalAction(obj);
    }

    async deleteRole(roleId){
        var req = await this.props.fetchRequest({
            api:'accounts',
			method:'DELETE',
			url:'roles/'+roleId,
			requireToken:true,
			modal:this.props.modal_loader,
			message_modal:this.props.t('modal.spin.deleting')
        });
        
        await this.getRoles();
    }

    render(){
        const {t} = this.props;

        return (
            <Panel shaded>
                <i class="rs-panel-heading" style={{padding:"0px"}}> 
                    {t('screen.settings.account.roles.title')}
                </i>
                <Grid fluid className="form-group">
                    <Col xs={4}>
                        <IconButton className="s-primary-btn" 
                            icon={<Icon icon="plus" />}
                            circle
                            size="sm"
                            onClick={()=>this.openModal()}
                        />
                    </Col>
                    <Table responsive hover>
                        <thead>
                            <tr>
                                <th>{this.props.t('screen.settings.account.roles.name')}</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.roles.map((r, i)=>(
                                <tr key={i}>
                                    <td>{r.name}</td>
                                    <td  style={{width:'5%'}} align='right'>
                                        <Icon icon="pencil" size="lg" 
                                            onClick={()=>this.openModalEdit(r.id)}
                                            title={t('button.edit')}/>
                                    </td>
                                    <td  style={{width:'5%'}} align='right'>
                                        <Icon icon="trash" size="lg" 
                                            onClick={()=>this.onDelete(r.id)}
                                            title={t('button.delete')}	/>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Grid>

                <ModalRoles {...this.props} onRef={ref => this.modal_roles = ref} getRoles={this.getRoles} />
            </Panel>
        )
    }
 
}

export default Roles;