import React from 'react';
import { Grid, Col, ControlLabel } from 'rsuite';

const TaxData = (props)=>{
    const {t} = props;
    return (
        <Grid fluid>
            <Col xs={24} sm={12} md={8} lg={6}>
                <ControlLabel>{t('screen.settings.employee.details.form.rfc')}</ControlLabel>
                <input className="form-control form-control-sm" name="rfc" id="rfc" value={props.rfc}
                    disabled={!props.edit_tax} onChange={(e)=>props.handleChange(e)}
                />
            </Col>
            <Col xs={24} sm={12} md={8} lg={5}>
                <ControlLabel>{t('screen.settings.employee.details.form.nss')}</ControlLabel>
                <input className="form-control form-control-sm" name="nss" id="nss" value={props.nss}
                    disabled={!props.edit_tax} onChange={(e)=>props.handleChange(e)}
                />
            </Col>
            <Col xs={24} sm={12} md={8} lg={5}>
                <ControlLabel>{t('screen.settings.employee.details.form.salary')}</ControlLabel>
                <input className="form-control form-control-sm" name="salary" id="salary" value={props.salary}
                    disabled={!props.edit_tax} onChange={(e)=>props.handleChange(e)}
                />
            </Col>
        </Grid>
    )
}

export default TaxData;