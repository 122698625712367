import React from 'react';
import {withTranslation} from 'react-i18next';
import Layout from '../components/Layout';
import { Image } from 'react-bootstrap';


const Home = (props)=>{
	return (
		<Layout {...props}>
			<div className="s-image-home">
				<Image src="../images/image.png" alt="logo" fluid />
			</div>
		</Layout>
	)
}

export default withTranslation('translations') (Home);