let url = "";
switch(window.location.hostname){
	case "localhost":
		url = "https://api.justcheck.us/";
	break;
	case "app.justcheck.us":
		url = "https://api.justcheck.us/";
	break;
	case "app-dev.justcheck.us":
		url = "https://api-dev.justcheck.us/";
	break;
}


const config = {
	apiGateway:{
		API: {
			accounts:url+'accounts-api/',
			leads:url+'leads-api/',
			auth:url+'auth-api/',
			check:url+'checks-api/',
			reports:url+'reporting-api/',
			payments:url+'payments-api/'
		}
	}
};

export default config;