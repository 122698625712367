import React, {Component} from 'react';

import {
	Modal, Loader
} from 'rsuite';

class ModalLoader extends Component{
	constructor(props){
		super(props);

		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);

		this.state = {
			show: false,
			title:"",
		}
	}

	componentDidMount(){
		this.props.onRef(this);
	}

	componentDidUmount(){
		this.props.onRef(null);
	}

	handleShow(title, message, name = null){
		this.setState({
			show:true,
			title:title,
		});
	}

	handleClose(){
		this.setState({show:false});
	}

	render(){
		return (
			<Modal show={this.state.show} onHide={this.handleClose} size="xs">
				<Modal.Body>
					<div className="form-group">
						<div style={{textAlign:'center', fontSize:'1.5em', height:'65px'}}>
							<Loader size="lg" backdrop content={this.state.title} vertical />
						</div>
					</div>
				</Modal.Body>
			</Modal>
		);
	}

}

export default ModalLoader;