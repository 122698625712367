import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import {Col, Panel, FlexboxGrid} from 'rsuite';
import {Image} from 'react-bootstrap';

import Layout from '../../components/Layout';
class Reports extends Component{
    constructor(props){
        super(props);

        this.state = {
            categories:[{
                title:'assistances',
                reports:[
                    {
                        name:'entries.name',
                        image: 'images/reportes/entries.png',
                        route:'/reports/entries',
                        disabled:false,
                        active:true
                    },
                    {
                        name:'check-in-face.name',
                        image: 'images/reportes/check_in_face.png',
                        route:'/reports/check_in_face',
                        disabled:false,
                        active:false
                    },
                    {
                        name:'attendance.name',
                        image: 'images/reportes/attendance.png',
                        route:'/reports/attendance',
                        disabled:false,
                        active:false
                    },
                    {
                        name:'visit.name',
                        image: 'images/reportes/visit.png',
                        route:'/reports/visit',
                        disabled:false,
                        active:false
                    },
                    {
                        name:'overtime.name',
                        image: 'images/reportes/overtime.png',
                        route:'/reports/overtime',
                        disabled:false,
                        active:false
                    },
                ]
            }, {
                title:'absences',
                reports:[
                    {
                        name:'absences.name',
                        image: 'images/reportes/absences.png',
                        route:'/reports/absences',
                        disabled:false,
                        active:false
                    },
                    {
                        name:'calendar.name',
                        image: 'images/reportes/calendar.png',
                        route:'/reports/calendar',
                        disabled:false,
                        active:true
                    }
                ]
            }]
        }
    }

    componentDidMount(){
        var suscription = sessionStorage.getItem('suscription');

        var categories = this.state.categories;

        categories.map((c, j) => 
            c.reports.map((r, i)=>
                j === 0 ?
                    (i !== 0 && i !== 2) && suscription === 'free' ? r.disabled = false
                    : i === 3 && suscription === "Basic Account"? r.disabled = false
                    : r.disabled = true
                : j === 1 && suscription === 'free'? r.disabled = false
                :suscription === 'free' ?
                    r.disabled = true
                : r.disabled = true
            )
        );
        
        this.setState({categories});
        
    }

    render(){
        const {t} = this.props;
    	return(
    		<Layout {...this.props}>
                <h3 className="s-title-page">{t('screen.reports.name')}</h3>
		        <FlexboxGrid justify="center">
                    <Col xs={24} sm={22} md={22} lg={22}>
                        {this.state.categories.map((cat, i)=>
                            <Panel key={i} header={t('screen.reports.categories.'+cat.title)} style={{marginBottom:8}}>
                                {cat.reports.map((rep, j)=>
                                    <Col key={j} xs={24} sm={12} md={8} lg={6} style={{textAlign:'center', marginBottom:8}}>
                                        <Panel shaded={false} bordered bodyFill className="panel-report">
                                            <div className="image-panel-report">
                                                <Image src={rep.image}
                                                    onClick={(e)=> rep.disabled ?
                                                        this.props.history.push(rep.route)
                                                    : e.preventDefault()}
                                                    style={rep.disabled ? {cursor:'pointer', width:'100%'} : {cursor:'no-drop', width:'100%', filter: 'grayscale(1)'}}
                                                />                                                
                                            </div>
                                            <Panel header={t('screen.reports.'+rep.name)} className="report-name"></Panel>
                                        </Panel>
                                    </Col>
                                )}
                            </Panel>
                        )}
                    </Col>
                </FlexboxGrid>
		    </Layout>
    	)
        
    }
}

export default withTranslation('translations') (Reports);