import React, {Component} from 'react';

import {Button, Modal, Col, FlexboxGrid, ControlLabel, Alert } from 'rsuite';
import { withTranslation } from 'react-i18next';

class ModalLogin extends Component{
	constructor(props){
		super(props);

		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
        this.goToLogin = this.goToLogin.bind(this);
        this.loginNow = this.loginNow.bind(this);

		this.state = {
            show: false,
            password:""
		}
	}

	componentDidMount(){
		this.props.onRef(this);
	}

	componentDidUmount(){
		this.props.onRef(null);
	}

	handleShow(title){
		this.setState({
			show:true
		});
	}

	handleClose(){
		this.setState({show:false});
	}

	goToLogin(){
        this.props.history.push('/login');
        this.handleClose();
    }
    
    handleChange(e, value){
        if (value) {
            this.setState({
                [value]:{
                    ...this.state[value], 
                    [e.target.name]: e.target.value
                }
            });
        } else {
            this.setState({[e.target.name]: e.target.value});
        }
    }

    async loginNow(){
        if(this.state.password === "" || this.state.password === null || this.state.password === undefined){
            Alert.error(this.props.t('error_alert.incomplete_fields'));
            return;
        }

        let obj = {
            email: sessionStorage.getItem("userEmail"),
            password: this.state.password
        }

        let req = {
              api:'auth',
              method:'POST',
              url:'auth/login',
              obj:obj,
              modal:this.props.props.modal_loader,
              message_modal:this.props.t('modal.spin.start')
          }
        let request = await this.props.props.fetchRequest(req);
        if(request !== undefined){
            sessionStorage.setItem('token', request.token);
            this.handleClose();
        }
    }

	render(){
        const {t} = this.props;
		return (
			<Modal backdrop={false} show={this.state.show} size="xs">
				<Modal.Header>	
					<Modal.Title>{t('screen.modal_login.title')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
                    <FlexboxGrid justify="center">
                        <Col xs={24}>
                            <ControlLabel>{t('screen.modal_login.password')}</ControlLabel> 
                            <input className="form-control" name="password" id="password" type="password" 
                                autocomplete="off" onChange={(e)=>this.handleChange(e)} 
                                value={this.state.password}/>
                        </Col>
                    </FlexboxGrid>
				</Modal.Body>
				<Modal.Footer>
					<FlexboxGrid justify="center">
						<Col xs={24} style={{textAlign:'center'}}>
							<Button appearance="primary" onClick={this.loginNow} block>
								{t('button.continue')}
							</Button>
						</Col>
					</FlexboxGrid>
                    <FlexboxGrid justify="center" style={{paddingTop:"10px"}}>
                        <Col xs={24} style={{textAlign:'center'}}>
							<Button appearance="ghost" onClick={this.goToLogin} block>
								{t('button.cancel')}
							</Button>
						</Col>
					</FlexboxGrid>
				</Modal.Footer>
			</Modal>
		);
	}

}

export default withTranslation('translations') (ModalLogin);