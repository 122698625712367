import React, {Component} from 'react';

import {
	Modal, Button, Grid, Col
} from 'rsuite';

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';

class ModalTemplate extends Component{
	constructor(props){
		super(props);

		this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onSave = this.onSave.bind(this);

		this.state = {
            show: false,
            columns:[],
            data:[],
		}
	}

	componentDidMount(){
		this.props.onRef(this);
	}

	componentDidUmount(){
		this.props.onRef(null);
	}

	handleShow(l, c){
		this.setState({
            show:true,
            data:l,
            columns:c
        });
        
	}

	handleClose(){
		this.setState({show:false, data:[], columns:[]});
    }

    async onSave(){
        var obj = [];
        this.state.data.map((d)=>{
            var item = {};
            var keyNames = Object.keys(d);
            var special_fields = [];

            keyNames.forEach((key)=>{
                if(key.indexOf('@') === -1){
                    item = {
                        ...item,
                        [key]:d[key]
                    }
                }else{
                    special_fields.push({
                        title: key.replace('@', ''),
                        value: d[key].toString()
                    });
                }
            });

            item = {
                ...item,
                attributes:{
                    ...item.attributes,
                    special_fields: special_fields
                }
            }

            return obj.push(item);
        });

        var res = await this.props.fetchRequest({
            api:'accounts',
            url:'employees/save-many',
            method:'POST',
            obj: obj,
            requireToken:true,
            modal:this.props.modal_loader,
            message_modal:this.props.t('modal.spin.loading')
        });

        if(res !== undefined){
            setTimeout(()=>{
                this.props.reloadTable();
                this.handleClose();
            }, 500);
            
        }
        
    }

	render(){
        const {t} = this.props;
		return (
			<Modal size="lg" show={this.state.show} onHide={this.handleClose} backdrop={false}>
                <Modal.Title>{t('screen.settings.employee.modal_preview.title')}</Modal.Title>
				<Modal.Body>
					<div className="form-group">
						<Grid fluid>
                            <Col xs={24}>
                                <BootstrapTable 
                                    keyField={this.state.columns.length > 0 ? this.state.columns[0].dataField : ""}
                                    data={this.state.data}
                                    columns={this.state.columns}
                                    cellEdit={ cellEditFactory({
                                        mode: 'click',
                                        blurToSave: true
                                    }) }
                                />
                            </Col>
                        </Grid>
					</div>
				</Modal.Body>
                <Modal.Footer>
                    <div style={{marginTop:10}}>
                        <Button color="blue" onClick={()=>this.onSave()}>{this.props.t('button.load')}</Button>
                        <Button style={{marginLeft:10}} onClick={this.handleClose}>{this.props.t('button.cancel')}</Button>
                    </div>                    
                </Modal.Footer>
			</Modal>
		);
	}

}

export default ModalTemplate;