import Swal from 'sweetalert2';
var config = require('../config');

export const fetchRequest = ({
		api, 
		method, 
		url, 
		obj = null, 
		modal = null, 
		message_modal = "", 
		requireToken = false,
		is_file = false
	})=>{
	let apiGateway = config.default.apiGateway.API[api];

	let token = sessionStorage.getItem('token');

	if(modal !== null){
		modal.handleShow(message_modal);
	}
	
	return fetch(apiGateway+url,{
		method: method,
		body: obj != null ? JSON.stringify(obj) : null,
		responseType: is_file ? 'blob' : null,
		headers: new Headers(
			requireToken ?
				{
				'Authorization'	: 'Bearer '+token,
				'Accept'		: 'application/json',
				'Content-Type' 	: 'application/json',
				}
			:
				{
				'Accept'		: 'application/json',
				'Content-Type' 	: 'application/json',
				//'Access-Control-Allow-Origin':'*'
				}
		)
	}).then(res => {
		if(modal !== null){
			modal.handleClose();	
		}
		
		if(res.ok){
			return !is_file ? res.json() : res.blob();
		}else{
			res.text().then(msg =>{
				msg = JSON.parse(msg);
				console.log(url)
				if(url !== 'schemas/by-account' && url !== 'payment-methods/by-account'){
					if(msg.message === null){
						Swal.fire('Error', '', 'error');	
					}else{
						Swal.fire('Error', msg.message, 'error');	
					}
					
				}				
				
			});
		}
	}).then(response => {
		if(response !== undefined){
			
			return response;
		}
	}).catch(error => {
		if(modal !== null){
			modal.handleClose();	
		}
	});
}

export const fileDownLoad = (name, extension, type, message)=>{
	var blob = new Blob([message], {type: type});
	var downloadUrl = URL.createObjectURL(blob);
	var a = document.createElement("a");
	a.href = downloadUrl;
	a.download = name+extension;
	document.body.appendChild(a);
	a.click();
}