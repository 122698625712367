import React, { Component } from 'react';
import { Panel, FlexboxGrid, Button, Col } from 'rsuite';
import { swalAction } from '../../lib/functions';

class DeleteAccount extends Component{
    constructor(props){
        super(props);

        this.onDelete = this.onDelete.bind(this);
        this.deleteAccount = this.deleteAccount.bind(this);

        this.state = {accept_delete:false}
    }

    componentDidMount(){
        this.props.onRef(this);
    }

    onDelete(){
        var obj = {
            title           : this.props.t('modal.alert.titles.continue'),
            text            : this.props.t('modal.alert.messages.delete_account'),
            icon            : 'warning',
            showConfirm     : true,
            showCancel      : true,
            textConfirm     : this.props.t('modal.alert.confirms.continue'),
            colorConfirm    : '#3085d6',
            colorCancel     : '#d33',
            textcancel      : this.props.t('modal.alert.confirms.no'),
            values          : '',
            fn              : ()=>{this.deleteAccount()}
        }
        swalAction(obj);
    }

    async deleteAccount(){
        var req = await this.props.fetchRequest({
            api:'payments',
			method:'DELETE',
			url:'subscription/destroy-account',
			requireToken:true,
			modal:this.props.modal_loader,
			message_modal:this.props.t('modal.spin.deleting')
        });

        if(req !== undefined){
            window.location.href = "https://www.justcheck.us/#/";
        }
    }

    render(){
        const {t} = this.props;

        return(
            <Panel header={t('screen.plans.delete_account')} shaded bordered>
                <Col xs={24} className="form-group">
                    <input type="checkbox" name="this.state.accept_delete" id="this.state.accept_delete" value={this.state.accept_delete}
                        checked={this.state.accept_delete} onChange={()=>this.setState({accept_delete:!this.state.accept_delete})}
                        className="rs-col-xs-1" style={{marginTop:5}}
                    />  <p style={{textAlign:'justify'}} className="rs-col-xs-23">
                        {t('screen.plans.message_delete_account.p1')}<br/>
                        {t('screen.plans.message_delete_account.p2')}<br/>
                        {t('screen.plans.message_delete_account.p3')}<br/>
                        {t('screen.plans.message_delete_account.p4')}<br/>
                        {t('screen.plans.message_delete_account.p5')}<br/>
                        {t('screen.plans.message_delete_account.p6')}<br/>
                    </p>
                </Col>
               
               <Col xs={24} className="form-group">
                    <FlexboxGrid justify="center">
                        <Button color="red" 
                            className="s-btn-primary" 
                            onClick={()=>this.onDelete()}
                            disabled={!this.state.accept_delete}
                        >
                            {t('button.delete_account')}
                        </Button>
                    </FlexboxGrid>
                </Col>
            </Panel>
        )
    }
}

export default DeleteAccount;