import React, {Component} from 'react';
import {Modal, ControlLabel, Col, Grid, IconButton, Icon, Button, Alert} from 'rsuite';
import { Table } from 'react-bootstrap';

class ModalFields extends Component{
    constructor(props){
        super(props);

        this.open         = this.open.bind(this);
        this.close        = this.close.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.addOption    = this.addOption.bind(this);
        this.deleteOption = this.deleteOption.bind(this);

        this.state = {
            show: false,
            nameField:"",
            typeField:"",
            default:"",
            required:false,
            actived:true,
            comboValue:"",
            comboDefault:false,
            options:[],
        }
    }

    componentDidMount(){
        this.props.onRef(this);
    }

    componentWillUnmount(){
        this.props.onRef(null);
    }

    open(){
        this.setState({show: true});
    }

    close(){
        this.setState({
            show: false,
            nameField:"",
            typeField:"",
            default:"",
            required:false,
            actived:true,
            comboValue:"",
            comboDefault:false,
            options:[],
        });
    }

    handleChange(e){
        if(e.target.type === 'checkbox'){
            this.setState({[e.target.name]: e.target.checked});
        }else{
            this.setState({[e.target.name]: e.target.value});
        }
    }

    addOption(){
        if(this.state.comboValue.trim() !== ""){
            var options = this.state.options;

            var index = options.findIndex(obj => 
                obj.text === this.state.comboText && 
                obj.comboDefault === true
            );

            if(index === -1){
                options.push({
                    value   : this.state.comboValue,
                    default : this.state.comboDefault
                });

                this.setState({
                    options     : options,
                    comboValue  : "",
                    comboDefault: false
                });

            }else{
                Alert.error('Ya existe un elemento con el texto ingresado');
            }
        }
    }

    deleteOption(e, i){
        var options = this.state.options;

        options.splice(i, 1);

        this.setState({options: options});
    }


    render(){
        const {t} = this.props;
        return (
            <Modal size="md" overflow show={this.state.show} onHide={this.close}>
                <Modal.Header>
                    <Modal.Title>{t('modal.employ_fields.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <Grid fluid>
                            <Col xs={24} sm={8}>
                                <ControlLabel>{t('modal.employ_fields.name')}</ControlLabel>
                                <input name="nameField" id="nameField" value={this.state.nameField}
                                    onChange={this.handleChange} className="form-control form-control-sm" />
                            </Col>
                            <Col xs={24} sm={8}>
                                <ControlLabel>{t('modal.employ_fields.type')}</ControlLabel>
                                <select name="typeField" id="typeField" value={this.state.typeField}
                                    onChange={this.handleChange} className="form-control form-control-sm">
                                    <option value="">{t('general.select')}</option>
                                    <option value="string">{t('modal.employ_fields.types.open')}</option>
                                    <option value="string_enum">{t('modal.employ_fields.types.select')}</option>
                                    <option value="number">{t('modal.employ_fields.types.numeric')}</option>
                                    <option value="string_date">{t('modal.employ_fields.types.date')}</option>
                                    <option value="string_yesNo">{t('modal.employ_fields.types.yes_no')}</option>
                                </select>
                            </Col>
                            {(this.state.typeField === 'string' || this.state.typeField === 'string_yesNo') &&
                                <Col xs={24} sm={8}>
                                    <ControlLabel>{t('modal.employ_fields.default')}</ControlLabel>
                                    {this.state.typeField === 'string' &&
                                        <input name="default" id="default" value={this.state.default}
                                            onChange={this.handleChange} className="form-control form-control-sm" />
                                    }
                                    {this.state.typeField === 'string_yesNo' &&
                                        <select name="default" id="default" value={this.state.default}
                                            onChange={this.handleChange} className="form-control form-control-sm">
                                            <option value="">{t('general.select')}</option>
                                            <option value="Yes">Si</option>
                                            <option value="No">No</option>
                                        </select>
                                    }
                                </Col>
                            }
                        </Grid>
                        
                        <Grid fluid style={{marginTop:5}}>
                            <Col xs={24}>
                                <input type="checkbox" name="required" id="required" value={this.state.required}
                                    onChange={this.handleChange} checked={this.state.required} /> <span>{t('modal.employ_fields.required')}</span>
                            </Col>
                            <Col xs={24}>
                                <input type="checkbox" name="actived" id="actived" value={this.state.actived}
                                    onChange={this.handleChange} checked={this.state.actived} /> <span>{t('modal.employ_fields.active')}</span>
                            </Col>
                        </Grid>
                        <br/>
                        {this.state.typeField === 'string_enum' &&
                            <Grid fluid>
                                <fieldset>
                                    <legend style={{fontSize:'18px'}}>{t('modal.employ_fields.elements.title')}</legend>
                                    <Col xs={24} sm={8}>
                                        <ControlLabel>{t('modal.employ_fields.elements.value')}</ControlLabel>
                                        <input name="comboValue" id="comboValue" value={this.state.comboValue}
                                            onChange={this.handleChange} className="form-control form-control-sm" />
                                    </Col>
                                    <Col xs={24} sm={6} style={{marginTop:'4%'}}>
                                        <input type="checkbox" name="comboDefault" id="comboDefault" value={this.state.comboDefault}
                                            onChange={this.handleChange} checked={this.state.comboDefault} /> <span>{t('modal.employ_fields.elements.default')}</span>
                                    </Col>
                                    <Col xs={24} sm={2} style={{marginTop:'4%'}}>
                                        <IconButton icon={
                                            <Icon icon="plus" />
                                        } circle size="sm" color="blue"
                                        onClick={this.addOption}
                                        />
                                    </Col>
                                    <Col xs={24} style={{marginTop:8}}>
                                        <Table responsive hover>
                                            <thead>
                                                <tr>
                                                    <th>{t('modal.employ_fields.elements.title')}</th>
                                                    <th>{t('modal.employ_fields.default')}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                
                                                {this.state.options.map((o, i)=>(
                                                    <tr key={i}>
                                                        <td>{o.value}</td>
                                                        <td>
                                                            <input type="checkbox" checked={o.default} disabled/>
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </fieldset>
                            </Grid>
                        }    
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button appearance="primary" onClick={()=>this.props.addField()}>{t('button.add')}</Button>
                    <Button appearance="default" onClick={this.close}>{t('button.cancel')}</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default ModalFields;