import React, { Component } from 'react';
import {Panel, Grid, Col, Button, IconButton, Icon} from 'rsuite';
import { Table } from 'react-bootstrap';
import ModalSubscription from '../../ModalSubscription';
import { swalAction } from '../../../lib/functions';


class SubscriptionData extends Component{
    constructor(props){
        super(props);

        this.openModal = this.openModal.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.messageUpdate = this.messageUpdate.bind(this);
        this.updateAccount = this.updateAccount.bind(this);
    }

    componentDidMount(){
        this.props.onRef(this);
    }

    componentWillUnmount(){
        this.props.onRef(null);
    }      

    openModal(action){
        this.modal_subscription.handleShow(action);
    }    

    onConfirm(e, i, type){
        e.preventDefault();
        var method = this.props.methods[i];
        var text = '';

        if(type === 'change'){
            text = this.props.t('modal.alert.messages.confirm_default_method');
        }

        if(type === 'delete'){
            text = this.props.t('modal.alert.messages.delete_method');
        }

        swalAction({
            title           : this.props.t('modal.alert.titles.pay_method'),
            text            : text,                                
            icon            : 'warning',
            showConfirm     : true,
            showCancel      : true,
            textConfirm     : this.props.t('modal.alert.confirms.yes'),
            colorConfirm    : '#3085d6',
            colorCancel     : '#d33',
            textcancel      : this.props.t('modal.alert.confirms.no'),
            values          : '',
            fn              : ()=>{
                                return type === 'change' ?
                                    this.props.changeDefaultMethod(method.paymentMethodId)
                                : type === 'delete' ? 
                                    this.props.deleteMethod(method.paymentMethodId)
                                : null
                            }
        })
    }

    messageUpdate(){
        swalAction({
            title           : this.props.t('modal.alert.titles.update_account'),
            text            : this.props.t('modal.alert.messages.update_account'),                                
            icon            : 'warning',
            showConfirm     : true,
            showCancel      : true,
            textConfirm     : this.props.t('modal.alert.confirms.yes'),
            colorConfirm    : '#3085d6',
            colorCancel     : '#d33',
            textcancel      : this.props.t('modal.alert.confirms.no'),
            values          : '',
            fn              : ()=> this.updateAccount()
        });
    }

    async updateAccount(){
        var response = await this.props.fetchRequest({
            method: 'POST',
            api: 'payments',
            url: 'subscription/premium-update',
            requireToken: true,
            modal:this.props.modal_loader,
            message_modal:this.props.t('modal.spin.updating')
        });

        if(response !== undefined){
            sessionStorage.setItem('suscription', response.subscriptionType);
            window.location.reload();            
        }       
    }

    render(){
        const {t} = this.props;
        return (
            <Panel shaded>
                <i class="rs-panel-heading" style={{padding:"0px"}}> 
                    {t('screen.settings.account.subscription.title')}
                </i>
                <Grid fluid className="form-group">
                    <Col xs={4}>
                        <IconButton className="s-primary-btn" 
                            icon={<Icon icon="plus" />}
                            circle
                            size="sm"
                            onClick={()=>this.openModal('new_method')}
                        />
                    </Col>
                    <Col xs={8} style={{float:'right', textAlign:'right'}}>
                        <span>{t('screen.settings.account.subscription.subscription_type')}</span>
                        <Button size="sm" style={{marginLeft:8}} color={
                            this.props.suscription === 'free' ? 
                                "green"
                            : this.props.suscription === 'Basic Account' ? 
                                "blue"
                            : "orange"
                            }
                            //onClick={(e)=> this.props.suscription === 'Basic Account' ? this.messageUpdate() : e.preventDefault()}
                        >
                            {this.props.suscription === 'free' ? 
                                t('general.accounts.free')
                            : this.props.suscription === 'Basic Account' ? 
                                t('general.accounts.basic')
                            : t('general.accounts.premium')}
                        </Button>
                    </Col>
                </Grid>
                <Grid fluid>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>{t('screen.settings.account.subscription.columns.credit_card')}</th>
                                <th>{t('screen.settings.account.subscription.columns.name')}</th>
                                <th>{t('screen.settings.account.subscription.columns.ex_date')}</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.methods.map((m, i)=>
                                <tr key={i}>
                                    <td>
                                        {m.brand === 'visa' &&
                                            <Icon icon="cc-visa" style={{marginRight:8}} />
                                        }

                                        {m.brand === 'mastercard' &&
                                            <Icon icon="cc-mastercard" style={{marginRight:8}} />
                                        }

                                        {(m.brand === 'americanExpress'|| m.brand === 'amex') &&
                                            <Icon icon="cc-amex" style={{marginRight:8}} />
                                        }
                                        
                                        {m.brand.toUpperCase()+' '+m.last4Digits}
                                    </td>
                                    <td>{m.nameCustomerCard}</td>
                                    <td>{m.expMonth+'/'+m.expYear.toString().substr(-2)}</td>
                                    <td>
                                        <input type="radio" checked={m.defaultPaymentMethod} 
                                            onChange={(e)=>this.onConfirm(e, i, 'change')}
                                        /> <span>{t('screen.settings.account.subscription.columns.default_card')}</span>
                                    </td>
                                    <td>
                                        <IconButton 
                                            icon={<Icon icon="trash" style={{color:'red', fontSize:18}} />}
                                            size="sm"
                                            appearance="subtle"
                                            onClick={(e)=>this.onConfirm(e, i, 'delete')}
                                        />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Grid>

                <ModalSubscription {...this.props} onRef={ref => this.modal_subscription = ref} />
            </Panel>
        )
    }
 
}

export default SubscriptionData;