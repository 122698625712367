import React, {Component} from 'react';
import {
	Modal, Button, ControlLabel, Grid, Col, Alert
} from 'rsuite';

class ModalDocumentType extends Component{
	constructor(props){
		super(props);

		this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);

		this.state = {
            show: false,
            name:""
		}
	}

	componentDidMount(){
		this.props.onRef(this);
	}

	componentDidUmount(){
		this.props.onRef(null);
	}

    handleChange(e){
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    }

	handleShow(){
		this.setState({show:true, name:""});
	}

	handleClose(){
        this.setState({show:false});
    }

    async save(){
        if(this.state.name !== ""){
            var obj ={
                name: this.state.name
            }
            var req = {
                api:'accounts',
                method:'POST',
                url:'document-types',
                obj:obj,
                modal:this.props.modal_loader,
                message_modal:this.props.t('modal.spin.saving'),
                requireToken:true
            }
    
            var response = await this.props.fetchRequest(req);
            console.log(response);
            if(response !== undefined){
                await this.props.getDocumentTypes();
                this.handleClose();
            }
        }else{
            Alert.error("Debe ingresar el tipo de documento");
        }
    }

	render(){
        const {t} = this.props;
		return (
			<Modal show={this.state.show} onHide={this.handleClose} backdrop="true">
                <Modal.Title>Nuevo tipo de documento</Modal.Title>
				<Modal.Body style={{maxHeight:'350px !important'}}>
					<div className="form-group">
                        <Grid fluid>
                            <Col xs={24} sm={16}>
                                <ControlLabel>{t('screen.settings.employee.documents.type')}</ControlLabel>
                                <input type="text" className="form-control form-control-sm" name="name"
                                    id="name" value={this.state.name} 
                                    onChange={e=>this.handleChange(e)}>
                                </input>
                            </Col>
                        </Grid>
					</div>
				</Modal.Body>
                <Modal.Footer>
                    <div style={{marginTop:10}}>
                        <Button color="blue" onClick={this.save}>{this.props.t('button.save')}</Button>
                        <Button style={{marginLeft:10}} onClick={this.handleClose}>{this.props.t('button.cancel')}</Button>
                    </div>                    
                </Modal.Footer>
			</Modal>
		);
	}
}

export default ModalDocumentType;