import React, {Component} from 'react';

import {Button, Modal, Col, FlexboxGrid } from 'rsuite';
import { withTranslation } from 'react-i18next';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { Image } from 'react-bootstrap';
import CheckoutForm from './subscription/CheckoutForm';
import ModalLoader from "./ModalLoader";

class ModalSubscription extends Component{
	constructor(props){
		super(props);

		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.confirmSubscription = this.confirmSubscription.bind(this);

		this.state = {
			show: false,
			action:""
		}
    }

	componentDidMount(){
		this.props.onRef(this);
		
		window.addEventListener('resize', () => {
        	if (window.innerWidth < 450 && this.state.elementFontSize !== '14px') {
          		this.setState({elementFontSize: '14px'});
        	} else if (
          		window.innerWidth >= 450 &&
          		this.state.elementFontSize !== '18px'
        	) {
          		this.setState({elementFontSize: '18px'});
        	}
		});

	}

	componentDidUmount(){
		this.props.onRef(null);
	}

	handleShow(action = 'general'){
		this.setState({show:true, action: action});
	}

	handleClose(){
		this.setState({show:false});
	}
    
    handleChange(e, value){
        if (value) {
            this.setState({
                [value]:{
                    ...this.state[value], 
                    [e.target.name]: e.target.value
                }
            });
        } else {
            this.setState({[e.target.name]: e.target.value});
        }
    }

    async confirmSubscription(){
		this.modal_loader.handleShow(this.props.t('modal.spin.validating'));
		var cardData = await this.checkout_form.getCardData();

		this.modal_loader.handleClose();
		if(cardData === undefined){
			return;
		}

		var url = 'subscription';
		if(this.state.action === 'new_method'){
			url = 'payment-methods';
		}

		let req = {
			api:'payments',
			method:'POST',
			url: url,
			requireToken:true,
			obj:cardData,
			modal:this.props.modal_loader,
			message_modal:this.props.t('modal.spin.generating')
		};
		let response =  await this.props.fetchRequest(req);
		if(response !== null&& response !== undefined){
			sessionStorage.setItem("suscription", response.subscriptionType);
			window.location.reload();
		}
    }

	render(){
		const {t} = this.props;
		//Testing 
		//const stripePromise = loadStripe("pk_test_51Hhf2tGibt8wqoLZIg8mEj04dQ6vlP7Slgre2czPw9csDDuYuurjFT4dPDl4UmVMdmptyAM2grCxYVBLwbAA2Bdg00EVzcCFOG");
		
		//Production
		const stripePromise = loadStripe("pk_live_51Hhf2tGibt8wqoLZxiCjGzYFLskDegJDsU52tVpHjnnXj8qh7voIgj6Hg5AUEo37DazVyJIQk9kXO9wBd986ePua00VhPp8Eet");
		return (
			<Modal backdrop="static" show={this.state.show} onHide={this.handleClose} size="xs" > 
				<Modal.Header className="s-modal-head">	
				</Modal.Header>
				<Modal.Body>
                    <FlexboxGrid justify="center">
						<Col xs={24} >
							<Elements stripe={stripePromise}>
    							<CheckoutForm {...this.props} translate={t} onRef={ref => this.checkout_form = ref} plans={false}/>
  							</Elements>			
						</Col>
						<Col xs={24}>
							<Image src="../../../images/powered-by-stripe-badge.png"  fluid />
						</Col>
                    </FlexboxGrid>
				</Modal.Body>
				<Modal.Footer>
					<FlexboxGrid justify="center">
						<Col xs={24} style={{textAlign:'center', width:"100%"}}>
							<Button appearance="primary" size="md" onClick={this.confirmSubscription}>
								{t('modal.subscription.message')}
							</Button>
							
						</Col>
					</FlexboxGrid>
				</Modal.Footer>

				<ModalLoader onRef={ref => this.modal_loader = ref}/>
			</Modal>
		);
	}

}

export default withTranslation('translations') (ModalSubscription);