import React, {Component} from 'react';
import {Panel, Alert, Col, FormGroup, ControlLabel, Icon, IconButton, Row, Button} from 'rsuite';
import {Table} from 'react-bootstrap';
import SaveBtn from './SaveBtn';
import SavedMark from './SavedMark';
import { isValidForm } from '../../../lib/functions';
import ModalPaymentSchemes from './ModalPaymentSchemes';

class Payment extends Component{
    constructor(props){
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.addPaymentScheme = this.addPaymentScheme.bind(this);
        this.deletePayment = this.deletePayment.bind(this);
        this.modalPaymentSchemes = this.modalPaymentSchemes.bind(this);
        this.disabledButton = this.disabledButton.bind(this);

        this.state = {
            payment:{
				type:"",
				cutDay1:"",
				cutDay2:"",
				cutDay3:"",
				cutDay4:""
			},
            payments:[],
            is_saved:false,
            active_save:false,
            disabled:true
        }
    }

    componentDidMount(){
        this.props.onRef(this);
    }

    handleChange(e, variable){
        this.setState({
            [variable]:{
                ...this.state[variable], 
                [e.target.name]: e.target.value
            }
        });
    }

    addPaymentScheme(){
        console.log("Add");
        var payments = this.state.payments;
		var index = payments.findIndex(obj => obj.type === this.state.payment.type);

		if(index === -1){
            if(isValidForm('form.payment')){
                payments.push(this.state.payment);

                this.setState({
                    payment  : {type:"", cutDay1:"", cutDay2:"", cutDay3:"",cutDay4:""},
                    payments : payments,
                    active_save: true
                });
            }else{
                Alert.error(this.props.t('error_alert.incomplete_fields'));
            }
   
		}else{
			Alert.error(this.props.t('error_alert.scheme_registered'));
		}
    }

    deletePayment(e, i){
        console.log("Delete");
        var payments = this.state.payments;

        payments.splice(i, 1);

        this.setState({payments: payments, active_save:true});
    }

    modalPaymentSchemes(){
        if(this.state.payments.length === 0){
            Alert.error("No se ha registrado ningún esquema de pago");
            return;
        }
		this.modal_payment_schemes.handleShow(this.state.payments[0].type);
	}

    disabledButton(){
        this.setState({
            disabled:!this.state.disabled, 
            payments: this.state.payments === null?[]:this.state.payments
        });
    }

    render(){
        return (
            <Panel shaded>
                <i class="rs-panel-heading" style={{padding:"0px"}}> 
                    {this.props.t('screen.settings.account.payment_scheme.title')}
                </i>
                <i class="rs-icon rs-icon-help-o rs-panel-heading" style={{paddingLeft:"10px"}} title={this.props.t('screen.settings.account.tooltips.payment_scheme')}> 
                </i>
                {this.state.is_saved &&
                    <SavedMark />
                }
                <form className="payment">
                    <Row className="form-group">
                        <Col xs={24} sm={2} md={1} style={{marginTop:'1.5%'}}>
                            {this.state.disabled ?
                                <IconButton 
                                    icon={<Icon icon='pencil' />}
                                    color="blue"
                                    size="sm"
                                    onClick={this.disabledButton}
                                />
                            :
                                <SaveBtn t={this.props.t} active={this.state.active_save} action={this.props.dataUpdate} />
                            }
                        </Col>
                        <Col xs={24} sm={8} md={6} lg={4} style={{marginTop:'15px'}}>
                            <FormGroup>
                                <select className="form-control form-control-sm is-number" name="type" 
                                    id="type" value={this.state.payment.type} 
                                    disabled={this.state.disabled || this.state.payments.length > 0}
                                    onChange={(e)=>this.handleChange(e, 'payment')}>
                                    <option value="">{this.props.t('general.select')}</option>
                                    <option value="weekly">{this.props.t('screen.wizard.operation.weekly')}</option>
                                    <option value="biweekly">{this.props.t('screen.wizard.operation.biweekly')}</option>
                                    <option value="monthly">{this.props.t('screen.wizard.operation.monthly')}</option>
                                </select>		
                            </FormGroup>
                        </Col>
                        <Col xs={4}>
                            <Button disabled={this.state.disabled || this.state.payments.length === 0} className="s-btn-primary"
                                onClick={()=>this.modalPaymentSchemes()} style={{marginLeft:10, marginTop:10}}>
                                Días de Pago
                            </Button>
                        </Col>
                        {this.state.payment.type !== "" && 
                            <Col xs={24} sm={4} md={4}>
                                <ControlLabel>{this.props.t('screen.settings.account.payment_scheme.cut_day')} 1</ControlLabel>
                                <input type="number" className="form-control form-control-sm is-number" name="cutDay1" id="cutDay1"
                                    value={this.state.payment.cutDay1} onChange={(e)=>this.handleChange(e, 'payment')} required />
                            </Col>
                        }
                        {(this.state.payment.type === 'weekly' || this.state.payment.type === 'biweekly') &&
                            <Col xs={24} sm={4} md={4}>
                                <ControlLabel>{this.props.t('screen.settings.account.payment_scheme.cut_day')} 2</ControlLabel>
                                <input type="number" className="form-control form-control-sm is-number" name="cutDay2" id="cutDay2"
                                    value={this.state.payment.cutDay2} onChange={(e)=>this.handleChange(e, 'payment')} required />
                            </Col>
                        }
                        {this.state.payment.type === 'weekly' &&
                            <Col xs={24} sm={4} md={4}>
                                <ControlLabel>{this.props.t('screen.settings.account.payment_scheme.cut_day')} 3</ControlLabel>
                                <input type="number" className="form-control form-control-sm is-number" name="cutDay3" id="cutDay3"
                                    value={this.state.payment.cutDay3} onChange={(e)=>this.handleChange(e, 'payment')} required />
                            </Col>
                        }
                        {this.state.payment.type === 'weekly' &&
                            <Col xs={24} sm={4} md={4}>
                                <ControlLabel>{this.props.t('screen.settings.account.payment_scheme.cut_day')} 4</ControlLabel>
                                <input type="number" className="form-control form-control-sm is-number" name="cutDay4" id="cutDay4"
                                    value={this.state.payment.cutDay4} onChange={(e)=>this.handleChange(e, 'payment')} required />
                            </Col>
                        }
                        {this.state.payment.type !== "" &&
                            <Col xs={24} sm={4} md={4}>
                                <IconButton icon={<Icon icon="plus" />} circle size="md" color="blue"
                                    style={{position:'relative', marginTop:'13%'}} 
                                    onClick={()=>this.addPaymentScheme()}
                                />
                            </Col>
                        }
                    </Row>
                </form>
                <div>
                    <Table responsive hover>
                        <thead>
                            <tr>
                                <th>{this.props.t('screen.settings.account.payment_scheme.scheme')}</th>
                                <th>{this.props.t('screen.settings.account.payment_scheme.cut_day')} 1</th>
                                <th hidden={
                                    this.state.payments !== null? this.state.payments.findIndex(obj => obj.type === 'monthly') !== -1:false
                                }>{this.props.t('screen.settings.account.payment_scheme.cut_day')} 2</th>
                                <th hidden={
                                    this.state.payments !== null? this.state.payments.findIndex(obj => obj.type === 'biweekly' || obj.type === 'monthly') !== -1:false
                                }>{this.props.t('screen.settings.account.payment_scheme.cut_day')} 3</th>
                                <th hidden={
                                    this.state.payments !== null? this.state.payments.findIndex(obj => obj.type === 'biweekly' || obj.type === 'monthly') !== -1:false
                                }>{this.props.t('screen.settings.account.payment_scheme.cut_day')} 4</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                            this.state.payments !== null? 
                                this.state.payments.map((p, i)=>(
                                    <tr key={i}>
                                        <td>
                                            {p.type === 'weekly' ? this.props.t('screen.wizard.operation.weekly') : 
                                            p.type === 'biweekly' ? this.props.t('screen.wizard.operation.biweekly') : 
                                            p.type === 'monthly' ? this.props.t('screen.wizard.operation.monthly') : ''}
                                        </td>
                                        <td>{p.cutDay1}</td>
                                        <td>{p.cutDay2}</td>
                                        <td>{p.cutDay3}</td>
                                        <td>{p.cutDay4}</td>
                                        <td>
                                            <IconButton 
                                                color={this.state.disabled ? "" : "cyan"}
                                                icon={<Icon icon="trash" />}
                                                title={this.props.t('button.delete')}
                                                onClick={(e)=>this.state.disabled ? e.preventDefault() : this.deletePayment(e, i)}
                                            />
                                        </td>
                                    </tr>
                                ))
                            :null}
                        </tbody>
                    </Table>
                </div>
                <ModalPaymentSchemes {...this.props} onRef={ref => this.modal_payment_schemes = ref} />
            </Panel>
        )
    }
    
}

export default Payment;