import React, {Component} from 'react';

import {Button, Modal, Col, FlexboxGrid, FormGroup, ControlLabel, Alert } from 'rsuite';
import { withTranslation } from 'react-i18next';
import validator from 'validator';

class ModalAccountUser extends Component{
	constructor(props){
		super(props);

		this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);

		this.state = {
            show: false,
            email:"",
            error_email:""
		}
    }

	componentDidMount(){
		this.props.onRef(this);
	}

	componentDidUmount(){
		this.props.onRef(null);
	}

	async handleShow(roleId){
        await this.setState({show:true});
	}

	handleClose(){
		this.setState({
            show:false, 
            email:""
        });
    }

    async save(){
        if(this.state.email !== ""){	
            //Validar correo
            if(this.state.error_email !== ''){
                Alert.error(this.props.t('error_alert.incomplete_fields'));
                return;
            }

            var accountId  = atob(sessionStorage.getItem(btoa('account_id')));
            accountId = accountId.substring(1, accountId.length - 1);
            

            var obj = {
                email: this.state.email
            };
            var req = {
                api:'auth',
                method:'POST',
                url:'users/add-account-member',
                obj:obj,
                modal:this.props.modal_loader,
                message_modal:this.props.t('modal.spin.saving'),
                requireToken:true
            }
            
            var response = await this.props.fetchRequest(req);
            if(response !== null){
                this.props.getAccountUsers();
            }
            this.handleClose();
        }else{
            Alert.error(this.props.t('error_alert.incomplete_fields'));
        }
		
    }

    handleChange(e){
		let	value = e.target.value;
        let name = e.target !== undefined ? e.target.name : e;
        
        this.setState({[name]: value});	

        if(!validator.isEmail(e.target.value)){
            this.setState({error_email:this.props.t('error_alert.invalid_email')});
        }else{
            this.setState({error_email:''});
        }
    }

	render(){
		const {t} = this.props;
		return (
			<Modal backdrop={false} show={this.state.show} size="md">
				<Modal.Header>	
					<Modal.Title>{this.props.t('screen.settings.account.users.modal_title')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
                    <FlexboxGrid >
                        <Col xs={12} >
                            <FormGroup>
                                <ControlLabel>{this.props.t('screen.settings.account.users.email')}</ControlLabel> <span style={{color:'red'}}>*</span>
                                <span style={{color:'red', fontSize:12, marginLeft:10}}>{this.state.error_email}</span>
                                <input type="text" className="form-control form-control-sm"
									value={this.state.email} required
									name="email"
									onChange={(e)=>this.handleChange(e)} 
								/>
                            </FormGroup>	
						</Col>
                    </FlexboxGrid>
				</Modal.Body>
				<Modal.Footer>
                    <Button appearance="primary" onClick={this.save}>{t('button.add')}</Button>
                    <Button appearance="default" onClick={this.handleClose}>{t('button.cancel')}</Button>
				</Modal.Footer>
			</Modal>
		);
	}

}

export default withTranslation('translations') (ModalAccountUser);