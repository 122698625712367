import React, {Component} from 'react';
import moment from 'moment';
import { Panel, Grid, IconButton, Icon, Alert, Col} from 'rsuite';
import { Tabs, Tab } from 'react-bootstrap';
import {swalAction} from '../../../lib/functions';

import SavedMark from './SavedMark';
import ScheduleForm from '../general/ScheduleForm';
import SaveBtn from './SaveBtn';
import { isNumber } from 'react-simple-timefield';

class CheckerRules extends Component{
    constructor(props){
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.setKey = this.setKey.bind(this);
        this.newRule = this.newRule.bind(this);
        this.updateRules = this.updateRules.bind(this);
        this.deleteRule = this.deleteRule.bind(this);
        this.changeRules = this.changeRules.bind(this);
        this.openMessage = this.openMessage.bind(this);

        this.state = {
            active_save:false,
            is_saved: false,
            rule:"",
            schedules:[],
            show_form:false,
            index:-1,
            suscription: sessionStorage.getItem('suscription'),
            disabled:true,
        }
    }

    componentDidMount(){
        this.props.onRef(this);
        
    }

    handleChange(e){
        if(e.target.value === ""){
            this.setState({[e.target.name]: e.target.value});
        }

        if(e.target.name === 'checkOffsetHours' || e.target.name === 'breakOutOffsetMinutes'){
            if(parseInt(e.target.value) > 0){
                this.setState({[e.target.name]: e.target.value});
            }
        }        
    }

    setKey(e){
        var rule = e;
        var index = this.state.schedules.findIndex(obj => obj.label === rule);
        
        this.setState({rule, index});

        this.schedule_rules.setState({schedule: this.state.schedules[index]})
    }

    newRule(){
        var schedules = this.state.schedules;

        var error = -1;
        var error_title = '';

        schedules.map((s)=>{
        	error = s.days.findIndex(obj => 
        		obj.error_start !== "" || obj.error_break !== "" || obj.error_return !== "" || obj.error_close !== ""
            );

            if(s.name === ''){
                error = 0;
                error_title = this.props.t('error_alert.title_checker_rules');
            }
            
            return error;
        });

        
        if(error === -1){
        
            var suscription = sessionStorage.getItem('suscription');

            if(suscription === 'free' && schedules.length >= 1){
                this.verifySuscription();
            }else{
                var index = schedules.length + 1;

                var found = '';
                
                if(this.schedule_rules !== undefined){
                    if(schedules.length > 0){
                        found = schedules[schedules.length - 1].label.substr(
                            this.schedule_rules.state.schedule.label.length - 1
                        );    
                    }                    
                }
                            
                schedules.push({
                    name:"Regla "+(isNumber(found) ? parseInt(found) + 1 : index),
                    label:"regla"+(isNumber(found) ? parseInt(found) + 1 : index),
                    useBreakTime:false,
                    activeWeekend:false,                
                    nightShift:false,
                    type:'traditional',
                    days:[
                        {name:'monday', startTime:"", error_start:"", closingTime:"", error_close:"", breakTime:"", error_break:"",  breakReturnTime:"", error_return:"", check:true},
                        {name:'tuesday', startTime:"", error_start:"", closingTime:"", error_close:"", breakTime:"", error_break:"",  breakReturnTime:"", error_return:"", check:false},
                        {name:'wednesday',  startTime:"", error_start:"", closingTime:"", error_close:"", breakTime:"", error_break:"",  breakReturnTime:"", error_return:"", check:false},
                        {name:'thursday',  startTime:"", error_start:"", closingTime:"", error_close:"", breakTime:"", error_break:"",  breakReturnTime:"", error_return:"", check:false},
                        {name:'friday',  startTime:"", error_start:"", closingTime:"", error_close:"", breakTime:"", error_break:"",  breakReturnTime:"", error_return:"", check:false},
                        {name:'saturday',  startTime:"", error_start:"", closingTime:"", error_close:"", breakTime:"", error_break:"",  breakReturnTime:"", error_return:"", check:false},
                        {name:'sunday',  startTime:"", error_start:"", closingTime:"", error_close:"", breakTime:"", error_break:"",  breakReturnTime:"", error_return:"", check:false},
                    ]
                });
                this.setState({
                    show_form:true, 
                    schedules: schedules, 
                    rule:"regla"+index, 
                    active_save: true, 
                    is_saved:false,
                    index: schedules.length - 1
                });
                
                setTimeout(()=>{
                    this.schedule_rules.setState({schedule: schedules[schedules.length - 1]})
                }, 500);
            }

        }else{
            if(error_title !== ''){
                Alert.error(error_title);
            }else{
                Alert.error(this.props.t('error_alert.validation_checker_rule'));
            }
        	
        }

	        
    }

    updateRules(schedule){
        var schedules = this.state.schedules;
        var label = schedule.label;

        var index = schedules.findIndex(obj => 
            obj.label === label    
        );

        if(index !== -1){
            schedules[index] = schedule;

            this.setState({schedules: schedules, is_saved:false, active_save:true});
        }
    }

    changeRules(e, i){
        var schedules = this.state.schedules;
        var index = schedules.findIndex(obj => obj.label === this.state.rule);
		var schedule = schedules[index];

		schedule.days[i][e.target.name] = e.target.type ==='checkbox' ? e.target.checked : e.target.value;

		if(i === 0 && e.target.type !== 'checkbox'){
            
			schedule.days.map((d, j)=>{
				if(j !== i){
                    d[e.target.name] = e.target.value;
                }              

                return d;
            });
		}

		if(e.target.type !== 'checkbox'){
			this.validHours(schedule)
		}

        schedules[index] = schedule;
		this.setState({schedules: schedules, is_saved:false, active_save: true});
    }

    deleteRule(i){
        var schedules = this.state.schedules;
        schedules.splice(i, 1);
        
        this.setState({
            schedules: schedules,
            show_form: schedules.length > 0 ? true : false,
            rule: schedules.length > 0 ? schedules[0].label : "",
            index: 0,
            is_saved: false,
            active_save: true
        });

    }

    openMessage(e, i){
        var obj = {
            title           : this.props.t('modal.alert.titles.alert'),
            text            : this.props.t('modal.alert.messages.delete_rule'),
            icon            : 'warning',
            showConfirm     : true,
            showCancel      : true,
            textConfirm     : this.props.t('modal.alert.confirms.delete'),
            colorConfirm    : '#3085d6',
            colorCancel     : '#d33',
            textcancel      : this.props.t('modal.alert.confirms.cancel'),
            values          : i,
            fn              : this.deleteRule
        }

        swalAction(obj);
    }

    validHours(schedule){
        schedule.days.map((d, j)=>{
            d.error_start = '';
            d.error_break = '';
            d.error_return = '';
            d.error_close = '';

            if(schedule.nightShift){
                if(this.compareHours( d.closingTime, d.startTime)){
                    d.error_start = 'error';
                }

                if(schedule.useBreakTime){
                    if(this.compareHours(d.breakTime, d.startTime)){
                        d.error_start = 'error';
                    }

                    if(this.compareHours(d.breakTime, d.breakReturnTime)){
                        d.error_break = 'error';
                    }
                    if(this.compareHours(d.breakReturnTime, d.startTime)){
                        d.error_start = 'error';
                    }

                    
                    if(this.compareHours(d.breakReturnTime, d.closingTime)){
                        d.error_return = 'error'
                    }

                    if(this.compareHours(d.breakTime, d.closingTime)){                		
                        d.error_break = 'error';
                    }
                }
            }else{
                if(this.compareHours(d.startTime, d.closingTime)){
                    d.error_start = 'error';
                }

                if(schedule.useBreakTime){
                    if(this.compareHours(d.startTime, d.breakTime)){
                        d.error_start = 'error';
                    }

                    if(this.compareHours(d.breakTime, d.breakReturnTime)){
                        d.error_break = 'error';
                    }
                    if(this.compareHours(d.startTime, d.breakReturnTime)){
                        d.error_start = 'error';
                    }

                    
                    if(this.compareHours(d.breakReturnTime, d.closingTime)){
                        d.error_return = 'error'
                    }

                    if(this.compareHours(d.breakTime, d.closingTime)){                		
                        d.error_break = 'error';
                    }
                }
            }


            return d;
        });
    }

    compareHours(i, f){
        var inicial = moment(i, 'HH:mm');
        var final = moment(f, 'HH:mm');
        var valid = false;

        if(final.isBefore(inicial)){
            valid = true;
        }

        return valid;
    }

    verifySuscription(){
        var obj = {
            title           : this.props.t('modal.alert.titles.suscription'),
            text            : this.props.t('modal.alert.messages.update_suscription'),
            icon            : 'warning',
            showConfirm     : true,
            showCancel      : true,
            textConfirm     : this.props.t('modal.alert.confirms.go_plans'),
            colorConfirm    : '#3085d6',
            colorCancel     : '#d33',
            textcancel      : this.props.t('modal.alert.confirms.cancel'),
            values          : '',
            fn              : ()=>{this.props.history.push('/settings/account')}
        }
        
        swalAction(obj);  
    }

    render(){
        const {t} = this.props;
        return (
            <Panel shaded>
                <i class="rs-panel-heading" style={{padding:"0px"}}> 
                    {t('screen.settings.account.checker_rules.title')}
                </i>
                <i class="rs-icon rs-icon-help-o rs-panel-heading" style={{paddingLeft:"10px"}} title={this.props.t('screen.settings.account.tooltips.checker_rules')}> 
                </i>
                {this.state.is_saved &&
                    <SavedMark />
                }

                <Grid fluid>
                    <Col xs={24} sm={2} md={1}>
                        {this.state.disabled ?
                            <IconButton 
                                icon={<Icon icon='pencil' />}
                                color="blue"
                                size="sm"
                                onClick={()=>this.setState({disabled: !this.state.disabled})}
                            />
                        :
                            <SaveBtn t={this.props.t} active={this.state.active_save} action={this.props.dataUpdate} />
                        }

                        <IconButton 
                            icon={<Icon icon="plus" />}
                            className={this.state.disabled ? '' 
                                : this.state.suscription === 'free' && this.state.schedules.length > 0 ? '' 
                                : "s-btn-primary"}
                            onClick={(e)=>this.state.disabled ? e.preventDefault() : this.newRule()}
                            circle
                            size="sm"
                            style={{marginTop:8}}
                        />
                    </Col>

                    <Col xs={24} sm={22} md={21}>
                        {this.state.show_form &&
                            <Tabs
                                id="checker-rules-form"
                                activeKey={this.state.rule}
                                transition={false}
                                onSelect={(k)=> this.setKey(k)}
                            >
                                {this.state.schedules.map((s, i)=>
                                    <Tab
                                        key={i}
                                        eventKey={s.label}
                                        title={
                                            <div>
                                                <span>{s.name}</span>
                                                <IconButton 
                                                    appearance="subtle"
                                                    icon={<Icon icon="close" size="3x" />}
                                                    size="md"
                                                    title="Cerrar"
                                                    onClick={(e)=>this.state.disabled ? e.preventDefault() : this.openMessage(e, i)}
                                                    style={{marginLeft:8, fontSize:10}}
                                                />
                                            </div>
                                        }
                                    >
                                        <ScheduleForm 
                                            {...this.props}
                                            updateRules={this.updateRules}
                                            changeRules={this.changeRules}
                                            schedules={this.state.schedules}
                                            handleChange={this.handleChange}
                                            edit_schedule={this.state.disabled}
                                            details={false}
                                            wizard={false}
                                            schedule={this.state.schedules[this.state.index]}
                                            onRef={ref => this.schedule_rules = ref}
                                        />
                                    </Tab>
                                )}
                            </Tabs>
                        }
                    </Col>
                    
                </Grid>
                
            </Panel>
        )
    }
}

export default CheckerRules;