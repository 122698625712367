import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {FlexboxGrid, Col, Panel, Grid, Icon} from 'rsuite';
import {Image } from 'react-bootstrap';


class FreePlan extends Component{
	constructor(props){
		super(props);

		this.handleChange = this.handleChange.bind(this);

		this.state = {
		}
	}

	componentDidMount(){
	}

	handleChange(e){
	}

	render(){
		const {t} = this.props;
		

		return (
            <Panel className="panel-general free" bordered shaded={this.props.free} style={{height: 640, cursor:'pointer'}} onClick={()=>this.props.changePlan('free')}>
				<div style={{textAlign:'center'}}>
					<Image className="image-plan" src="images/plans/free.svg" />
					
					<div className="title-plan">
						<span>{t('screen.plans.free')}</span>					
					</div>
				</div>
				<FlexboxGrid justify="center">
					<Col xs={24}>
						<Grid fluid>
							<Col xs={24} style={{textAlign:'center'}}>
								<span style={{fontWeight:'bold', display:'block', fontSize:'2.8em'}}>$0</span>
								<span style={{color:"transparent", fontSize:'1em'}}>0</span>
							</Col>
							
							<Col xs={24}>
								<Icon icon="check" style={{color:'green', marginRight:8}} /> <span>{t('screen.plans.facial')}</span>
							</Col>
							<Col xs={24}>
								<Icon icon="check" style={{color:'green', marginRight:8}} /> <span>{t('screen.plans.location')}</span>
							</Col>
							<Col xs={24}>
								<Icon icon="check" style={{color:'green', marginRight:8}} /> <span>{t('screen.plans.unlimited_check')}</span>
							</Col>
							<Col xs={24}>
								<Icon icon="check" style={{color:'green', marginRight:8}} /> <span>5 {t('screen.plans.employees')}</span>
							</Col>
							<Col xs={24}>
								<Icon icon="check" style={{color:'green', marginRight:8}} /> <span>1 {t('screen.plans.address')}</span>
							</Col>
							<Col xs={24}>
								<Icon icon="check" style={{color:'green', marginRight:8}} /> <span>{t('screen.plans.standard_report')}</span>
							</Col>
						</Grid>
					</Col>
				</FlexboxGrid>
				<div className="footer-plan" style={{background:'#5bced5'}}></div>
				
            </Panel>
		)
	}
		  
}

export default withTranslation('translations')(FreePlan)