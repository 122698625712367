import React from 'react';
import { Grid, ControlLabel, Col } from 'rsuite';
import TimeField from 'react-simple-timefield';

const FlexibleForm = (props)=>{
    const {t} = props;
    return(
        <Grid fluid>
            <Grid fluid className="form-group" style={{marginTop:10}}>
                <Col xs={12} sm={6} md={4}>
                    <ControlLabel>{t('screen.settings.account.checker_rules.startTime')}</ControlLabel> <span style={{color:'red'}}>*</span>
                </Col>
                
                {props.schedule.useBreakTime &&
                    <Col xs={24} sm={4} md={4}>
                        <ControlLabel>{t('screen.settings.account.checker_rules.breakTime')}</ControlLabel> <span style={{color:'red'}}>*</span>
                    </Col>
                }
                {props.schedule.useBreakTime &&
                    <Col xs={24} sm={4} md={4}>
                        <ControlLabel>{t('screen.settings.account.checker_rules.breakReturnTime')}</ControlLabel> <span style={{color:'red'}}>*</span>
                    </Col>
                }
                <Col xs={12} sm={6} md={4}>
                    <ControlLabel>{t('screen.settings.account.checker_rules.closingTime')}</ControlLabel> <span style={{color:'red'}}>*</span>
                </Col>
            </Grid>
            {props.schedule.days.map((d, i)=>(
                <div key={i}>
                    {i === 0 &&
                        <Grid className="form-group" fluid>
                            <Col xs={12} sm={6} md={4}>
                                <TimeField
                                    className={"form-control form-control-sm "+d.error_start} 
                                    value={d.startTime}
                                    onChange={(e)=>props.changeRules(e, i)} 
                                    style={{width:'100%'}}
                                    name="startTime"
                                    disabled={props.edit_schedule ? props.schedule.days.length > 0 ? !d.check : false : true} 
                                />
                            </Col>
                            {props.schedule.useBreakTime &&
                                <Col xs={4}>
                                    <TimeField 
                                        className={"form-control form-control-sm "+d.error_break} 
                                        value={d.breakTime} 
                                        onChange={(e)=>props.changeRules(e, i)} 
                                        style={{width:'100%'}}
                                        name="breakTime"
                                        disabled={props.edit_schedule ? props.schedule.days.length > 0 ? !d.check : false : true} 
                                    />
                                </Col>
                            }
                            {props.schedule.useBreakTime &&
                                <Col xs={4}>
                                    <TimeField 
                                        className={"form-control form-control-sm "+d.error_return} 
                                        value={d.breakReturnTime} 
                                        onChange={(e)=>props.changeRules(e, i)} 
                                        style={{width:'100%'}}
                                        name="breakReturnTime"
                                        disabled={props.edit_schedule ? props.schedule.days.length > 0 ? !d.check : false : true} 
                                    />
                                </Col>
                            }		
                            <Col xs={12} sm={6} md={4}>
                                <TimeField 
                                    className={"form-control form-control-sm "+d.error_close} 
                                    value={d.closingTime} 
                                    onChange={(e)=>props.changeRules(e, i)} 
                                    style={{width:'100%'}}
                                    name="closingTime"
                                    disabled={props.edit_schedule ? props.schedule.days.length > 0 ? !d.check : false : true} 
                                />
                            </Col>				
                        </Grid>
                    }
                </div>
                
            ))}
        </Grid>
    )
}

export default FlexibleForm;