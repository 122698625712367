import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {
	FlexboxGrid, Col, InputGroup, Icon, Button, Input, Alert
} from 'rsuite';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { swalAction } from '../lib/functions';
import ModalChangePassword from './ModalChangePassword';

class Login extends Component{
	constructor(props){
		super(props);

		this.handleChange = this.handleChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
        this.initSession = this.initSession.bind(this);

		this.state = {
			email:"",
			password:"",
		}

	}

	parseJwt(token) {
        if (!token) { return; }
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }

	handleChange(val, e){
		this.setState({[e.target.name]: e.target.value});

		if(e.target.value !== ""){
			var control = document.getElementById(e.target.name);
			if(control !== null){
				if(control.required && control.className.includes('error')){
		        	control.classList.remove('error');
		    	}
			}
		}
	}

	async onSubmit(){
		if(this.props.isValidForm('form')){
			let obj = {
				email: this.state.email,
				password: this.state.password
			}

			let req = {
	  			api:'auth',
	  			method:'POST',
	  			url:'auth/login',
	  			obj:obj,
	  			modal:this.props.modal_loader,
	  			message_modal:this.props.t('modal.spin.start')
	  		}
			let response = await this.props.fetchRequest(req);
			
			if(response !== undefined){
                if(response.action === "CONTINUE"){
                    await this.initSession(response);
                }else{
                    this.modal_change_password.handleShow();
                }
				
				
			}
		}else{
			Alert.error(this.props.t('error_alert.incomplete_fields'));
		}
	}

    async initSession(loginResponse){
        sessionStorage.setItem('token', loginResponse.token);
                    
        if(loginResponse.accountExist){
            var lang = await this.props.fetchRequest({
                api:'accounts',
                method:'GET',
                url:'accounts/language',
                requireToken:true
            })

            sessionStorage.setItem(btoa("lang"), btoa(lang.language));
        }
        
        sessionStorage.setItem('hasAccount', loginResponse.accountExist);

        sessionStorage.setItem(btoa("account_id"), btoa(JSON.stringify(loginResponse.accountId)));
        sessionStorage.setItem(btoa("lead_id"), btoa(loginResponse.leadId));
        sessionStorage.setItem('canceled', loginResponse.canceled);
        sessionStorage.setItem(btoa('sys-leadName'), btoa('nl-'+loginResponse.leadName))
        sessionStorage.setItem("userEmail",this.state.email);
        sessionStorage.setItem('suscription', loginResponse.suscriptionType);
        
        if(loginResponse.canceled){
            var obj = {
                title           : this.props.t('modal.alert.titles.continue'),
                text            : this.props.t('modal.alert.messages.canceled_account'),
                icon            : 'warning',
                showConfirm     : true,
                showCancel      : true,
                textConfirm     : this.props.t('modal.alert.confirms.continue'),
                colorConfirm    : '#3085d6',
                colorCancel     : '#d33',
                textcancel      : this.props.t('modal.alert.confirms.no'),
                values          : '',
                fn              : ()=>{this.props.history.push('/subscription-plans');}
            };
            swalAction(obj);
        }
        if(!loginResponse.accountConfigured){
            this.props.history.push('/wizard');
        }else{
            this.props.history.push('/');							
        }
    }

	render(){
		const {t} = this.props;

		return (
			<FlexboxGrid justify="center">
	            <Col xs={24} sm={16} md={12} lg={10} >
					<Image src="images/login/login2.png" style={{width:'100%'}}/>
	                <div className="login-form">
	                    <div>
	                        <h2>{t('screen.login.title')}</h2>               
	                    </div>
	                    <form>
	                        <div style={{padding:'15px'}}>
	                            <FlexboxGrid justify="center" className="form-group">    
	                                <Col xs={22} sm={20} md={14} lg={14}>
	                                    <InputGroup inside style={{overflow: 'unset'}}>
	                                        <Input 
	                                            placeholder={t('screen.login.email')}
	                                            size="sm"
	                                            name="email" 
	                                            id="email"
	                                            value={this.state.email} 
	                                            onChange={this.handleChange}
	                                            required
	                                        />
	                                        <InputGroup.Addon>
	                                            <Icon icon="envelope-o" />
	                                        </InputGroup.Addon>
	                                    </InputGroup>
	                                </Col>
	                            </FlexboxGrid>
	                            <FlexboxGrid justify="center" className="form-group">
	                                <Col xs={22} sm={20} md={14} lg={14}>
	                                    <InputGroup inside style={{overflow: 'unset'}}>
	                                        <Input 
	                                            placeholder={t('screen.login.password')}
	                                            size="sm"
	                                            name="password" 
	                                            id="password"
	                                            type="password"
	                                            value={this.state.password} 
	                                            onChange={this.handleChange}
												required
												onPressEnter={this.onSubmit}
	                                        />
	                                        <InputGroup.Addon>
	                                            <Icon icon="lock" />
	                                        </InputGroup.Addon>
	                                    </InputGroup>
	                                </Col>
	                            </FlexboxGrid>
	                        </div>
	                    </form>
	                    <div style={{paddingBottom:'15px'}}>
							<FlexboxGrid justify="center">
	                            <Col xs={22} sm={20} md={14} lg={14}>
									<Col xs={24} sm={17} md={17} lg={18} className="form-group">
										<Link onClick={(e)=>{e.preventDefault(); return this.props.history.push('/recover')}}
											to=""
										>
											{t('screen.login.forgot_password')}
										</Link>
									</Col>
	                                <Col xs={24} sm={7} md={7} lg={6} style={{textAlign:'right'}}>
	                                    <Button style={{width:'100%'}} color="blue" size="sm" onClick={this.onSubmit}>{t('button.sign_in')}</Button>
	                                </Col>
	                            </Col>
	                        </FlexboxGrid>
	                    </div>
	                </div>
	            </Col>

                <ModalChangePassword {...this.props} onRef={ref => this.modal_change_password = ref} email={this.state.email} initSession={this.initSession}/>
	        </FlexboxGrid>
		)
	}
		
}

export default withTranslation('translations')(Login);