import React, {Component} from 'react';

import {
	Button, Modal, Col, FlexboxGrid
} from 'rsuite';

class ModalMessage extends Component{
	constructor(props){
		super(props);

		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.goToLogin = this.goToLogin.bind(this);

		this.state = {
			show: false,
			title:"",
			message:"",
			name:"",
			redirect:false,
		}
	}

	componentDidMount(){
		this.props.onRef(this);
	}

	componentDidUmount(){
		this.props.onRef(null);
	}

	handleShow(title, message, name = null, redirect = false){
		this.setState({
			show:true,
			title:title,
			message:message,
			name:name,
			redirect:redirect
		});
	}

	handleClose(){
		this.setState({show:false});
		//this.props.showForm();
	}

	goToLogin(){
		this.props.history.push('/login');
	}

	render(){
		return (
			<Modal backdrop={false} overflow={false} show={this.state.show} onHide={()=>this.props.showForm(this.state.redirect)} size="xs">
				<Modal.Header>	
					<Modal.Title>{this.state.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div style={{textAlign:'center', fontSize:'1.5em'}}>
						{this.state.name !== null && <p>{this.state.name}</p>}
						<p>
							{this.state.message}
						</p>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<FlexboxGrid justify="center">
						<Col xs={12} style={{textAlign:'center'}}>
							<Button 
								appearance="primary" 
								style={{width:'80%'}}
								onClick={()=>this.props.showForm(this.state.redirect)}
							>
								{this.props.btn}
							</Button>
						</Col>
					</FlexboxGrid>
				</Modal.Footer>
			</Modal>
		);
	}

}

export default ModalMessage;