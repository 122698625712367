import React, {Component} from 'react';
import {
	Panel, FlexboxGrid, Form, FormGroup, Icon, IconButton, Container, Col
} from 'rsuite';

import SaveBtn from './SaveBtn';
import SavedMark from './SavedMark';

class CheckerValidation extends Component{
    constructor(props){
        super(props);

        this.handleCheck = this.handleCheck.bind(this);

        this.state = {
            enforceFacialRecognition:false,
            enforceGeolocation:false,
            save_actived:false,
            is_saved:false,
            disabled:true
        }
    }

    componentDidMount(){
        this.props.onRef(this);
    }

    componentWillUnmount(){
        this.props.onRef(null);
    }

    handleCheck(e){
        this.setState({
            [e.target.name]: e.target.checked,
            active_save:true,
            is_saved:false
        });
    }

    render(){
        const {t} = this.props;
        return (
            <Panel shaded>
                <i class="rs-panel-heading" style={{padding:"0px"}}> 
                    {this.props.t('screen.settings.account.checker_validation')}
                </i>
                <i class="rs-icon rs-icon-help-o rs-panel-heading" style={{paddingLeft:"10px"}} title={this.props.t('screen.settings.account.tooltips.checker')}> 
                </i>
                {this.state.is_saved &&
                    <SavedMark />
                }
                <Container>
                    <FlexboxGrid>
                        <Col xs={24} sm={2} md={1}>
                            {this.state.disabled ?
                                <IconButton 
                                    icon={<Icon icon='pencil' />}
                                    color="blue"
                                    size="sm"
                                    onClick={()=>this.setState({disabled: !this.state.disabled})}
                                />
                            :
                                <SaveBtn t={this.props.t} active={this.state.active_save} action={this.props.dataUpdate} />
                            }
                        </Col>
                        <Col xs={24} md={12}>
                            <Form fluid className="checker-validation">
                                <FormGroup>
                                    <div style={{paddingLeft:'40px'}}>
                                        <input type="checkbox" className="form-check-input"
                                            name="enforceFacialRecognition" id="enforceFacialRecognition" 
                                            value={this.state.enforceFacialRecognition}
                                            onChange={this.handleCheck} 
                                            checked={this.state.enforceFacialRecognition}
                                            disabled={this.state.disabled}
                                        /> <span>{t('screen.wizard.operation.facial')}</span>
                                            
                                    </div>
                                    <div style={{paddingLeft:'40px'}}>
                                        <input type="checkbox" className="form-check-input" 
                                            name="enforceGeolocation" id="enforceGeolocation" 
                                            value={this.state.enforceGeolocation}
                                            onChange={this.handleCheck}
                                            checked={this.state.enforceGeolocation}
                                            disabled={this.state.disabled}
                                        /> <span>{t('screen.wizard.operation.geolocation')}</span> 
                                            
                                    </div>						
                                </FormGroup>
                                
                            </Form>
                        </Col>
                    </FlexboxGrid>
                </Container>
                
            </Panel>
        )
    }
}

export default CheckerValidation;