import React from 'react';
import {
	Container, FlexboxGrid, Form, FormGroup, ControlLabel, Col, SelectPicker
} from 'rsuite';

const General = (props)=>{
	return(
		<Container>
			<FlexboxGrid>
				<Col xs={24} sm={14} md={12} style={{padding:'5px'}}>
					<Form fluid>
						<FormGroup>
							<ControlLabel>
								{props.t('screen.wizard.general.company_name')} <span style={{color:'red'}}>*</span>
							</ControlLabel>
							<input size="sm" id="company_name" name="company_name" value={props.company_name}
								onChange={(e)=>props.handleChange(e)} required 
								disabled={props.disabled}
								className="form-control form-control-sm"/>
							
						</FormGroup>
						<FormGroup>
							<ControlLabel>
								{props.t('screen.wizard.general.industry')} <span style={{color:'red'}}>*</span>
							</ControlLabel>
							<select id="industry" name="industry" value={props.industry}
								className="form-control form-control-sm"
								disabled={props.disabled}
								onChange={(e)=>props.handleChange(e)} required>
								<option value="">{props.t('general.select')}</option>
								{props.industries.map((v, i)=>(
									<option key={i} value={v.key}>{v.value}</option>
								))}
							</select>
						</FormGroup>
						{props.industry === 'other_services' &&
							<FormGroup>
								<ControlLabel>
									{props.t('screen.wizard.general.other')} <span style={{color:'red'}}>*</span>
								</ControlLabel>
								<input size="sm" id="industryOther" name="industryOther"  value={props.industryOther}
									onChange={(e)=>props.handleChange(e)} required
									disabled={props.disabled}
									className="form-control form-control-sm"/>
							</FormGroup>
						}
							
						<FormGroup>
							<ControlLabel>
								{props.t('screen.wizard.general.country')} <span style={{color:'red'}}>*</span>
							</ControlLabel>

							<SelectPicker 
								data={props.countries}
								value={props.country}
								onChange={(e)=>props.handleChange({target:{name:'country', value:e}})}
								required
								disabled={props.disabled}
								className="s-time-zone"
								id="country"
								name="country"
								style={{width:'100%'}}
							/>
						</FormGroup>
						<FormGroup>
							<ControlLabel>
								{props.t('screen.wizard.general.time_zone')} <span style={{color:'red'}}>*</span>
							</ControlLabel>

							<SelectPicker 
								data={props.zones}
								value={props.time_zone}
								onChange={(e)=>props.handleChange({target:{name:'time_zone', value:e}})}
								required
								disabled={props.disabled}
								className="s-time-zone"
								id="time_zone"
								name="time_zone"
								style={{width:'100%'}}
							/>
							
						</FormGroup>
					</Form>
				</Col>
			</FlexboxGrid>
		</Container>
	)
}

export default General;