import React, {Component} from 'react';
import {CardCvcElement,CardNumberElement, CardExpiryElement} from '@stripe/react-stripe-js';
import { Alert, Grid, Col, ControlLabel} from 'rsuite';

export default class CardForm extends Component {

  constructor(props){
    super(props);

    this.getCardData = this.getCardData.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
			name:""
    };
  }

  componentDidMount(){
    this.props.onRef(this);
  }
    
  async getCardData(){
    const {stripe, elements} = this.props;

    if(this.state.name === "" || this.state.name === null || this.state.name === undefined){
      Alert.error(this.props.t('error_alert.error_card_name'),3500);
      return;
    }
    
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card:elements.getElement(CardNumberElement)
    });

    if(error !== undefined){
      console.log(error);
      Alert.error(error.message,3500);
      return;
    }

    var token = await this.getToken();
    if(token === null || token === undefined){
      Alert.error(this.props.t('error_alert.error_pay_process'), 3500);
      return;
    }
    var res = {
      paymentMethodId: paymentMethod.id,
      tokenId: token.id,
      name: this.state.name,
      country: paymentMethod.card.country
    }

    return res;
  };

  async getToken(){
    const {stripe, elements} = this.props;

    var res = await stripe.createToken(elements.getElement(CardNumberElement)).then(await function(result) {
      if(result.token){
        return result.token;
      }
      return null;
    });
    return res;
  }

  handleChange(event){
    var value = event.target.value;
		var name = event.target.name;
    this.setState({[name]: value});	
  }

  render() {
    const {t} = this.props;
    return (
        <div>
          <Grid fluid>
            <Col xs={24}>
              <ControlLabel>{t('modal.subscription.name')}</ControlLabel>
              <input style={{width:"100%"}}
                name="name" 
                id="name"
                value={this.state.name} 
                onChange={this.handleChange}
                required
                className="form-control form-control-sm s-input-suscription"
              />
            </Col>
            <Col xs={24}>
              <ControlLabel>{t('modal.subscription.card_number')}</ControlLabel>
              <CardNumberElement options={{showIcon: true, placeholder:"", style:{base:{fontFamily:'Roboto Condensed'}}}}/>
            </Col>            
            <Col xs={24} md={12}>
              <ControlLabel>{t('modal.subscription.ex_date')}</ControlLabel>
              <CardExpiryElement options={{placeholder:"MM/YY", style:{base:{fontFamily:'Roboto Condensed'}}}}/>
            </Col>
            <Col xs={24} md={12}>
              <ControlLabel>{t('modal.subscription.cvc')}</ControlLabel>
              <CardCvcElement options={{placeholder:"", style:{base:{fontFamily:'Roboto Condensed'}}}}/>
            </Col>
          </Grid>
        </div>
    );
  }

}