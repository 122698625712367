import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import Layout from '../../../components/Layout';
import { FlexboxGrid, Col, Grid, Panel, Icon, Divider, Button } from 'rsuite';
import moment from 'moment';
import { Image } from 'react-bootstrap';
import ModalDetail from './ModalDetail';
import ModalMap from '../ModalMap';


class VisitDetails extends Component{
    constructor(props){
        super(props);

        this.getLocation = this.getLocation.bind(this);

        this.state = {
            startEmployee:"",
            startLocation:"",
            startDate:"",
            startLat:"",
            startLon:"",
            endEmployee:"",
            endLocation:"",
            endLat:"",
            endLon:"",
            endDate:"",
            deviceEmployee:"",
            time:"",
            evidenceList:[],
            startDateSearch:"",
            endDateSearch:""
        }
    }

    async componentDidMount(){
        console.log(this.props)
        if(this.props.location.state !== undefined){
            this.setState({
                startDateSearch: this.props.location.state.startDate,
                endDateSearch: this.props.location.state.endDate
            });
        }

        var id = this.props.match.params.id;
        
        var req = await this.props.fetchRequest({
            api:'check',
            method:'GET',
            url: 'visits/details/'+id,
            modal: this.props.modal_loader,
            message_modal: this.props.t('modal.spin.loading'),
            requireToken:true
        });

        if(req === undefined){
            localStorage.clear();
            sessionStorage.clear();
            this.props.history.push('/login');
            return;
        }

        this.setState({
            startEmployee: req.visitStartEmployee,
            startLocation: req.visitStartLocation,
            startLat: req.startLatitude !== null ? req.startLatitude : "",
            startLon: req.startLongitude !== null ? req.startLongitude : "",
            startDate: req.visitStartDate,
            endEmployee: req.visitEndEmployee,
            endLocation: req.visitEndLocation,
            endLat: req.endLatitude !== null ? req.endLatitude : "",
            endLon: req.endLongitude !== null ? req.endLongitude : "",
            endDate: req.visitEndDate,
            deviceEmployee: req.deviceEmployee,
            time: req.visitTime,
            evidenceList: req.visitEvidenceList.reverse(),
            startValidFace: req.startValidFace,
            endValidFace: req.endValidFace
        })
    }

    getLocation(lat, lng, name){
        this.modal_map.handleShow(lat, lng, name)
    }

    render(){
        const {t} = this.props;

        return(
            <Layout {...this.props}>
                <h3 className="s-title-page">{t('screen.reports.visit.details.title')}</h3>
                <fieldset>
                    <FlexboxGrid justify="center">
                        <Col xs={24} sm={20} md={20} className="s-content">
                            <Grid fluid>
                                <Col xs={6}>
                                    <Panel header={t('screen.reports.visit.details.start')} shaded>
                                        <Grid fluid>
                                            <Col xs={4}>
                                                <Icon size="2x" icon="user" style={{color:'#1a9e13'}} /> 
                                            </Col>
                                            <Col xs={20}>
                                                <span>{this.state.deviceEmployee}</span>
                                            </Col>
                                        </Grid>
                                        <Grid fluid>
                                            <Col xs={4}>
                                                <Icon size="2x" icon="map-marker" 
                                                    style={this.state.startLocation !== null && this.state.startLocation !== " null" && this.state.startLocation !== "" ? 
                                                        {color:'#1a9e13'} : 
                                                    this.state.startLat !== "" && this.state.startLon !== "" ?
                                                        {color:'#1a9e13', cursor:'pointer'}
                                                    : {color:'#b1b4b7'}} 
                                                    onClick={(e)=>this.state.startLocation !== null && this.state.startLocation !== " null" && this.state.startLocation !== "" ?
                                                        e.preventDefault()
                                                    : this.state.startLat !== "" && this.state.startLon !== "" ?
                                                        this.getLocation(this.state.startLat, this.state.startLon, this.state.deviceEmployee)
                                                    : e.preventDefault()
                                                    }    
                                                /> 
                                            </Col>
                                            <Col xs={20}>
                                                <span>{this.state.startLocation !== ""?
                                                    this.state.startLocation
                                                :this.state.startLat === ""?
                                                t('screen.reports.visit.columns.no_location')
                                                :""
                                                }</span>
                                            </Col>
                                        </Grid>
                                        <Grid fluid>
                                            <Col xs={4}>
                                                <Icon size="2x" icon="smile-o" 
                                                style={(this.state.startEmployee !== null && this.state.startValidFace) ? {color:'#1a9e13'} : {color:'#b1b4b7'}} /> 
                                            </Col>
                                            <Col xs={20}>
                                                <span>{this.state.startEmployee}</span>
                                            </Col>
                                        </Grid>
                                    </Panel>
                                    <Grid fluid>
                                        <Col xs={24} style={{textAlign:'center'}}>
                                            <span>{moment(this.state.startDate).format('DD/MM/YYYY - HH:mm')}</span>
                                        </Col>
                                    </Grid>
                                </Col>
                                <Col xs={6} xsPush={12}>
                                    <Panel header={t('screen.reports.visit.details.end')} shaded>
                                        <Grid fluid>
                                            <Col xs={4}>
                                                <Icon size="2x" icon="user" style={{color:'#1a9e13'}} /> 
                                            </Col>
                                            <Col xs={20}>
                                                <span>{this.state.deviceEmployee}</span>
                                            </Col>
                                        </Grid>
                                        <Grid fluid>
                                            <Col xs={4}>
                                                <Icon size="2x" icon="map-marker" 
                                                    style={this.state.endLocation !== null && this.state.endLocation !== " null" && this.state.endLocation !== "" ? 
                                                        {color:'#1a9e13'} 
                                                    : this.state.endLat !== "" && this.state.endLon !== "" ?
                                                        {color:'#1a9e13', cursor:'pointer'}
                                                    : {color:'#b1b4b7'}} 
                                                    onClick={(e)=>this.state.endLocation !== null && this.state.endLocation !== " null" && this.state.endLocation !== "" ? 
                                                        e.preventDefault()
                                                    : this.state.endLat !== "" && this.state.endLon !== "" ?
                                                        this.getLocation(this.state.endLat, this.state.endLon, this.state.deviceEmployee)
                                                    : e.preventDefault()
                                                    } 
                                                /> 
                                            </Col>
                                            <Col xs={20}>
                                                <span>{this.state.endLocation !== ""?
                                                    this.state.endLocation
                                                    :this.state.endLat === ""?
                                                        t('screen.reports.visit.columns.no_location')
                                                    :""}
                                                </span>
                                            </Col>
                                        </Grid>
                                        <Grid fluid>
                                            <Col xs={4}>
                                                <Icon size="2x" icon="smile-o" 
                                                style={(this.state.endEmployee !== null && this.state.endValidFace) ? {color:'#1a9e13'} : {color:'#b1b4b7'}} /> 
                                            </Col>
                                            <Col xs={20}>
                                                <span>{this.state.endEmployee}</span>
                                            </Col>
                                        </Grid>
                                    </Panel>
                                    <Grid fluid>
                                        <Col xs={24} style={{textAlign:'center'}}>
                                            <span>{moment(this.state.endDate).format('DD/MM/YYYY - HH:mm')}</span>
                                        </Col>
                                    </Grid>
                                </Col>                        
                            </Grid>
                            <FlexboxGrid justify="center">
                                <Col xs={18}>
                                    <Divider className="s-rs-divider"/>    
                                </Col>                                
                            </FlexboxGrid>
                            <FlexboxGrid justify="center" className="form-group">
                                <Col xs={18} style={{textAlign:'center'}}>
                                    <span>{this.state.time}</span>
                                </Col>                                
                            </FlexboxGrid>
                            <Grid fluid className="form-group">
                                {this.state.evidenceList.map((list, i)=>
                                    <Col xs={24} sm={12} md={4} key={i} >
                                        <Panel shaded bordered bodyFill >
                                            <Panel style={{textAlign:"center"}}>
                                                <Image 
                                                    src={'data:image/jpeg;base64,'+list.imageBase64} 
                                                    alt="imagen" 
                                                    style={{cursor:'pointer',textAlign:"center", width:"100%",height:"100%"}}
                                                    onClick={()=>this.modal_detail.handleShow(i, this.state.evidenceList)}
                                                />
                                            </Panel>
                                            <Panel>
                                                <p style={{overflow: "hidden",textOverflow:"ellipsis",whiteSpace:"nowrap"}}>
                                                    {list.description}
                                                </p>
                                            </Panel>
                                        </Panel>
                                    </Col>
                                )}
                            </Grid>
                            <Divider />
                            <FlexboxGrid justify="center" className="form-group">
                                <Button 
                                    onClick={(e)=>
                                        this.props.history.push({                
                                            pathname: '/reports/visit',
                                            state: { 
                                                startDate: this.state.startDateSearch,
                                                endDate: this.state.endDateSearch
                                            }
                                        })
                                    } 
                                    style={{marginLeft:8}}
                                >
                                    {t('button.return')}
                                </Button>
                            </FlexboxGrid>
                        </Col>
                        
                        
                    </FlexboxGrid>
                </fieldset>

                <ModalDetail {...this.props} onRef={ref => this.modal_detail = ref} />
                <ModalMap  {...this.props} onRef={ref => this.modal_map = ref} />
            </Layout>
        )        
    }
}

export default withTranslation('translations')(VisitDetails);