import React, {Component} from 'react';
import { Nav, Navbar, Icon, ButtonToolbar, ButtonGroup, IconButton, Col, Drawer, Button } from 'rsuite';
import $ from 'jquery';
import Flag from "react-world-flags";
import { Image } from 'react-bootstrap';
import SideNav from './SideNav';
import ModalSubscription from './ModalSubscription';

class Navigation extends Component{
	constructor(props){
		super(props);

		this.logout = this.logout.bind(this);
		this.change = this.change.bind(this);
		this.modalSuscription = this.modalSuscription.bind(this);
		this.handleChange = this.handleChange.bind(this);

		this.state = {
			show: false,
			lang:"",
			flag:""
		};
	}

	componentDidMount(){
		var lang = atob(sessionStorage.getItem(btoa('lang')));
		this.setState({lang: lang, flag: lang === 'es' ? 'MX' : 'US'});

		this.change(lang);
	}

	logout(){
    	localStorage.clear();
		sessionStorage.clear();
		this.props.history.push('/login');
	}

	handleChange(e){
		this.setState({
			[e.target.name]: e.target.value,
			flag: e.target.value === 'es' ? 'MX' : 'US'
		});
		this.change(e.target.value);
	}

	change(lang = this.props.i18n.language){
		
		const {i18n} =  this.props;
		
		i18n.changeLanguage(lang);
		sessionStorage.setItem(btoa("lang"), btoa(lang));
		        
        if($('.table').length > 0){
			var table = null;
		
			if(this.props.children.length === undefined){
				if(this.props.children._self !== undefined){
					table = this.props.children._self.table;
				}else{
					table = this.props.children._owner.stateNode.table;
				}
				
			}else{
				if(this.props.children[this.props.children.length - 1]._self !== undefined){
					table = this.props.children[this.props.children.length - 1]._self.table;
				}else{
					table = this.props.children[this.props.children.length - 1]._owner.stateNode.table;
				}
				
			}
			
			if(table !== undefined){
				this.props.modal_loader.handleShow(this.props.t('modal.spin.loading'));
				table.resetTable();
				setTimeout(()=>{
					this.props.modal_loader.handleClose();
					table.setTable();
				}, 400);
			}
				
		}
	}

	modalSuscription(){
		this.modal_suscription.handleShow();
	}

	render(){
		const {t} = this.props;
		//const user = atob(sessionStorage.getItem(btoa('sys-leadName'))).split('-')[1];
		const suscription = sessionStorage.getItem("suscription");
		const canceled = JSON.parse(sessionStorage.getItem('canceled'));
		return (
			<div>
			<Navbar appearance="subtle">
				
				<Navbar.Body>
					<Col xs={2} mdHidden lgHidden>
						<Nav>
							<ButtonToolbar>
								<ButtonGroup>
									<IconButton 
										appearance="subtle"
										icon={<Icon icon="bars" />}
										size="lg"
										title="Menú"
										onClick={()=>this.setState({show: !this.state.show})}
									/>
								</ButtonGroup>
							</ButtonToolbar>
						</Nav>
					</Col>
					<Col xsHidden sm={4} md={3} lg={2}>
						<Nav>							
							<Image src="../../../images/image.png" alt="logo" fluid className="s-logo-img" />
						</Nav>
					</Col>
					<Col xs={22} sm={18} md={21} lg={22}>
						<Nav pullRight>
							<Button color={!canceled ?
									suscription === 'free' ? 'green'
									: suscription === 'Basic Account' ? 'blue' 
									: "orange"
								: "red"}
								style={{marginRight:8}}
								size="xs"
								onClick={(e)=>this.props.history.push('/subscription-plans')}
							>
								{t('general.accounts.'+(
									suscription === 'free' ? 'free'
									: suscription === 'Basic Account' ? 'basic' 
									: "premium"
								))}
							</Button>
							<Flag code={this.state.flag} height={15} style={{marginRight:8}} />
							<select className="form-control form-control-sm col-5" value={this.state.lang} name="lang" id="lang"
								onChange={(e)=>this.handleChange(e)}
								hastooltip="false"
								style={{marginRight:8, display:'inline-block'}}
							>
								<option value="es">{t('general.language.es')}</option>
								<option value="en">{t('general.language.en')}</option>
							</select>

							<IconButton 
								icon={<Icon icon="sign-out" size="lg" />}
								onClick={()=>this.logout()}
								appearance="subtle"
								className="s-sign-out"
							/>
						</Nav>
					</Col>
				</Navbar.Body>
			</Navbar>
	
			<Drawer
				size="xs"
				placement="left"
				show={this.state.show}
				onHide={()=>this.setState({show:false})}
				className="s-drawer"
			>
				<Drawer.Header>
					<Drawer.Title>Menú</Drawer.Title>
				</Drawer.Header>
				<Drawer.Body>
					<SideNav {...this.props} 
						expanded={true}
					/>				
				</Drawer.Body>
			</Drawer>
	
			<ModalSubscription {...this.props} onRef={ref => this.modal_suscription = ref} />
			</div>
		  );
	}
  	
}
export default Navigation;