import React, {Component} from 'react';

import {
	Sidenav, Nav, Icon
} from 'rsuite';
import { swalAction } from '../lib/functions';


class SideNav extends Component{
	constructor(props){
		super(props);

		this.changeScreen = this.changeScreen.bind(this);

		this.state = {
			title:"",
			expanded:false,
			menu:[
				{title: 'home', url:'/', icon:'home', image:'ICON-home.png'},
				{title: 'employees', url:'/settings/employee', icon:'user', image:'ICON-empleado.png'},
				{title: 'locations', url:'/settings/locations', icon:'map', image:'ICON-ubicacion.png'},
				{title: 'events', url:'/settings/employee/events', icon:'calendar', image:''},
				{title: 'reports.title', url:'/reports', icon:'detail', image:'ICON-reportes.png'},
				{title: 'config_account', url:'/settings/account', icon:'cog', image:'ICON-configuracion.png'}
			]
		}
	}

	componentDidMount(){
		if(this.props.expanded !== undefined){
			this.setState({expanded: this.props.expanded});
		}
	}

	changeScreen(url){
		var verify = true;
		if(this.props.verify !== undefined){
			verify = this.props.verify();
			
			if(!verify){
				var obj = {
					title           : this.props.t('modal.alert.titles.update_employee'),
					text            : this.props.t('modal.alert.messages.update_employee'),
					icon            : 'warning',
					showConfirm     : true,
					showCancel      : true,
					textConfirm     : this.props.t('modal.alert.confirms.yes'),
					colorConfirm    : '#3085d6',
					colorCancel     : '#d33',
					textcancel      : this.props.t('modal.alert.confirms.no'),
					values          : '',
					fn              : ()=>{this.props.history.push(url);}
				}
				
				swalAction(obj);
			}else{
				this.props.history.push(url);
			}
			
		}
		if(verify){
			this.props.history.push(url);
		}
		
	}

	render(){
		const {t} = this.props;
		let canceled = JSON.parse(sessionStorage.getItem('canceled'));
		return (
			<Sidenav appearance="subtle" expanded={this.state.expanded} className="s-background-sidebar">
				<Sidenav.Body>
					<Nav>
						{this.state.menu.map((m, i)=>(
							(m.title !== "events" || sessionStorage.getItem("suscription") !== "free")?
								<Nav.Item 		
									key={i}							
									eventKey={i}
									disabled={canceled && m.url !== '/reports'}
									renderItem={item => 
										<a role="button" tabIndex={i} href="/#"
											className="s-nav-item-content rs-nav-item-content"
											onClick={(e)=>{
												e.preventDefault(); 
												return canceled && m.url !== '/reports'? 
													e.preventDefault()
												: this.changeScreen(m.url)
											}}
										>
											{m.image !== '' ? 
												<img src={"/images/icons/"+m.image}  alt="icon" 
													style={canceled && m.url !== '/reports' ? {filter: 'grayscale(0.8)'} : {}}
												/>
											:
												<Icon icon={m.icon} 
													className={
														i > 0 && i < this.state.menu.length - 1 ? "s-primary" : 
														i === this.state.menu.length - 1 ? "s-secondary" : ""
													}
													size="4x"
												/>
											}
											<span className={"rs-ripple-pond "+(m.image !== '' ? 's-submenu-image' : 's-submenu-icon')}>
												{t('menu.'+m.title)}												
											</span>
										</a>
									}
									title={t('menu.'+m.title)}
									className={
										i > 0 && i < this.state.menu.length - 1 ? "s-background-primary" : 
										i === this.state.menu.length - 1 ? "s-background-secondary" : ""
									}
									
									style={
										i === 0 ? {borderBottom:'1px solid #e5e5ea'} : 
										i === this.state.menu.length - 1 ? {borderTop:'1px solid #e5e5ea'} : {}}
								>
									{t('menu.'+m.title)}
								</Nav.Item>
							:null
						))}
					</Nav>
				</Sidenav.Body>

			</Sidenav>
		)
	}	
}

export default SideNav;