import React, {Component} from 'react';
import {Panel, Grid, Col, Button, Icon, IconButton} from 'rsuite';
import { Table } from 'react-bootstrap';
import ModalFields from './ModalFields';
import SaveBtn from './SaveBtn';
import SavedMark from './SavedMark';

class EmployFieldSettings extends Component {
	constructor(props){
		super(props);

		this.addField = this.addField.bind(this);
		this.openModal = this.openModal.bind(this);

		this.state = {
			required:[],
			schemas:[],
			is_saved:false,
			active_save:false,
			disabled:true
		}
	}

	componentDidMount(){
        this.props.onRef(this);
    }

    componentWillUnmount(){
        this.props.onRef(null);
    }

	addField(){
		
		var schemas = this.state.schemas;
		var required = this.state.required;
		var obj = {};
		var name = "";

		name = this.modal.state.nameField.trim()
					.toLowerCase()
					.replace(/ /g, "_");

		switch(this.modal.state.typeField){
			case 'string':
				
				obj = {
					schema: {
						title: this.modal.state.nameField.trim(),
						type: 'string',
						default: this.modal.state.default,
					},					
					name: name,
					active: this.modal.state.actived,
					required: this.modal.state.required
				};
				
				schemas.push(obj);
				break;
			case 'string_enum':
				obj = {
					schema:{
						title: this.modal.state.nameField,
						type: 'string',
						default: '',
						enum: [],
					},					
					name: name,
					active: this.modal.state.actived,
					required: this.modal.state.required
				};

				this.modal.state.options.map((o)=>{
					if(o.default){
						obj.schema.default = o.value;
					}

					obj.schema.enum.push(o.value);

					return obj;
				});

				schemas.push(obj);

				break;
			case 'number':
				obj = {
					schema:{
						title: this.modal.state.nameField,
						type: 'number',
					},					
					name: name,
					active: this.modal.state.actived,
					required: this.modal.state.required
				}

				schemas.push(obj);
				break;
			case 'string_date':
				obj = {
					schema:{
						title: this.modal.state.nameField,
						type: 'string',
						format: 'date',
					},					
					name: name,
					active: this.modal.state.actived,
					required: this.modal.state.required
				}

				schemas.push(obj);
				break;
			case 'string_yesNo':
				obj = {
					schema:{
						title: this.modal.state.nameField,
						type: 'string',
						default: this.modal.state.default,
						enum: ['Yes', 'No']
					},
					name: name,
					active: this.modal.state.actived,
					required: this.modal.state.required
				};

				schemas.push(obj);

				break;
			default:
				break;
		}

		this.setState({
			schemas: schemas,
			active_save:true, 
			is_saved:false,
			disabled:false
		});
		

		this.modal.close();
	}

	openModal(e){
		this.modal.open();
	}

	delete(e, i){
		var schemas = this.state.schemas;
		var required = this.state.required;

		var index = required.findIndex(obj =>
			obj === schemas[i].name
		);

		if(index !== -1){
			required.splice(index, 1);
		}

		schemas.splice(i, 1);

		this.setState({schemas:schemas, required:required, active_save: true, is_saved: false});
	}

	render(){
		const {t} = this.props;
		return (
			<Panel shaded>
				<i class="rs-panel-heading" style={{padding:"0px"}}> 
					{t('screen.settings.account.employ_fields.title')}
                </i>
                <i class="rs-icon rs-icon-help-o rs-panel-heading" style={{paddingLeft:"10px"}} title={this.props.t('screen.settings.account.tooltips.employee_fields')}> 
                </i>
				<Grid fluid>
					<Col xs={24} sm={2} md={1}>
						{this.state.disabled ?
							<IconButton 
								icon={<Icon icon='pencil' />}
								color="blue"
								size="sm"
								onClick={()=>this.setState({disabled: !this.state.disabled})}
							/>
						:
							<SaveBtn t={this.props.t} active={this.state.active_save} action={this.props.dataUpdate} />
						}
					</Col>
					<Col xs={24} sm={4}>
						<Button className="s-btn-primary" onClick={(e)=>this.openModal(e)} disabled={this.state.disabled}>
							{t('button.new')}
						</Button>
					</Col>
					<Col xs={24} sm={19}>
						{this.state.is_saved &&
							<SavedMark />
						}
					</Col>
				</Grid>
				<Grid fluid>
					<Col xs={24} sm={24}>
						<Table responsive hover style={{fontSize:'15px'}} className="fixed_table_scroll">
							<thead>
								<tr>
									<th style={{width:'70%'}}>{t('screen.settings.account.employ_fields.field_name')}</th>
									<th style={{width:'10%'}}>{t('screen.settings.account.employ_fields.required')}</th>
									<th style={{width:'10%'}}>{t('screen.settings.account.employ_fields.active')}</th>
									<th style={{width:'10em'}}></th>
								</tr>
							</thead>
							<tbody>
								{this.state.schemas.map((s, i)=>(
									<tr key={i} style={{display:'block'}}>
										<td style={{width:'71%'}}>{s.schema.title}</td>
										<td style={{width:'10%'}}>
											{s.required &&
												<Icon icon="check" size="lg" />
											}
										</td>
										<td style={{width:'10%'}}>
											{s.active &&
												<Icon icon="check" size="lg" />
											}
										</td>
										<td style={{width:'10em'}}>
											<Icon icon="trash" size="lg" 
												style={{cursor:this.state.disabled ? 'no-drop' : 'pointer'}}
												onClick={(e)=>this.state.disabled ? e.preventDefault() : this.delete(e, i)} 
												title={t('button.delete')}	
											/>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Col>
					
				</Grid>

				<ModalFields {...this.props} onRef={ref => this.modal = ref}
					addField={this.addField} />
				
				
			</Panel>
		)
	}
	
}

export default EmployFieldSettings;