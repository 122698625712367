import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import {HashRouter} from 'react-router-dom';

import 'rsuite/dist/styles/rsuite-default.css';
//import 'rsuite/lib/styles/index.less';
//import '../assets/css/styles.less';
import 'bootstrap/dist/css/bootstrap.css';
import './theme.css';


ReactDOM.render(
  
    <Suspense fallback="">
      <HashRouter>
        <App />
      </HashRouter>
    </Suspense>
    
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
