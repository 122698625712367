import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';
import { Grid, Col, ControlLabel, Button, CheckPicker, Icon} from 'rsuite';

import Layout from '../../components/Layout';
import "react-big-calendar/lib/css/react-big-calendar.css";
import Swal from 'sweetalert2';
import $ from 'jquery';

require('moment/locale/es-mx');

const localizer = momentLocalizer(moment);


function CustomToolbar(props) {
    const navigate = action => {
        props.onView(action);
    }
    return (
        <div className="rbc-toolbar">
            <div className="rbc-toolbar-label">{props.label}</div>

            {/*
            <div className="rbc-btn-group">
                <button type="button" onClick={()=>navigate('month')} class="rbc-active">{props.t('screen.reports.calendar.form.month')}</button>                
                <button type="button" onClick={()=>navigate('week')}>{props.t('screen.reports.calendar.form.week')}</button>
                <button type="button" onClick={()=>navigate('day')}>{props.t('screen.reports.calendar.form.day')}</button>
                <button type="button" onClick={()=>navigate('agenda')}>{props.t('screen.reports.calendar.form.agenda')}</button>
            </div>*/}
        </div>
    )
}

class CalendarReport extends Component{
    constructor(props){
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.search = this.search.bind(this);
        this.updateColumns = this.updateColumns.bind(this);

        this.state = {
            start_date:moment().format('YYYY-MM-DD'),
            end_date:moment().format('YYYY-MM-DD'),
            employee:[],
            employees:[],
            data:[],
            filename:'name',
            years:[],
            months:[1,2,3,4,5,6,7,8,9,10,11,12],
            value:[],
            default_date : moment().toDate(),
            events:[/*{
                start: moment().toDate(),
                end: moment()
                  .add(1, "days")
                  .toDate(),
                title: "Some title"
              }*/],
        }
    }

    async componentDidMount(){
        var req = {
            api:'accounts',
            url:'employees',
            method:'GET',
            requireToken:true
        }
        var employees = await this.props.fetchRequest(req);
        var data = ['Todos'];
        employees.map((e)=>{
            e.label = e.firstName +' '+ e.lastName+' '+(e.maternalSurname === null ? '' : e.maternalSurname);
            e.value = e.id
            return e;
        });

        var years = [];
        var d = new Date();
        var n = d.getFullYear();

        for (let index = n-1; index <= n; index++) {
            years.push(index);
        }
        
        this.setState({employees: employees, data: data, years:years});

        //this.table.setTable();
    }

    handleChange(e){
        this.setState({[e.target.name]: e.target.value});
    }

    async search(e, url){
        $('.rs-btn-default').blur();
        var obj = {
            year: this.state.year,
            month: this.state.month,
            employeeIds: this.state.employee
        };

        if(this.state.employee.length > 0){
            var req = {
                api: 'reports',
                url: url,
                obj: obj,
                method: 'POST',
                modal: this.props.modal_loader,
                message_modal: this.props.t('modal.spin.searching'),
                requireToken: true
            };

            var request = await this.props.fetchRequest(req);
            var list = [];

            //var temp = [];
            request.map(r => 
                list.push({"start":moment(r.date).toDate(),"end":moment(r.date).toDate(),"title":r.employee.name,"absence":r.absence})
            );

            var date = new Date(this.state.year,this.state.month-1,'1');

            this.setState({events: list,default_date:date});
        }else{
            Swal.fire('Error', 'Debe seleccionar al menos un empleado', 'error');
        }

    }

    eventStyleGetter(event, start, end, isSelected) {
        //console.log(event);
        var bgc = "";

        if (event.absence === true) {
            bgc = '#E91B06';
        }else {
            bgc = '#22E906';
        }

        //var backgroundColor = '#' + event.hexColor;
        var style = {
            backgroundColor: 'transparent',
            borderRadius: '0px',
            opacity: 0.8,
            color: bgc,
            border: '0px',
            display: 'block',
            width: '40px',
            height: '50px !important'
        };
        return {
            style: style
        };
    }

    Event({ event }) {
        const showIcon = event.absence === true ? <Icon icon='times-circle-o' size="2x" /> : <Icon icon='check-circle-o' size="2x"/>
        return (
            showIcon
          
        )
    }

    dayEvent(events){
        /*console.log(events)
        const eventIcons = "";
        events.map((e) => 
            (console.log(e),
                eventIcons = eventIcons + e.absence === true ? <Icon icon='times-circle-o' size="2x" /> : <Icon icon='check-circle-o' size="2x"/>
            )
        )

        console.log(eventIcons)*/
    }

    updateColumns(e, i){
        var columns = this.state.columns;

        columns[i].visible = e.target.checked;

        this.setState({columns: columns});
    }

    render(){
        const {t} = this.props;
        var culture = atob(sessionStorage.getItem(btoa('lang')));
        const lang = {
            en: null,
                'en-GB': null,
            es: {
                week: 'Semana',
                work_week: 'Semana de trabajo',
                day: 'Día',
                month: 'Mes',
                previous: 'Atrás',
                next: 'Después',
                today: 'Hoy',
                agenda: 'Agenda',
            }
        }
		
        return (
            <Layout {...this.props}>
                <Grid fluid>
                    <Col xs={24} style={{textAlign:'center'}}>
                        <h3>{t('screen.reports.calendar.'+this.state.filename)}</h3>
                    </Col>
                    <Col xs={24} >
                        <Col sm={8} md={4}>
                            <ControlLabel className="s-table-title">{t('screen.reports.calendar.form.year')}</ControlLabel>
                            <select className="form-control form-control-sm" name="year" id="year" 
                                value={this.state.year} onChange={(e)=>this.handleChange(e)} required >
                                    <option value="">{t('general.select')}</option>
                                    {this.state.years.map((inc, i)=>(
                                        <option key={i} value={inc}>
                                            {inc}
                                        </option>
                                    ))}
                            </select>
                        </Col>
                        <Col sm={8} md={4}>
                            <ControlLabel className="s-table-title">{t('screen.reports.calendar.form.month')}</ControlLabel>
                            <select className="form-control form-control-sm" name="month" id="month" 
                                value={this.state.month} onChange={(e)=>this.handleChange(e)} required >
                                    <option value="">{t('general.select')}</option>
                                    {this.state.months.map((inc, i)=>(
                                        <option key={i} value={inc}>
                                            {t('general.months.'+inc)}
                                        </option>
                                    ))}
                            </select>
                        </Col>
                        <Col sm={8} md={4} className="form-group">
                            <ControlLabel className="s-table-title">{t('screen.reports.calendar.form.employee')}</ControlLabel>
                            {/*<AutoComplete
                                data={this.state.data}
                                value={this.state.employee}
                                onChange={(e)=>this.handleChange({target:{name:'employee', value:e}})}
                                id="employee"
                                required
                            />*/}
                            <CheckPicker
                                id="employee"
                                value={this.state.employee}
                                /*onChange={this.handleChange}*/
                                onChange={(e)=>this.handleChange({target:{name:'employee', value:e}})}
                                data={this.state.employees}
                                style={{ width: 224 }}
                                placeholder={t('general.select')}
                            />
                        </Col>
                        <Col sm={8} md={4} style={{textAlign:'center'}}>
                            <br/>
                            <Button className="s-btn-primary" onClick={(e)=>this.search(e, 'reports/calendar')}>
                                {t('button.search')}
                            </Button>
                            <Button onClick={(e)=>this.props.history.goBack()} style={{marginLeft:8}}>
                                {t('button.return')}
                            </Button>
                        </Col>
                    </Col>
                    <Col xs={24}>
                        <Calendar
                            culture={culture}
                            localizer={localizer}
                            popup={false}
                            onShowMore={(events, date) => this.dayEvent(events)}
                            defaultDate={this.state.default_date}
                            date={this.state.default_date}
                            defaultView="month"
                            events={this.state.events}
                            style={{ height: "100vh" }}
                            eventPropGetter={(this.eventStyleGetter)}
                            components={{
                                /*event: this.Event*/
                                month : {event: this.Event},
                                day : {event: this.Event},
                                toolbar: props => <CustomToolbar {...props} t={t} />
                            }}
                        />
                    </Col> 
                </Grid>
            </Layout>
        )
    }
}

export default withTranslation('translations')(CalendarReport);