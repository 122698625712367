import React, {Component} from 'react';
import {Grid, Col, ControlLabel, IconButton, Icon} from 'rsuite';

class FormIncidentRules extends Component{
    render(){
		const {t} = this.props;
        return(
            <div className="col-xs-24">
				{this.props.details &&
					<Col xs={4} sm={2} style={{marginTop:'29px'}}>
						<IconButton 
							icon={<Icon icon={!this.props.disable_fields ? "save" : "pencil"} />}
							onClick={()=>this.props.editRule()}
							color="blue"
							size="sm"
						/>
					</Col>
				}
				{this.props.show_name ?
					this.props.select_name ?
						<Grid className="form-group" fluid>
							<Col xs={16} sm={8} md={8} lg={5}>
								<ControlLabel>{t('screen.settings.employee.new.form.incident_rule')}</ControlLabel>
								<select className="form-control form-control-sm" name="incident_name" id="incident_name"
									value={this.props.incident_name} onChange={(e)=>this.props.selectIncident(e)} required
									disabled={this.props.disable_fields}>
									<option value="" disabled>{t('general.select')}</option>
									{this.props.incidents.map((inc, i)=>(
										<option key={i} value={inc.name}>
											{inc.name}
										</option>
									))}
								</select>
							</Col>
							
							
							<Col xs={4} sm={3} style={{marginTop:25}}>
								<IconButton 
									icon={<Icon icon="plus" />}
									title={t('error_alert.no_schedule_rule')}
									onClick={(e)=>this.props.newRule(e)}
									circle
									color={sessionStorage.getItem('suscription') === 'free' && this.props.incidents.length >= 1 ? 
										"default" 
									: "blue"}
								/>
							</Col>
							
							
						</Grid>
					:
						<Grid className="form-group">
							<Col xs={24} sm={8} md={6}>					
								<ControlLabel>{t('screen.settings.account.incident_rules.rule_name')}</ControlLabel> <span style={{color:'red'}}>*</span>
								<input type="text" className="form-control form-control-sm"
									value={this.props.incident.name}
									name="name"
									onChange={(e)=>this.props.handleChange(e, 'incident')} 
								/>
							</Col>
						</Grid>
				: null}
				<Grid style={{marginTop:10}} fluid>
					<Col xs={24} sm={12} md={8} lg={6}>
						<ControlLabel>{t('screen.settings.account.incident_rules.support_delays')}</ControlLabel>
						<select className="form-control form-control-sm col-sm-6 col-md-4 col-lg-4" name="supportDelays"
							value={this.props.incident.supportDelays}
							disabled={this.props.disable_fields}
							onChange={(e)=>this.props.handleChange(e, 'incident')}>
							<option value="Si">{t('general.yes')}</option>
							<option value="No">{t('general.no')}</option>
						</select>
					</Col>
					{this.props.incident.supportDelays === 'Si' &&
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <ControlLabel>{t('screen.settings.account.incident_rules.minutes_delays')}</ControlLabel>
							<input type="number" name="minutesDelays"
								className="is-number form-control form-control-sm col-sm-6 col-md-4 col-lg-4" 
								value={this.props.incident.minutesDelays || ""}
								disabled={this.props.disable_fields}
								onChange={(e)=>this.props.handleChange(e, 'incident')}
							/>
                        </Col>
                    }
					{this.props.incident.supportDelays === 'Si' &&
						<Col xs={24} sm={12} md={8} lg={6}>
							<ControlLabel>{t('screen.settings.account.incident_rules.number_delays')}</ControlLabel>
							<input type="number" name="numDelays"
								className="is-number form-control form-control-sm col-sm-6 col-md-4 col-lg-4" 
								disabled={this.props.disable_fields}
								value={this.props.incident.numDelays} onChange={(e)=>this.props.handleChange(e, 'incident')} />
						</Col>
					}
				</Grid>

				<Grid style={{marginTop:10}} fluid>
					<Col xs={24} sm={12} md={8} lg={6}>
						<ControlLabel>{t('screen.settings.account.incident_rules.support_extra_hr')}</ControlLabel>
						<select className="form-control form-control-sm col-sm-6 col-md-4 col-lg-4" name="supportExtraHr" 
							disabled={this.props.disable_fields}
							value={this.props.incident.supportExtraHr} onChange={(e)=>this.props.handleChange(e, 'incident')}>
							<option value="Si">{t('general.yes')}</option>
							<option value="No">{t('general.no')}</option>
						</select>
					</Col>
                    {this.props.incident.supportExtraHr === 'Si' &&
                        <Col xs={24} sm={12} md={16} lg={12}>
                            <ControlLabel>{t('screen.settings.account.incident_rules.max_extra_hr')}</ControlLabel>
							<input type="number" className="is-number form-control form-control-sm col-sm-6 col-md-4 col-lg-2" name="maxExtraHr"
								disabled={this.props.disable_fields}
                                value={this.props.incident.maxExtraHr} onChange={(e)=>this.props.handleChange(e, 'incident')} />
                        </Col>
                    }
				</Grid>
				
                <Grid style={{marginTop:10}} fluid>
					<Col xs={24} sm={12} md={8} lg={6}>
						<ControlLabel>{t('screen.settings.account.incident_rules.support_economic_days')}</ControlLabel>
						<select className="form-control form-control-sm col-sm-6 col-md-4 col-lg-4" name="supportEconomicDay"
							disabled={this.props.disable_fields}
							value={this.props.incident.supportEconomicDay} onChange={(e)=>this.props.handleChange(e, 'incident')}>
							<option value="Si">{t('general.yes')}</option>
							<option value="No">{t('general.no')}</option>
						</select>
					</Col>
                    {this.props.incident.supportEconomicDay === 'Si' &&
                        <Col xs={24} sm={12} md={16} lg={12}>
                            <ControlLabel>{t('screen.settings.account.incident_rules.max_economic_days')}</ControlLabel>
							<input type="number" className="is-number form-control form-control-sm col-sm-6 col-md-4 col-lg-2" name="maxEconomicDay"
								disabled={this.props.disable_fields}
                                value={this.props.incident.maxEconomicDay} onChange={(e)=>this.props.handleChange(e, 'incident')}/>
                        </Col>
                    }
				</Grid>
            </div>
        )
    }
}
export default FormIncidentRules;