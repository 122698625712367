import React, {Component} from 'react';
import { Container, FlexboxGrid, Grid, Col, Alert } from 'rsuite';
import moment from 'moment';
import readXlsxFile from 'read-excel-file';
import { withTranslation } from 'react-i18next';

import Layout from '../../../components/Layout';
import TableList from '../../../components/TableList';
import ModalLoadPicture from '../../../components/settings/employee/ModalLoadPicture';
import { fileDownLoad } from '../../../lib/fetchRequest';
import { swalAction } from '../../../lib/functions';

import ModalTemplate from '../../../components/settings/employee/ModalTemplate';


class Employee extends Component{
    constructor(props){
        super(props);

        this.getList = this.getList.bind(this);
        this.updateColumns = this.updateColumns.bind(this);
        this.openEdit = this.openEdit.bind(this);
        this.newEmployee = this.newEmployee.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.deleteEmployee = this.deleteEmployee.bind(this);
        this.updateEmployee = this.updateEmployee.bind(this);
        this.openModalPicture = this.openModalPicture.bind(this);
        this.actionIcon = this.actionIcon.bind(this);
        this.changeList = this.changeList.bind(this);
        this.downloadTemplate = this.downloadTemplate.bind(this);
        this.previewFile = this.previewFile.bind(this);
        this.reloadTable = this.reloadTable.bind(this);
        this.goToPlans = this.goToPlans.bind(this);

        this.state = {
            columns:[
                {title:'image', name:'image', visible:true, width:'50px'},
                {title:'name', name:'name', visible:true, width:'100px'},
                {title:'lastname', name:'lastname', visible:true, width:'100px'},
                {title:'maternal_surname', name:'maternal_surname', visible:false, width:'100px'},
                {title:'admission_date', name:'admission_date', visible:false, width:'100px'},
                {title:'sex', name:'sex', visible:false, width:'60px'},
                {title:'', name:'face', visible:true, width:'50px'},
                {title:'', name:'location', visible:true, width:'50px'},
                {title:'', name:'email', visible:true, width:'50px'},
                {title:'', name:'phone', visible:true, width:'50px'},
                {title:'', name:'actions', visible:true, width:'100px'},
            ],
            data:[],
            actions:[
                {
                    btn:true,
                    icon:'edit',
                    title:this.props.t('general.table.actions.edit'),
                    clickFn:'edit',
                    action: this.openEdit
                },
                {
                    btn:true,
                    icon:'trash-o',
                    title:this.props.t('general.table.actions.delete'),
                    clickFn:'delete',
                    action: this.deleteEmployee
                }
            ],
            employees:[],
            change_list:false
            
        }
    }

    async componentDidMount(){
        var columnsEmployee = sessionStorage.getItem('columnsEmployee');

        if(columnsEmployee === null){
            var req = {
                api:'accounts',
                method:'GET',
                url:'schemas/by-account',
                requireToken:true
            };
            let scheme = await this.props.fetchRequest(req);

            if(scheme !== undefined){
                var campos = this.state.columns;
                scheme.map((s)=>{
                    var properties = s.schema.schema_1.properties;

                    var keyNames = Object.keys(properties);

                    if(keyNames.length > 0){
                        keyNames.forEach((k, i)=>{
                            var obj = {
                                title:properties[k].title,
                                name:k,
                                visible:false,
                                special:true,
                                width:'120px'
                            };

                            var index = 6 + i;
                            campos.splice(index , 0, obj)                        
                        });
                    }
                    
                    
                    return s;
                });

                if(columnsEmployee === null){
                    sessionStorage.setItem('columnsEmployee', JSON.stringify(campos));
                }
                this.setState({columns: campos});
                
            }
        }else{
            this.setState({columns: JSON.parse(columnsEmployee)});
        }
        this.getList();
    }

    async getList(){
        var req = {
            api:'accounts', 
            method:'GET', 
            url:'employees/details', 
            modal:this.props.modal_loader,
            message_modal:this.props.t('modal.spin.loading'),
            requireToken:true
        }
        var employees = await this.props.fetchRequest(req);

        if(employees === undefined){
            localStorage.clear();
            sessionStorage.clear();
            this.props.history.push('/login');
            return;
        }

        var list = [];
        
        employees.forEach((e)=>{
            var item = [
                'icon/user/'+
                    (e.employee.attributes !== null ? 
                        e.employee.attributes.faceId !== undefined ?
                            e.employee.active ?
                                '#2196F3' 
                            : '#7E7E7E'
                        : '#7E7E7E'
                    : '')+'/image/'+(e.employee.active ? 'one' : 'no_action'),
                e.employee.firstName,
                e.employee.lastName,
                e.employee.maternalSurname,
                e.employee.startDate !== null ? moment(e.employee.startDate).format('DD/MM/YYYY') : "",
                e.employee.gender,
            ];

            if(e.employee.attributes !== undefined){
                var columns = this.state.columns;
                columns.forEach((col)=>{
                    if(col.special){
                        
                        if(e.employee.attributes !== null){
                            if(e.employee.attributes.special_fields !== undefined){
                                var index = e.employee.attributes.special_fields.findIndex(element => element.title === col.name);
                                if(index === -1){
                                    item.push('');
                                }else{
                                    item.push(e.employee.attributes.special_fields[index].value);
                                }
                            }else{
                                item.push('');    
                            }
                        }else{
                            item.push('');
                        }
                        
                    }
                });
                
            }

            item.push(
                'icon/smile-o/'+
                    (e.employee.enforceFacialRecognition ? 
                        e.employee.active ?
                            '#2196F3' 
                        : '#7E7E7E' 
                    : '#7E7E7E')+'/face/'+(e.employee.active ? 'one' : 'no_action'),
                'icon/map-marker/'+
                    (e.employee.enforceGeolocation ? 
                        e.employee.active ?
                            '#2196F3' 
                        : '#7E7E7E'     
                    : '#7E7E7E')+'/location/'+(e.employee.active ? 'one' : 'no_action'),
                'icon/envelope-o/'+
                    (e.invitationSent ? 
                        e.employee.active ?
                            '#2196F3' 
                        : '#7E7E7E' 
                    : '#7E7E7E')+'/email/'+(e.employee.active ? 'one' : 'no_action'),
                'icon/mobile/'+
                    (e.appInstalled ? 
                        e.employee.active ?
                            '#2196F3' 
                        : '#7E7E7E' 
                    : '#7E7E7E')+'/phone/'+(e.employee.active ? 'one' : 'no_action'),
                e.employee.id
            )

            list.push(item)
        });


        var suscription = sessionStorage.getItem('suscription');
        var disabled = false;
        var message = 'new';
        
        if(list.length >= 5 && suscription === 'free'){
            disabled = true;
            //message = 'error';
        }
        this.table.setState({disabled_new: disabled, message: message});

        this.setState({data: list, employees: employees});

        setTimeout(()=>{
            this.table.setTable();
        }, 400);

    }

    updateColumns(e, i){
        var columns = this.state.columns;

        columns[i].visible = e.target.checked;

        sessionStorage.setItem('columnsEmployee', JSON.stringify(columns));

        this.setState({columns: columns});
    }

    openEdit(e, id){
        var canActive = sessionStorage.getItem('suscription') === 'free' ?
                                this.state.data.length > 5 ? false : true
                            : true;
        this.props.history.push({
			pathname: '/settings/employee/details/'+id+'/'+canActive,
			state: { 
                id: id,
                can_active: canActive
			}
		});
    }

    async onDelete(id){
        var req = {
            api:'accounts',
            method:'DELETE',
            url:'employees/'+id,
            requireToken:true,
            modal:this.props.modal_loader,
            message_modal:this.props.t('modal.spin.deleting'),
        }

        this.props.fetchRequest(req);

        setTimeout(()=>{
            this.reloadTable();
        }, 500);   
    }

    newEmployee(){
        var suscription = sessionStorage.getItem('suscription');

        if(suscription === 'free' && this.state.employees.length >= 5){
            this.verifySuscription();
        }else{
            this.props.history.push('/settings/employee/new');
        }
        
    }

    goToPlans(){
        this.props.history.push("/subscription-plans");
    }

    verifySuscription(){
        var obj = {
            title           : this.props.t('modal.alert.titles.suscription'),
            text            : this.props.t('modal.alert.messages.update_suscription'),
            icon            : 'warning',
            showConfirm     : true,
            showCancel      : true,
            textConfirm     : this.props.t('modal.alert.confirms.go_plans'),
            colorConfirm    : '#3085d6',
            colorCancel     : '#d33',
            textcancel      : this.props.t('modal.alert.confirms.cancel'),
            values          : '',
            fn              : this.goToPlans
        }
        swalAction(obj);  
    }

    deleteEmployee(e, id){
        var obj = {
            title           : this.props.t('modal.alert.titles.alert'),
            text            : this.props.t('modal.alert.messages.delete_employee'),
            icon            : 'warning',
            showConfirm     : true,
            showCancel      : true,
            textConfirm     : this.props.t('modal.alert.confirms.delete'),
            colorConfirm    : '#3085d6',
            colorCancel     : '#d33',
            textcancel      : this.props.t('modal.alert.confirms.cancel'),
            values          : id,
            fn              : this.onDelete
        }
        swalAction(obj);        
    }

    async updateEmployee({obj, method, url}){
        var req = {
            api:'accounts',
            method: method,
            url: url,
            obj:obj,
            modal:this.props.modal_loader,
            message_modal:this.props.t('modal.spin.saving'),
            requireToken:true
        }

        var request = await this.props.fetchRequest(req);

        if(request !== undefined){
            setTimeout(()=>{
                this.table.resetTable();
                this.getList();
            }, 0)
        }
    }

    openModalPicture(e, id){
        this.modal_load_picture.handleShow(id);
    }

    actionIcon(e, i, type){
        var obj = this.state.employees[i].employee;
        var method = 'PUT';
        var url = 'employees/'+obj.id;
        var message = '';
        var confirm = this.props.t('modal.alert.confirms.update');

        switch(type){
            case 'image':
                this.openModalPicture(e, obj.id);
                break;                  
            case 'face':
                obj.enforceFacialRecognition = !obj.enforceFacialRecognition;
                message = this.props.t('modal.alert.messages.update_info_facial');
                break;
            case 'location':
                obj.enforceGeolocation = !obj.enforceGeolocation;
                message = this.props.t('modal.alert.messages.update_info_location');
                break;
            case 'email':
                var invite = this.state.employees[i].invitationSent;

                obj = {
                    type: "both",
                    employeeId: obj.id
                };

                method = 'POST';
                url = 'employees/invite';
                message = !invite ? this.props.t('modal.alert.messages.send_invitation') : this.props.t('modal.alert.messages.resend_invitation');
                confirm = this.props.t('modal.alert.confirms.send');

                
                break;
            default: 
                e.preventDefault();
                break;
        }

        if(type !== 'image' && type !== 'phone'){
            var item = {
                title           : this.props.t('modal.alert.titles.alert'),
                text            : message,
                icon            : 'warning',
                showConfirm     : true,
                showCancel      : true,
                textConfirm     : confirm,
                colorConfirm    : '#3085d6',
                colorCancel     : '#d33',
                textcancel      : this.props.t('modal.alert.confirms.cancel'),
                values          : {obj, method, url},
                fn              : this.updateEmployee
            }
            swalAction(item);
        }
        
    }

    changeList(){
        this.setState({change_list: !this.state.change_list});
    }

    async downloadTemplate(){
        var suscription = sessionStorage.getItem('suscription');

        if(suscription === 'free' && this.state.employees.length >= 5){
            this.verifySuscription();
        }else{
            var res = await this.props.fetchRequest({
                api:'accounts',
                method:'GET',
                url:'employees/download-template',
                requireToken:true,
                modal:this.props.modal_loader,
                message_modal:'Descargando...',
                is_file:true
            });
    
            if(res !== undefined){
                fileDownLoad('template', ".xlsx", 'application/vnd.ms-excel', res);
            }
        }        
    }

    previewFile(){
        const carga = document.getElementById('carga');
		var columns = [], listado = [];

		if(carga.files[0] !== undefined){
			if(carga.files[0].name.indexOf('xlsx') !== -1){
                
				readXlsxFile(carga.files[0]).then((rows) => {
			    	// `rows` is an array of rows
			    	// each row being an array of cells.
			  
			    	 return rows.map((row, i)=>{
			    		if(i > 0){
                            var obj = {}
                            row.map((row, j)=>{
                                return obj[columns[j].dataField] = row === null ? i : row;
                            });

			    			listado.push(obj);	
			    		}else{
			    			row.map((r)=>{
			    				return columns.push({dataField:r, text:r});
			    			});
                        }
                        
                        return row;
                    });	    	
                    
			  	});

                setTimeout(()=>{
                    this.modal_template.handleShow(listado, columns);
                }, 500);
			  	
			  	
			}else{

				Alert.error('Formato de archivo no valido, el formato valido es .xlsx');
			}
		}else{
			Alert.error('No se ha cargado ningun archivo');
		}
    }

    reloadTable(){
        this.table.resetTable();
        this.getList();
    }

    render(){
        const {t} = this.props;
        return (
            <Layout {...this.props}>
                <h3 className="s-title-page">{t('screen.settings.employee.list.title')}</h3>
                <Container>
                    <FlexboxGrid justify="center">
                        <Grid fluid style={{width:'100%'}}>
                            <Col xs={24}>
                                {/*this.state.change_list ?
                                    <Cards />    
                                :*/}
                                    <TableList 
                                        {...this.props} 
                                        onRef={ref => this.table = ref} 
                                        columns={this.state.columns}
                                        btnNew={true}
                                        data={this.state.data}
                                        actions={this.state.actions}
                                        updateColumns={this.updateColumns}
                                        new={this.newEmployee}
                                        actionIcon={this.actionIcon}
                                        openModal={this.openModalPicture}
                                        changeList={this.changeList}
                                        screen={"employee"}
                                        report={false}
                                        scroll={false}
                                        download={this.downloadTemplate}
                                        preview={this.previewFile}
                                    />
                                {}
                            </Col>
                        </Grid>
                    </FlexboxGrid>
                </Container>

                <ModalLoadPicture {...this.props} 
                    onRef={ref => this.modal_load_picture = ref} 
                    getList={this.getList}
                    table={this.table}
                />

                <ModalTemplate 
                    {...this.props}
                    reloadTable={this.reloadTable}
                    onRef={ref => this.modal_template = ref}
                />

            </Layout>
        )
    }
}

export default withTranslation('translations') (Employee);