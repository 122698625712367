import React from 'react';
import {IconButton, Icon} from 'rsuite';

const SaveBtn = (props)=>{
    return (
        <div style={{marginBottom:10}}>
            <IconButton 
                appearance={props.active ? "primary" : "subtle"}
                icon={<Icon icon="save" size="3x" />}
                size="sm"
                title={props.t('button.save')}
                onClick={(e)=>props.active ? props.action() : e.preventDefault()}
            />
            <br/>
        </div>
    )
}

export default SaveBtn;