import React from 'react';
import {
	Grid, Col, Row, FormGroup, ControlLabel, Button
} from 'rsuite';

import PasswordStrengthBar from 'react-password-strength-bar';

const Form = (props)=>{
	return (
		<Grid style={{padding:35}}>
			<h3>{props.t('screen.register.title')}</h3>
			<Row>
				<Col lg={12}>
					<form>
						<FormGroup>
							<ControlLabel>
								{props.t('screen.register.name')} <span style={{color:'red'}}>*</span>
							</ControlLabel>
							<input name="name" id="name" value={props.name} 
								className="form-control form-control-sm" 
								onChange={(e)=>props.handleChange(e)} required />
						</FormGroup>
						<FormGroup>
							<ControlLabel>
								{props.t('screen.register.email')} <span style={{color:'red'}}>*</span>
							</ControlLabel>
							<input name="email" id="email" value={props.email} 
								className="form-control form-control-sm" 
								onChange={(e)=>props.handleChange(e)} disabled />
						</FormGroup>
						<FormGroup>
							<ControlLabel>
								{props.t('screen.register.password')} <span style={{color:'red'}}>*</span>
							</ControlLabel>
							<input type='password' name="password" id="password" value={props.password} 
								className="form-control form-control-sm" 
								onChange={(e)=>props.handleChange(e)} required />
							<PasswordStrengthBar 
								password={props.password} 
								minLenght={8}
								scoreWords={[
									props.t('general.password_score_word.very_weak'),
									props.t('general.password_score_word.weak'),
									props.t('general.password_score_word.good'),
									props.t('general.password_score_word.very_good'),
									props.t('general.password_score_word.strong')
								]}
								shortScoreWord={props.t('general.password_score_word.small')}
							/>
							<div >
								<span style={{color:'red'}}>{props.error_password}</span>
							</div>
						</FormGroup>
						<FormGroup className="form-group">
							<ControlLabel>
								{props.t('screen.register.confirm_password')} <span style={{color:'red'}}>*</span>
							</ControlLabel>
							<input type='password' name="confirm_password" id="confirm_password" value={props.confirm_password} 
								className="form-control form-control-sm" 
								onChange={(e)=>props.handleChange(e)} required />
						</FormGroup>
						<FormGroup style={{textAlign:"right"}}>
							<Button appearance="primary" onClick={()=>props.onConfirm()}>
								{props.t('button.confirm')}
							</Button>
						</FormGroup>
					</form>
				</Col>
			</Row>
		</Grid>
	)
}

export default Form;