import React, { Component } from 'react';
import { Grid, IconButton, Icon} from 'rsuite';
import { Card } from 'react-bootstrap';

class WithFacial extends Component{
    constructor(props){
        super(props);

        this.state ={
            image : '/images/default-user-image.png'
        }
    }

    componentDidMount(){
        this.props.onRef(this);
    }

    render(){
        return (
            <Grid fluid>
                <Card style={{width:'75%', display:'inline-block'}}>
                    <Card.Img variant="top" src={this.state.image} id="img_employee" />
                </Card>
                <div style={{width:'25%', display:'inline-block', position:'absolute', marginLeft:10, bottom:0}}>
                    <IconButton 
                        icon={<Icon icon={this.state.image.indexOf('data:image') !== -1 ? "trash-o" : "plus"} />}
                        circle
                        color="blue"
                        onClick={()=>this.props.handleChange({target:{name:'show_image'}})}
                    />
                </div>                
            </Grid>
        )
    }
    
}

export default WithFacial;