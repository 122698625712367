import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import moment from 'moment';
import { Grid, Col, ControlLabel, AutoComplete, Button } from 'rsuite';
import Layout from '../../components/Layout';
import TableList from '../../components/TableList';
import Swal from 'sweetalert2';
import $ from 'jquery';

class Absences extends Component{
    constructor(props){
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.search = this.search.bind(this);
        this.updateColumns = this.updateColumns.bind(this);

        this.state = {
            start_date:moment().format('YYYY-MM-DD'),
            end_date:moment().format('YYYY-MM-DD'),
            employee:this.props.t('screen.reports.all'),// "Todos",
            employees:[],
            data:[],
            filename:'name',
            columns:[
                {title:'employee', name:'employee', visible:true},
                {title:'date', name:'date', visible:true}
            ],
            list:[]
        }
    }


    async componentDidMount(){
        var req = {
            api:'accounts',
            url:'employees',
            method:'GET',
            requireToken:true
        }

        var employees = await this.props.fetchRequest(req);
        var data = [this.props.t('screen.reports.all')];
        employees.map((e)=>(
            data.push(e.firstName+' '+e.lastName+' '+(e.maternalSurname === null ? '' : e.maternalSurname))
        ));

        this.setState({employees: employees, data: data});
        this.table.setTable();
    }

    componentDidUpdate(){
        var data = this.state.data;
        if(data.length > 0){
            if(data[0] !== this.props.t('screen.reports.all')){
                data[0] = this.props.t('screen.reports.all');

                this.setState({data: data, employee: data[0]});
            }            
        }        
    }


    handleChange(e){
        this.setState({[e.target.name]: e.target.value});
    }


    async search(e, url){
        $('.rs-btn-default').blur();
        var id = '';
        var error = "";

        if(this.state.employee === this.props.t('screen.reports.all')){
            id = 'all';
        }else if(this.state.employee === ""){
            error = this.props.t('error_alert.no_employee');
        }else{
            var index = this.state.employees.findIndex(obj => 
                obj.firstName+' '+obj.lastName+' '+obj.maternalSurname === this.state.employee
            );

            id = this.state.employees[index].id;
        }

        var obj = {
            startDate: this.state.start_date,
            endDate: this.state.end_date,
            employeeId: id
        };

        var req = {
            api: 'reports',
            url: url,
            obj: obj,
            method: 'POST',
            modal: this.props.modal_loader,
            message_modal: this.props.t('modal.spin.searching'),
            requireToken: true
        };

        if(error === ""){
            var request = await this.props.fetchRequest(req);
            var list = [];
            
            request.timeCards.map(r => 
                list.push([
                    r.employee.name,
                    moment(r.date).format('DD/MM/YYYY')
                ])
            );

            setTimeout(()=>{
                this.props.modal_loader.handleShow(this.props.t('modal.spin.loading'));
                this.table.resetTable();
            }, 500);

            setTimeout(()=>{
                this.props.modal_loader.handleClose();
                this.setState({list: list});
                this.table.setTable();

            }, 1000);
        }else{
            Swal.fire('Error', error, 'error');
            
        }
        

    }

    updateColumns(e, i){
        var columns = this.state.columns;
        columns[i].visible = e.target.checked;
        this.setState({columns: columns});
    }

    render(){
        const {t} = this.props;
        return (
            <Layout {...this.props}>
                <Grid fluid>
                    <Col xs={24} style={{textAlign:'center'}}>
                        <h3>{t('screen.reports.absences.'+this.state.filename)}</h3>
                    </Col>
                    <Col xs={24}>
                        <Col xs={24} sm={4}>
                            <ControlLabel className="s-table-title">{t('screen.reports.absences.form.start_date')}</ControlLabel>
                            <input type="date" name="start_date" id="start_date" 
                                className="form-control form-control-sm"
                                value={this.state.start_date}
                                onChange={this.handleChange} />
                        </Col>

                        <Col xs={24} sm={4}>
                            <ControlLabel className="s-table-title">{t('screen.reports.absences.form.end_date')}</ControlLabel>
                            <input type="date" name="end_date" id="end_date" 
                                className="form-control form-control-sm"
                                value={this.state.end_date}
                                onChange={this.handleChange} />
                        </Col>

                        <Col xs={24} sm={8} className="form-group">
                            <ControlLabel className="s-table-title">{t('screen.reports.absences.form.employee')}</ControlLabel>
                            <AutoComplete
                                data={this.state.data}
                                value={this.state.employee}
                                onChange={(e)=>this.handleChange({target:{name:'employee', value:e}})}
                                id="employee"
                                autocomplete="off"
                                required
                            />
                        </Col>

                        <Col xs={24} sm={6} style={{marginTop:30}}>
                            <Button className="s-btn-primary" onClick={(e)=>this.search(e, 'reports/absences')}>
                                {t('button.search')}
                            </Button>
                            <Button onClick={(e)=>this.props.history.goBack()} style={{marginLeft:8}}>
                                {t('button.return')}
                            </Button>
                        </Col>
                    </Col>
                    <Col xs={24}>
                        <Col xs={24}>
                            <TableList 
                                {...this.props} 
                                onRef={ref => this.table = ref} 
                                columns={this.state.columns}
                                btnNew={false}
                                data={this.state.list}
                                actions={[]}
                                updateColumns={this.updateColumns}
                                screen={"absences"}
                                searching={false}
                                report={true}
                                table_title={[]}
                                filename={t('screen.reports.absences.'+this.state.filename)}
                                scroll={true}
                                widths={['60%', '40%']}
                                search={this.search}
                            />
                        </Col>
                    </Col> 
                </Grid>
            </Layout>
        )
    }
}

export default withTranslation('translations')(Absences);