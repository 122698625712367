import React, {Component} from 'react';
import {Alert} from 'rsuite';
import {withTranslation} from 'react-i18next';

import Form from '../components/register/Form';
import ModalMessage from '../components/ModalMessage';

import {hasCapital, hasSpecial} from '../lib/functions';

class Register extends Component{
	constructor(props){
		super(props);

		this.showForm = this.showForm.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.onConfirm = this.onConfirm.bind(this);

		this.state = {
			name:"",
			email:"",
			password:"",
			confirm_password:"",
			show_form:false,
			urlCode:"",
			error_password:"",
		}
	}

	componentDidMount(){
		let urlCode = this.props.match.params.urlCode;
		if(urlCode === undefined){
			var url = window.location.pathname.split('/');
			urlCode = url[url.length - 1];
		}
		
		this.getLeadByCode(urlCode);
		
	}

	async getLeadByCode(urlCode){
		var req = {
			api: 'leads',
			method: 'GET',
			url: 'leads?urlCode='+urlCode
		}
		let response = await this.props.fetchRequest(req);
		if(response !== undefined){
			if(response.length > 0){
				this.setState({
					name: response[0].name, 
					email:response[0].email,
					urlCode:urlCode
				});

				if(response[0].language !== null){
					sessionStorage.setItem(btoa("lang"), btoa(response[0].language));

					const {i18n} =  this.props;
			
					i18n.changeLanguage(response[0].language);
				}
				
				
				if(!response[0].hasUser){
					setTimeout(()=>{
						this.setState({show_form: response[0].hasUser});
						this.modal_welcome.handleShow(
							this.props.t('modal.register.title'),
							this.props.t('modal.register.message'),
							this.props.t('modal.register.name')+' '+response[0].name+'!'
						);
					}, 1000);
						
				}else{
					this.props.history.push('/login');
				}
				
			}
		}
		
	}

	handleChange(e){
		if(e.target.name === 'password' || e.target.name === 'confirm_password'){
			var error = '';
			var caracteres = '', letra = '', simbolo = '';

			this.setState({[e.target.name]: e.target.value});

			if(e.target.name === 'password'){
				if(this.state.confirm_password !== "" && this.state.confirm_password !== e.target.value){
					error = this.props.t('error_alert.password_no_match');
				}
			}

			if(e.target.name === 'confirm_password'){
				if(this.state.password !== "" && this.state.password !== e.target.value){
					error = this.props.t('error_alert.password_no_match');
				}
			}

			if(error === ''){
				error = this.props.t('error_alert.password_contain');
				if(e.target.value.length < 8){
					caracteres = this.props.t('error_alert.min_character');
				}

				if(!hasCapital(e.target.value)){
					letra = this.props.t('error_alert.one_capital');
				}

				if(!hasSpecial(e.target.value)){
					simbolo = this.props.t('error_alert.one_symbol');
				};

				if(caracteres === "" && letra === "" && simbolo === ""){
					error = "";
				}else{
					error = error+caracteres+letra+simbolo;	
				}
			}
			
			this.setState({error_password: error});
		}else{
			this.setState({[e.target.name]: e.target.value});		
		}		

		if(e.target.value !== ""){
			var control = document.getElementById(e.target.name);
			if(control !== null){
				if(control.required && control.className.includes('error')){
		        	control.classList.remove('error');
		    	}
			}
		}
	}

	showForm(redirect = false){
		this.setState({show_form: true});
		this.modal_welcome.handleClose();

		if(redirect){
			this.props.history.push('/login');
		}
	}

	async onConfirm(){
		var alert = '';
		
		if(!this.props.isValidForm('form')){
			alert = this.props.t('error_alert.incomplete_fields');
		}

		if(this.state.password !== this.state.confirm_password){
			alert = this.props.t();
		}

		if(this.state.error_password !== ""){
			alert = this.state.error_password;
		}

		if(alert === ""){
			var obj = {
				email:this.state.email,
				password:this.state.password,
				urlCode:this.state.urlCode
			}
		
			var req = {
				api: 'auth',
				method: 'POST',
				url: 'auth/sign-up',
				obj: obj,
				modal: this.props.modal_loader,
				message_modal: this.props.t('modal.spin.saving')
			}

			var request = await this.props.fetchRequest(req);

			if(request !== undefined){
				this.setState({show_form: false});
				this.modal_welcome.handleShow(
					this.props.t('modal.confirm.title'),
					this.props.t('modal.confirm.message'),
					null,
					true
				);	
			}

		}else{
			Alert.error(alert);
		}	
	}

	render(){
		const {t} = this.props;
		return (
		    <div>
		    	{this.state.show_form &&
		    		<Form 
		    			t={t}
		    			name={this.state.name}
		    			email={this.state.email}
		    			password={this.state.password}
		    			confirm_password={this.state.confirm_password}
		    			handleChange={this.handleChange}
		    			onConfirm={this.onConfirm}
		    			error_password={this.state.error_password}
		    		/>
		    	}
			    	
		      	<ModalMessage {...this.props} 
		      		onRef={ref => this.modal_welcome =  ref} 
		      		name={this.state.name}
		      		btn={t('button.next')}
		      		showForm={this.showForm}
		      	/>

		    </div>
		)
	}
		  
}

export default withTranslation('translations')(Register)