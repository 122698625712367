import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {
	Container, FlexboxGrid, Steps, Panel, ButtonToolbar, Button, Alert, Col
} from 'rsuite';
import $ from 'jquery';

import General from '../components/wizard/General';
import Operation from '../components/wizard/Operation';
//import Connection from '../components/wizard/Connection';
import ModalMessage from '../components/ModalMessage';


class Wizard extends Component{
	constructor(props){
		super(props);

		this.onChange = this.onChange.bind(this);
		this.onNext = this.onNext.bind(this);
		this.onPrevious = this.onPrevious.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleCheck = this.handleCheck.bind(this);
		this.onFinish = this.onFinish.bind(this);
		this.onConfirm = this.onConfirm.bind(this);
		this.showForm = this.showForm.bind(this);

		this.state = {
			show:false,
			id:"",
			step:0,
			status:"process",

			company_name:"",
			industries:[],
			industry:"",
			industryOther:"",
			country:"MEX",
			countries:[{key:'MEX', value:'México'}],
			time_zone:"",
			company_size:"",
			zones:[],

			enforceGeolocation:false,
			enforceFacialRecognition:false,
			payrollWeekly:false,
			payrollBiweekly:false,
			payrollMonthly:false,
			payrollSpecial:false,
			supportDelays:false,
			supportVacations:false,
			supportEconomicDays:false,
			supportAbsences:false,

			supportAspel:false,
			supportContpaq:false,

			color_next:'default',
			vertical:false,
			language:"",

			//VALORES POR DEFAULT (NO CAMBIAR)
			breakOutOffsetMinutes: 15,
			checkAheadOffsetHours: 1,
			checkBackOffsetHours: 1,
			checkMaxRaduis: 1000
		}
	}


	async componentDidMount(){
		var lead_id = atob(sessionStorage.getItem(btoa('lead_id')));
		let showMessage = true;
		/*if(this.props.location.state !== undefined){
			if(this.props.location.state.from ==='login'){				
				showMessage = false;
				this.setState({step: 1, show:true});
			}
		}*/

		var lead = await this.props.fetchRequest({api:'leads', method:'GET', url:'leads/'+lead_id, requireToken:true});
		
		if(lead === undefined){
			this.props.closeSesion();
			return;
		}

		if(lead.language !== null){
			sessionStorage.setItem(btoa("lang"), btoa(lead.language));

			const {i18n} =  this.props;
	
			await i18n.changeLanguage(lead.language);
			this.setState({language: lead.language});
		}

		if(showMessage){
			await this.modal_message.handleShow(
				this.props.t('modal.wizard.title'),
				this.props.t('modal.wizard.message')
			);
		}

		var zones_aux = await this.props.fetchRequest({api:'accounts', method:'GET', url:'accounts/get-available-zones', requireToken:true});
		var zones = [];
		zones_aux.map((z)=>
			zones.push({value:z.zone+' '+z.offset, label:z.zone+' '+z.offset})
		);

		var aux_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		var index = zones_aux.findIndex(obj => obj.zone === aux_zone);

		var countries =  await fetch('https://countriesnow.space/api/v0.1/countries/iso')
                .then(res => {
                    return res.json().then((r)=>{
                        return r;
                    });
                }).then(response => {
                    var countries = [];
                    response.data.map((res)=>
                        countries.push({
                            value: res.Iso3,
                            label: this.props.t('general.countries.'+res.Iso3)
                        })
                    );
                    return countries;
				});
				
		if(lead.language === null){
			lead.language = atob(sessionStorage.getItem(btoa("lang")));
		}

        this.setState({
			zones: zones,
			countries: countries, //await this.props.fetchRequest({api:'accounts', method:'GET', url:'lookups?type=country', requireToken:true}),
			industries: await this.props.fetchRequest({api:'accounts', method:'GET', url:'lookups/economic_sector/'+lead.language, requireToken:true}),
			time_zone: index !== -1 ? zones_aux[index].zone+' '+zones_aux[index].offset : "",
			company_size: lead.companySize
		});
		
		var width = $(window).width();
		if(width < 430){
			this.setState({vertical: true});
		}else{
		  this.setState({vertical: false});
		}

		$(window).on('resize', ()=>{
			if ($(window).width() !== width) {
			  	width = $(window).width();
			  	if(width < 430){
				  	this.setState({vertical: true});
			  	}else{
					this.setState({vertical: false});
			  	}
			}
		});
	}

	handleChange(value, e){
		
		if(e === undefined){
			e = value;
			value = e.target.value
		}
		let name = e.target !== undefined ? e.target.name : e;

		this.setState({[name]: value});	

		if(value !== ""){
			var control = document.getElementById(name);
			if(control !== null){
				if(control.required && control.className.includes('error')){
		        	control.classList.remove('error');
		    	}
			}
		}

		setTimeout(()=>{
			if(this.state.company_name !== "" && 
				this.state.industry !== "" && 
				this.state.country !== ""){
				if(this.state.industry === 'other_services'){
					if(this.state.industryOther !== ""){
						this.setState({color_next:'primary'});
					}else{
						this.setState({color_next:'default'});		
					}
				}else{
					this.setState({color_next:'primary'});
				}
				
			}else{
				this.setState({color_next:'default'});
			}
		}, 300)
			
	}

	handleCheck(e){
		this.setState({ [e.target.name]: e.target.checked ? true : false });

		setTimeout(()=>{
			if(this.state.step === 1){
				if(this.state.automatic || this.state.isr || this.state.registered || this.state.incidents){
					this.setState({required_payroll: false});
				}
				if(!this.state.automatic && !this.state.isr && !this.state.registered && !this.state.incidents){
					this.setState({required_payroll: true});
				}
			}

			if(this.state.step === 2){
				if(this.state.facial || this.state.nip){
					this.setState({required_assistance: false});
				}
				if(!this.state.facial && !this.state.nip){
					this.setState({required_assistance: true});
				}	
			}
			
		}, 500);	
	}

	onChange(nextStep){
    	this.setState({
    		step:nextStep < 0 ? 0 : nextStep > 2 ? 2 : nextStep
    	});
  	};

  	onNext(){
		if(this.props.isValidForm('form')){	
			this.setState({status:'process'});

			this.onConfirm('FINALIZAR');
  		}else{
  			this.setState({status:'error'});
  			Alert.error(this.props.t('error_alert.incomplete_fields'));

  		}
  	}

  	onPrevious(){ 
  		this.onChange(this.state.step - 1);
  	}

  	async onSave(obj, method, url){
  		let req = {
  			api:'accounts',
  			method:method,
  			url:url,
  			obj:obj,
  			modal:this.props.modal_loader,
  			message_modal:this.props.t('modal.spin.configuring'),
  			requireToken:true
  		}
  		let request = await this.props.fetchRequest(req);

  		if(request !== undefined){
  			if(this.state.step === 1){
  				this.props.history.push('/');	
  			}

  			if(this.state.step === 0){
  				this.setState({id: request.id});
				sessionStorage.setItem('hasAccount', true);
				sessionStorage.setItem(btoa("account_id"), btoa(JSON.stringify(request.id)));
  			}
  			
  			if(this.state.step < 1){
  				this.onChange(this.state.step + 1);	
  			}
  			
  		}
  	}

  	onConfirm(action){
  		var obj = {
  			companyName: this.state.company_name,
		    industry: this.state.industry,
			country: this.state.country,
			companySize: this.state.company_size,
			timeZone: this.state.time_zone !== "" ? this.state.time_zone.split(' ')[0] : "",
		    legalName:"",
			language: this.state.language,
		    taxId: "",
		    phone: "",
		    website: "",
		    isConfigured: true,
		    attributes: {
		    	industryOther:this.state.industryOther
		    },
		    schedules: [],
		    accountConfig: {
		      	enforceGeolocation: this.state.enforceGeolocation,
		      	enforceFacialRecognition: this.state.enforceFacialRecognition,
		      	hasIMSS: false,
		      	hasSAT: false,
		      	supportAspel: this.state.supportAspel,
		      	supportContpaq: this.state.supportContpaq,
		      	supportDelays: this.state.supportDelays,
		      	supportVacations: this.state.supportVacations,
		      	supportEconomicDays: this.state.supportEconomicDays,
		      	supportAbsences: this.state.supportAbsences,
		      	payrollWeekly: this.state.payrollWeekly,
		      	payrollBiweekly: this.state.payrollBiweekly,
		      	payrollMonthly: this.state.payrollMonthly,
		      	payrollSpecial: this.state.payrollSpecial,
		      	payrollSpecialDays: 0,
		      	incidentRules: [],
		      	checkStorageS3:false,
		      	checkMaxRaduis:this.state.checkMaxRaduis,
				breakOutOffsetMinutes: this.state.breakOutOffsetMinutes,
				checkAheadOffsetHours: this.state.checkAheadOffsetHours,
				checkBackOffsetHours: this.state.checkBackOffsetHours,
				statusWizardStep2:action
		    }
  		}

  		if(this.state.step === 0){
  			this.onSave(obj, 'POST', 'accounts');
  		}else{
  			obj.id = this.state.id;
  			this.onSave(obj, 'PUT', 'accounts/'+this.state.id);
  		}
  	}

  	onFinish(){
		this.onConfirm('OMITIR');  		
  	}

  	showForm(){
  		this.modal_message.handleClose();

  		this.setState({show:true});
  	}

	render(){	
		const {t} = this.props;
		const steps = [
			{component:<General 
							t={t}
							company_name={this.state.company_name} 
							industries={this.state.industries}
							industry={this.state.industry}
							country={this.state.country}
							countries={this.state.countries}
							time_zone={this.state.time_zone}
							zones={this.state.zones}
							handleChange={this.handleChange}
						/>
			},
			{component:<Operation
							t={t}
							facial={this.state.enforceFacialRecognition}
							geolocalization={this.state.enforceGeolocation}
							weekly={this.state.payrollWeekly}
							biweekly={this.state.payrollBiweekly}
							monthtly={this.state.payrollMonthly}
							special={this.state.payrollSpecial}
							delays={this.state.supportDelays}
							vacations={this.state.supportVacations}
							economicDays={this.state.supportEconomicDays}
							absences={this.state.supportAbsences}
							handleCheck={this.handleCheck}
						/>
			}/*,
			{component:<Connection
							t={t}
							country={this.state.country}
							aspel={this.state.supportAspel}
							contpaq={this.state.supportContpaq}
							handleCheck={this.handleCheck}
						 />
			}*/
		];

		return (
			<div>
				{this.state.show &&
					<Container>
						<FlexboxGrid justify="center">
							<Col xs={24} sm={22} md={18} style={this.state.vertical ? {padding:'15px'} : {padding:'25px'}}>
								<Steps 
									current={this.state.step} 
									currentStatus={this.state.status} 
									small
									vertical={this.state.vertical}
								>
									<Steps.Item title={t('screen.wizard.steps.generals')} />
									<Steps.Item title={t('screen.wizard.steps.operation')} />
									{/*<Steps.Item title={t('screen.wizard.steps.conection')} />*/}
								</Steps>

								<Panel>
									{steps[this.state.step].component}
								</Panel>
								<hr/>
								<FlexboxGrid justify="end">
									<Col xs={24} sm={22} md={8}>
										<ButtonToolbar style={{float:'right'}}>
											{this.state.step > 0 && 
												<Button 
													onClick={()=>this.state.step === steps.length - 1 ? 
																this.onFinish()
															: this.onNext()
													} 
												>
													{t('button.skip')}
												</Button>
											}
											
											{this.state.step === steps.length - 1 ?
												<Button appearance="primary" onClick={()=>this.onNext()}>
													{t('button.finish')}
												</Button>

											: <Button appearance={this.state.color_next} onClick={()=>this.onNext()}>
												{t('button.next')}
											</Button>}
										</ButtonToolbar>
									</Col>
								</FlexboxGrid>
							</Col>
							<FlexboxGrid.Item colspan={18} style={{padding:'50px'}}>
								

								
								
								<FlexboxGrid justify="end">
									<FlexboxGrid.Item colspan={8}>
										
									</FlexboxGrid.Item>
								</FlexboxGrid>
							</FlexboxGrid.Item>
						</FlexboxGrid>

						
					</Container>
				}
				<ModalMessage {...this.props} 
					onRef={ref => this.modal_message = ref}
					btn={t('button.start')}
					showForm={this.showForm}
				/>
			</div>

					
		)
	}
}

export default withTranslation('translations')(Wizard);