import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {FlexboxGrid, Col, Grid, Icon, Panel} from 'rsuite';
import {Image } from 'react-bootstrap';

class PremiumPlan extends Component{
	constructor(props){
		super(props);

		this.handleChange = this.handleChange.bind(this);

		this.state = {
		}
	}

	componentDidMount(){
	}

	handleChange(e){
	}

	render(){
		const {t} = this.props;
		return (
            <Panel className="panel-general premium" bordered shaded={this.props.premium} style={{height: 640, cursor:'pointer'}} onClick={()=>this.props.changePlan('premium')}>
				<div style={{textAlign:'center'}}>
					<Image className="image-plan" src="images/plans/premium.svg" style={{width:'100%'}} />
					
					<div className="title-plan">
						<span>{t('screen.plans.premium')}</span>
					</div>
				</div>
				<FlexboxGrid justify="center">
					<Col xs={24}>
						<Grid fluid>
							<Col xs={24} style={{textAlign:'right'}}>
								<span style={{fontWeight:'bold', display:'block', fontSize:'2.8em'}}>$1.99 USD</span>
							</Col>
							<Col xs={24} style={{textAlign:'right'}}>
								<span style={{fontWeight:'bold', display:'block',fontSize:'1em'}}>{t('screen.plans.periodicity')}</span>
							</Col>
							<Col xs={24}>
								<Icon icon="check" style={{color:'green', marginRight:8}} /> <span>{t('screen.plans.facial')}</span>
							</Col>
							<Col xs={24}>
								<Icon icon="check" style={{color:'green', marginRight:8}} /> <span>{t('screen.plans.location')}</span>
							</Col>
							<Col xs={24}>
								<Icon icon="check" style={{color:'green', marginRight:8}} /> <span>{t('screen.plans.unlimited_check')}</span>
							</Col>
							<Col xs={24}>
								<Icon icon="check" style={{color:'green', marginRight:8}} /> <span>{t('screen.plans.unlimited_employees')}</span>
							</Col>
							<Col xs={24}>
								<Icon icon="check" style={{color:'green', marginRight:8}} /> <span>10 {t('screen.plans.address2')}</span>
							</Col>
							<Col xs={24}>
								<Icon icon="check" style={{color:'green', marginRight:8}} /> <span>{t('screen.plans.standard_report')}</span>
							</Col>
							<Col xs={24}>
								<Icon icon="check" style={{color:'green', marginRight:8}} /> <span>{t('screen.plans.special_report')}</span>
							</Col>
							<Col xs={24}>
								<Icon icon="check" style={{color:'green', marginRight:8}} /> <span>{t('screen.plans.multi_checks')}</span>
							</Col>
							<Col xs={24}>
								<Icon icon="check" style={{color:'green', marginRight:8}} /> <span>{t('screen.plans.overtime')}</span>
							</Col>
							<Col xs={24}>
								<Icon icon="check" style={{color:'green', marginRight:8}} /> <span>{t('screen.plans.special_configuration')}</span>
							</Col>
							<Col xs={24}>
								<Icon icon="check" style={{color:'green', marginRight:8}} /> <span>{t('screen.plans.visit')}</span>
							</Col>
							<Col xs={24}>
								<Icon icon="check" style={{color:'green', marginRight:8}} /> <span>{t('screen.plans.visit_report')}</span>
							</Col>
						</Grid>
					</Col>
				</FlexboxGrid>
				<div className="footer-plan" style={{background:'#f89801'}}></div>
            </Panel>
		)
	}
		  
}

export default withTranslation('translations')(PremiumPlan)