import React, {Component} from 'react';

import {
	Modal, Button
} from 'rsuite';

import Picture from './Picture';
import { swalAction } from '../../../lib/functions';

class ModalLoadPicture extends Component{
	constructor(props){
		super(props);

		this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.setImage = this.setImage.bind(this);
        this.saveImage = this.saveImage.bind(this);
        this.getImage = this.getImage.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
        this.onDelete = this.onDelete.bind(this);

		this.state = {
            show: false,
            id:"",
            photoBase64:"",
            saved:false,
		}
	}

	componentDidMount(){
		this.props.onRef(this);
	}

	componentDidUmount(){
		this.props.onRef(null);
	}

	handleShow(id){
		this.setState({
            show:true,
            id:id
        });
        
        this.getImage(id);
	}

	handleClose(){
		this.setState({show:false, id:"", photoBase64:"", saved:false});
    }

    async getImage(id){
        var req = {
            api:'accounts',
            method:'GET',
            url:'employees/image/'+id,
            modal:this.props.modal_loader,
            message_modal:this.props.t('modal.spin.loading'),
            requireToken:true
        };

        var request = await this.props.fetchRequest(req);

        if(request.base64 !== undefined){
            this.setState({photoBase64: 'data:image/jpeg;base64,'+request.base64, saved: true});
            var data = [{name:'empleado.png', fileKey:1, url: 'data:image/jpeg;base64,'+request.base64}];
            this.picture.setState({image:data})
        }

    }
    
    setImage(value){
        this.setState({photoBase64: value});
    }

    async saveImage(){
        if(this.state.photoBase64 !== ""){
            var obj = {
                employeeId: this.state.id,
                photoBase64: this.state.photoBase64
            }

            var req = {
                api:'accounts',
                method:'POST',
                url: this.state.saved ? 'employees/update-image' : 'employees/save-image',
                obj:obj,
                modal:this.props.modal_loader,
                message_modal: this.state.saved ? this.props.t('modal.spin.updating') : this.props.t('modal.spin.saving'),
                requireToken:true
            }

            var request = this.props.fetchRequest(req);

            if(request !== undefined){
                this.handleClose();
                setTimeout(()=>{
                    this.props.table.resetTable();
                    this.props.getList();
                }, 0)
            }
        }
    }

    async onDelete(){
        
        var obj = {
            employeeId: this.state.id,
            photoBase64: this.state.photoBase64
        }

        var req = {
            api:'accounts',
            method:'DELETE',
            url: 'employees/image/'+this.state.id,
            obj:obj,
            modal:this.props.modal_loader,
            message_modal: this.props.t('modal.spin.deleting'),
            requireToken:true
        }

        var request = this.props.fetchRequest(req);

        if(request !== undefined){
            this.handleClose();
            setTimeout(()=>{
                this.props.table.resetTable();
                this.props.getList();
            }, 0)
        }
    }

    deleteImage(){
        var obj = {
            title           : this.props.t('modal.alert.titles.alert'),
            text            : this.props.t('modal.alert.messages.delete_image'),
            icon            : 'warning',
            showConfirm     : true,
            showCancel      : true,
            textConfirm     : this.props.t('modal.alert.confirms.delete'),
            colorConfirm    : '#3085d6',
            colorCancel     : '#d33',
            textcancel      : this.props.t('modal.alert.condirms.cancel'),
            values          : this.state.id,
            fn              : this.onDelete,
            fnCancel        : this.getImage
        }

        swalAction(obj);

    }

	render(){
        const {t} = this.props;
		return (
			<Modal show={this.state.show} onHide={this.handleClose}>
				<Modal.Body style={{maxHeight:'350px !important'}}>
					<div className="form-group">
						<div>
                            {this.state.delete ?
                                <div style={{textAlign:'center'}}>
                                    <h3>{t('screen.settings.employee.list.employee')}</h3>
                                    <img src={this.state.photoBase64} alt="Imagen" />
                                </div>
                            :
                                <Picture {...this.props}
                                    setImage={this.setImage}
                                    savedImage={this.state.saved}
                                    deleteImage={this.deleteImage}
                                    onRef={ref => this.picture = ref}
                                />
                            }
                            
						</div>
					</div>
				</Modal.Body>
                <Modal.Footer>
                    <div style={{marginTop:10}}>
                        <Button color="blue" onClick={this.saveImage}>{this.props.t('button.load')}</Button>
                        <Button style={{marginLeft:10}} onClick={this.handleClose}>{this.props.t('button.cancel')}</Button>
                    </div>                    
                </Modal.Footer>
			</Modal>
		);
	}

}

export default ModalLoadPicture;