import React, { Component } from 'react';
import { Grid, Col, Uploader } from 'rsuite';

class Picture extends Component{
    constructor(props){
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            image:[]
        }
    }

    componentDidMount(){
        this.props.onRef(this);
    }

    componentWillUnmount(){
        this.props.onRef(null);
    }

    handleChange(value){
        this.setState({image: value.length > 0 ? [value[value.length - 1]] : []});
        
        if(value.length > 0){
            this.base64( [value[value.length - 1]], (data)=>{
                this.props.setImage(data.base64);
            })
        }else{
            this.props.setImage('');
        }        
    }

    base64(file, callback){
        var coolFile = {};
        function readerOnload(e){
            var base64      = btoa(e.target.result);
            coolFile.base64 = base64;
            callback(coolFile)
        };
      
        var reader = new FileReader();
        reader.onload = readerOnload;
        
        var image            = file[0].blobFile;
        coolFile.filetype   = image.type;
        coolFile.size       = image.size;
        coolFile.filename   = image.name;
        
        reader.readAsBinaryString(image);
    }

    render(){
        const {t} = this.props;
        return(
            <form>
                <h3>{t('screen.settings.employee.new.form.load_picture')}</h3>
                <Grid fluid>
                    <Col xs={24}>
                        <Uploader 
                            draggable
                            listType="picture-text"
                            onChange={this.handleChange}
                            autoUpload={false}
                            multiple={false}
                            fileList={this.state.image}
                            onRemove={this.props.deleteImage !== undefined ? 
                                this.props.savedImage ? this.props.deleteImage : this.handleChange
                            : this.handleChange}
                        >
                            <div style={{lineHeight: '200px'}}>{t('screen.settings.employee.new.form.image_instruction')}</div>
                        </Uploader>
                    </Col>
                </Grid>
            </form>
        )
    }
}
export default Picture