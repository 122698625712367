import React, {Component} from 'react';
import {Container, Grid, Col, /*ControlLabel,*/ Button, Alert} from 'rsuite';
import {withTranslation} from 'react-i18next';
import Layout from '../../../components/Layout';
import Geocode from "react-geocode";
/*import {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';*/

import AddressForm from '../../../components/settings/general/AddressForm';

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyAig1H6xWKyyS5J6NeADJhi8YR2A2N6akI");
  
// set response language. Defaults to english.
Geocode.setLanguage("es");

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
//Geocode.setRegion("es");

// Enable or disable logs. Its optional.
Geocode.enableDebug();

export class NewLocation extends Component {
    constructor(props){
        super(props);

        this.cancel = this.cancel.bind(this);
        this.saveLocation = this.saveLocation.bind(this);

        this.state = {
            optionForm: "new",
            language:""
        }
    }

    componentDidMount(){
        this.setState({optionForm:this.props.match.params.optionForm});
    }

    cancel(){
		this.props.history.push("/settings/locations");
	}
    
    async saveLocation(){        
        if(this.props.isValidForm('div.form-location')){	

            var obj = {
                type: this.address_form.state.type,
                name: this.address_form.state.name,
                latitude: this.address_form.state.latitude,
                longitude: this.address_form.state.longitude,
                address: this.address_form.state.address
            };
            

            if(this.state.optionForm === "edit"){
                obj.id = this.props.match.params.locationId;
            }

			let req = {
                api:'accounts',
                method: this.state.optionForm === "new"? "post" : "put",
                url: this.state.optionForm === "new"? "locations" : "locations/"+obj.id,
                obj:obj,
                requireToken:true,
                modal:this.props.modal_loader,
                message_modal:this.props.t('modal.spin.start')
            }
    
            let request = await this.props.fetchRequest(req);
    
            if(request !== undefined){
                this.props.history.push('/settings/locations');
            }else {
                Alert.error(this.props.t('error_alert.no_save'));
            }
  		}else{
            Alert.error(this.props.t('error_alert.incomplete_fields'));
  		}
    }

    render(){
        const {t} = this.props;
        return(
            <Layout {...this.props}>
                
                <Container >
                    <AddressForm {...this.props} 
                        form="location"
                        optionForm={this.state.optionForm}
                        onRef={ref => this.address_form = ref}
                    />
                    {this.state.optionForm !== 'details'?
                        <Grid className="form-group" fluid>
                            <Col xs={24} >
                                <Button appearance="primary" style={{marginRight:8}} onClick={()=>this.saveLocation()}>{t('button.save')}</Button>
                                <Button appearance="primary" onClick={()=>this.cancel()}>{t('button.cancel')}</Button>
                            </Col>
                        </Grid>
                    :
                        <Grid fluid className="form-group" >
                            <Col smOffset={11} xs={24} sm={2} >
                                <Button appearance="primary" onClick={()=>this.cancel()}>{t('button.cancel')}</Button>
                            </Col>                        
                        </Grid>
                    }
                </Container>
            </Layout>
        )
    }
}
export default withTranslation('translations') (NewLocation)
//export default withTranslation('translations') ( NewLocation);