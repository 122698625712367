import React, {Component} from 'react';
import {Panel, Grid, Icon, IconButton, Col } from 'rsuite';
import {Tabs, Tab } from 'react-bootstrap';

import FormIncidentRules from './FormIncidentRules';
import SaveBtn from './SaveBtn';
import SavedMark from './SavedMark';
import {swalAction} from '../../../lib/functions';

class IncidentRules extends Component{
    constructor(props){
        super(props);

        this.newRule = this.newRule.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setKey = this.setKey.bind(this);
        this.deleteRule = this.deleteRule.bind(this);

        this.state = {
            show_form: false,
            rule:"",
            incidents:[],
            is_saved: false,
            active_save:false,
            suscription: sessionStorage.getItem('suscription'),
            disabled:true,
        }
    }

    componentDidMount(){
        this.props.onRef(this);
    }
    
    componentWillUnmount(){
        this.props.onRef(null);
    }

    setKey(e){
        this.setState({rule:e});
    }

    newRule(){
        var incidents = this.state.incidents;
        

        if(this.state.suscription === 'free' && this.state.incidents.length >= 1){
            this.verifySuscription();
        }else{
            var index = incidents.length + 1;
            incidents.push({
                name:"Regla "+index,
                label:"regla"+index,
                supportDelays:"No",
                minutesDelays:"",
                numDelays:"",
                supportExtraHr:"No",
                maxExtraHr:"",
                supportEconomicDay:"No",
                maxEconomicDay:""
            });
            this.setState({show_form: true, incidents: incidents, rule:"regla"+index, is_saved:false, active_save: true});
        }
    }

    verifySuscription(){
        var obj = {
            title           : this.props.t('modal.alert.titles.suscription'),
            text            : this.props.t('modal.alert.messages.update_suscription'),
            icon            : 'warning',
            showConfirm     : true,
            showCancel      : true,
            textConfirm     : this.props.t('modal.alert.confirms.go_plans'),
            colorConfirm    : '#3085d6',
            colorCancel     : '#d33',
            textcancel      : this.props.t('modal.alert.confirms.cancel'),
            values          : '',
            fn              : ()=>{this.props.history.push('/settings/account')}
        }
        
        swalAction(obj);  
    }

    handleChange(e){
        var incidents = this.state.incidents;
        var index = incidents.findIndex(obj => obj.label === this.state.rule);
        
        var incident = incidents[index];
        if(e.target.name === 'name'){
            if(e.target.value.length <= 20){
                incident[e.target.name] = e.target.value;
            }
        }else{
            incident[e.target.name] = e.target.value;
        }
        
        incidents[index] = incident;
        this.setState({incidents: incidents, is_saved:false, active_save: true});
    }

    openMessage(e, i){
        var obj = {
            title           : 'Alerta',
            text            : '¿Desea eliminar la regla?',
            icon            : 'warning',
            showConfirm     : true,
            showCancel      : true,
            textConfirm     : 'Eliminar',
            colorConfirm    : '#3085d6',
            colorCancel     : '#d33',
            textcancel      : 'Cancelar',
            values          : i,
            fn              : this.deleteRule
        }

        swalAction(obj);
    }

    deleteRule(i){
        var incidents = this.state.incidents;
        incidents.splice(i, 1);
        
        this.setState({
            incidents: incidents,
            show_form: incidents.length > 0 ? true : false,
            rule: incidents.length > 0 ? incidents[0].label : "",
            is_saved: false,
            active_save: true
        });

    }

    render(){
        const {t} = this.props;
        return(
            <Panel shaded>
                <i class="rs-panel-heading" style={{padding:"0px"}}> 
                    {t('screen.settings.account.incident_rules.title')}
                </i>
                <i class="rs-icon rs-icon-help-o rs-panel-heading" style={{paddingLeft:"10px"}} title={this.props.t('screen.settings.account.tooltips.incident_rules')}> 
                </i>
                {this.state.is_saved &&
                    <SavedMark />
                }
				<Grid fluid>
                    <Col xs={24} sm={2} md={1}>
                        {this.state.disabled ?
                            <IconButton 
                                icon={<Icon icon='pencil' />}
                                color="blue"
                                size="sm"
                                onClick={()=>this.setState({disabled: !this.state.disabled})}
                            />
                        :
                            <SaveBtn t={this.props.t} active={this.state.active_save} action={this.props.dataUpdate} />
                        }

                        <IconButton 
                            icon={<Icon icon="plus" />}
                            className={this.state.disabled ? '' 
                                : this.state.suscription === 'free' && this.state.incidents.length > 0 ? '' 
                                : "s-btn-primary"}
                            onClick={(e)=>this.state.disabled ? e.preventDefault() : this.newRule()}
                            circle
                            size="sm"
                            style={{marginTop:8}}
                        />
                    </Col>

                    <Col xs={24} sm={22} md={23}>
                        {this.state.show_form &&
                            <Tabs id="incident-rules-form"
                                activeKey={this.state.rule}
                                transition={false}
                                onSelect={(k)=>this.setKey(k)}
                            >
                                {this.state.incidents.map((inc, i)=>(
                                    <Tab eventKey={inc.label} 
                                        title={<div>
                                            <span>{inc.name}</span>
                                            <IconButton 
                                                appearance="subtle"
                                                icon={<Icon icon="close" size="3x" />}
                                                size="lg"
                                                title="Cerrar"
                                                onClick={(e)=>this.openMessage(e, i)}
                                                style={{marginLeft:8, fontSize:10}}
                                            />
                                        </div>} 
                                        key={i}>
                                        <FormIncidentRules {...this.props}
                                            incident={inc}
                                            handleChange={this.handleChange}
                                            onRef={ref => this.form_incident = ref}
                                            disable_fields={this.state.disabled}
                                            wizard={false}
                                        />
                                    </Tab>
                                ))}
                            </Tabs>
                        }
                    </Col>
                </Grid>
                
			</Panel>
        )
    }
}
export default IncidentRules;