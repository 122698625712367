import React, {Component} from 'react';

import {
	Modal, Col, Grid, Panel, Icon
} from 'rsuite';
import { Carousel, Image } from 'react-bootstrap';


class ModalDetail extends Component{
	constructor(props){
		super(props);

		this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSelect = this.handleSelect.bind(this);

		this.state = {
            show: false,
            list:[],
            index:-1
		}
	}

	componentDidMount(){
		this.props.onRef(this);
	}

	componentDidUmount(){
		this.props.onRef(null);
	}

	handleShow(i, list){
        console.log(list)
		this.setState({
            show:true,
            index:i,
            list: list
		});
	}

	handleClose(){
		this.setState({show:false});
    }
    
    handleSelect(e){
        this.setState({index: e});
    }

	render(){
		return (
			<Modal size="lg" show={this.state.show} onHide={this.handleClose}>
                <Modal.Header></Modal.Header>
				<Modal.Body>
					<Grid fluid>
                        <Col xs={24}>
                            <Carousel 
                                activeIndex={this.state.index} onSelect={(e)=>this.handleSelect(e)}
                                prevIcon={<Icon icon="angle-double-left" size='3x' style={{color:'var(--primary)', fontWeight:'bold'}} />}
                                nextIcon={<Icon icon="angle-double-right" size='3x' style={{color:'var(--primary)', fontWeight:'bold'}} />}
                                //style={{background:'red'}}
                            >
                                {this.state.list.map((list, i)=>
                                    <Carousel.Item key={i}>
                                        <Panel shaded bordered bodyFill>
                                            <Panel style={{textAlign:"center"}}>
                                                <Image 
                                                    style={{width:"40%",height:"40%",textAlign:"center"}}
                                                    src={'data:image/jpeg;base64,'+list.imageBase64} 
                                                    alt="imagen"                             
                                                />
                                            </Panel>
                                            <Panel bordered>
                                                <p>
                                                    {list.description}
                                                </p>
                                            </Panel>
                                        </Panel>
                                    </Carousel.Item>
                                )}
                            </Carousel>
                        </Col>
                    </Grid>
				</Modal.Body>
			</Modal>
		);
	}

}

export default ModalDetail;